import { ApolloError } from "@apollo/client";

/* 
    grapqhl error object can include a number of errors, this will map through all the 
    errors and gather each error code, then check if any of the codes match the provided "code" variable
*/
export default function graphqlErrorsIncludesCode(err: any, code: string) {
  if (!err) throw new Error("No error object was provided");
  if (!code) throw new Error("No code was provided");
  const error: ApolloError = err as ApolloError; // can't assign errors in TS https://stackoverflow.com/questions/42618089/how-do-you-use-typed-errors-in-async-catch
  return error?.graphQLErrors?.map((e) => e?.extensions?.code)?.includes(code);
}
