import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import {
  Maybe,
  SessionStatus,
  useGetManySessionStatusesQuery,
} from "generated/graphql";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const DeviceStatusSelectInput = (props: {
  onChange: (result: SessionStatus) => void;
  value?: Maybe<SessionStatus>;
  style?: React.CSSProperties;
}) => {
  const { value, style, onChange } = props;
  const { data, loading } = useGetManySessionStatusesQuery();

  return (
    <>
      <Select
        onChange={(_, option: any) =>
          onChange({
            id: option?.value,
            key: option?.value,
            label: option?.children,
          })
        }
        value={value?.id || undefined}
        showArrow={false}
        filterOption={false}
        optionFilterProp="children"
        loading={loading}
        showSearch
        style={{
          width: "100%",
          margin: "auto",
          ...style,
        }}
      >
        {data?.getManySessionStatuses?.map(({ id, label }) => {
          return (
            <Option key={id} value={id}>
              {label}
            </Option>
          );
        })}
      </Select>
    </>
  );
};

// EXPORT
// ================================================================
export default DeviceStatusSelectInput;
