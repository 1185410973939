import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import {
  Maybe,
  Organization,
  useGetManyOrganizationsQuery,
} from "generated/graphql";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const OrganizationSelectInput = (props: {
  onChange: any;
  value?: Maybe<string>;
  organizations?: Organization[];
  style?: React.CSSProperties;
}) => {
  const { data } = useGetManyOrganizationsQuery({});
  const { onChange, value = "", style } = props;
  const options = data?.getManyOrganizations?.map(
    (organization: Organization) => {
      return (
        <Option key={organization?.id} value={organization?.id}>
          {organization?.name}
        </Option>
      );
    }
  );
  return (
    <>
      <Select
        {...props}
        onChange={onChange}
        value={value || undefined}
        optionFilterProp="children"
        placeholder="Select a client"
        style={{
          width: "100%",
          margin: "auto",
          ...style,
        }}
      >
        {options}
      </Select>
    </>
  );
};

// EXPORT
// ================================================================
export default OrganizationSelectInput;
