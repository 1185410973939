import { UserProfile, UserTypeEnum } from "generated/graphql";
import { useEffect } from "react";
import { useHistory } from "react-router";

export default function useRerouteLoggedInUser({
  currentUser,
}: {
  currentUser: UserProfile;
}) {
  const history = useHistory();
  useEffect(() => {
    if (currentUser?.userType === UserTypeEnum.Client) {
      return history.push(`/app/home`);
    }
    if (currentUser?.userType === UserTypeEnum.SuperAdmin) {
      return history.push(`/admin/home`);
    }
  }, [currentUser?.userType, history]);

  const onCheck = () => {};

  return onCheck;
}
