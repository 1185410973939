import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { Maybe, Site, useGetManySitesQuery } from "generated/graphql";
import { EnvironmentOutlined } from "@ant-design/icons";
import theme from "lib/theme";
// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const SitesSelectInput = (props: {
  onChange: any;
  value?: Maybe<string>;
  sites?: Site[];
  style?: React.CSSProperties;
  showIcon?: boolean;
  organizationIds?: string[];
  trialIds?: string[];
}) => {
  const { showIcon, sites, onChange, value = "", style } = props;
  const { data } = useGetManySitesQuery({
    variables: {
      organizationIds: props.organizationIds || [],
      trialIds: props?.trialIds ? props.trialIds : [],
    },
    fetchPolicy: "cache-and-network",
  });

  const options = Array.isArray(sites) ? sites : data?.getManySites;

  return (
    <>
      {showIcon && (
        <EnvironmentOutlined
          style={{
            position: "absolute",
            left: 19,
            top: 18,
            fontSize: 17,
            zIndex: 100,
            color: theme.colors.neutral5,
          }}
        />
      )}
      <Select
        {...props}
        onChange={onChange}
        value={value || undefined}
        optionFilterProp="children"
        placeholder="Select a site"
        style={{
          width: "100%",
          margin: "auto",
          ...style,
        }}
      >
        {options?.map((site) => {
          if (!site?.id) return null;
          return (
            <Option key={site?.id} value={site?.id}>
              {site?.name}
            </Option>
          );
        })}
      </Select>
    </>
  );
};

// EXPORT
// ================================================================
export default SitesSelectInput;
