import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { validate } from "email-validator";
import queryString from "query-string";
// COMPONENST
import Caption from "components/text/Caption";
import FormItem from "components/common/FormItem";
import ErrorBlock from "components/common/ErrorBlock";
import Button from "components/common/Button";
import TextInput from "components/inputs/TextInput";
// GRAPHQL
import {
  CurrentUserDocument,
  useAuthenticateMutation,
} from "generated/graphql";
import client from "ApolloClient/index";
// LIB
import setLocalStorage from "lib/helpers/setLocalStorage";
import config from "lib/config";
import logo from "lib/media/logo.png";
import bgImg from "lib/media/bg.jpg";
// HOOKS
import useRerouteLoggedInUser from "lib/hooks/useRerouteLoggedInUser";

const Logo = styled.img`
  height: 90px;
  margin: auto;
`;

const TitleContainer = styled.div`
  min-height: 95px;
  text-align: center;
`;

const FormContainer = styled.div`
  width: 470px;
  padding: 16px;
  max-width: 100%;
  margin: auto;
  padding-top: 20px;
  padding: 32px 64px;
  border-radius: ${(p) => p.theme.borderRadius};
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid ${({ theme }) => theme.colors.neutral9};
  @media only screen and (max-width: 414px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  align-items: center;
  padding-top: 20px;
  display: flex;
  height: 100vh;
  background-image: url(${bgImg});
  width: 100%;
  background-repeat: no-repeat, repeat;
  background-size: cover; /* Resize the background image to cover the entire container */
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const Gap = styled.div`
  margin-top: 12px;
`;

export default function AuthLogin({ currentUser, history, location }: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    serverError?: string;
    email?: string;
    password?: string;
  }>({
    serverError: undefined,
    email: undefined,
    password: undefined,
  });
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [authenticateMutation] = useAuthenticateMutation();
  useRerouteLoggedInUser({ currentUser });
  const { redirectTo } = queryString.parse(location.search);

  const onSubmit = async (e: any) => {
    try {
      if (e.preventDefault) {
        e.preventDefault();
      }

      // check that user added an email
      if (!email) {
        return setErrors({ email: "Please provide an email" });
      }
      // check if its a valid email
      if (!validate(email)) {
        return setErrors({
          email: "That is not a valid email",
        });
      }
      // check that they give a password
      if (!password) {
        return setErrors({
          password: "Please provide a password",
        });
      }
      setLoading(true);
      const res = await authenticateMutation({
        variables: {
          params: {
            password,
            user: {
              email: email,
            },
          },
        },
      });

      if (
        res?.data?.authenticate?.tokens?.accessToken &&
        res?.data?.authenticate?.tokens?.refreshToken
      ) {
        setLocalStorage(
          config.authTokenName,
          res?.data.authenticate.tokens.accessToken
        );
        setLocalStorage(
          config.refreshTokenName,
          res?.data.authenticate.tokens.refreshToken
        );
      }

      await client.query({
        query: CurrentUserDocument,
        fetchPolicy: "network-only",
      });

      setTimeout(() => {
        setLoading(false);
        if (redirectTo) {
          console.log("the redirect ran");
          history.push(redirectTo);
        }
      }, 1000);
    } catch (err: any) {
      let errMessage = err.message.replace("GraphQL", "");
      if (err && err.message.includes("Incorrect password [403]")) {
        errMessage = "You have entered an invalid username or password";
      }
      setLoading(false);
      console.log([errMessage]);
      return setErrors({
        serverError: errMessage,
      });
    }
  };

  // if (currentUser?.userType === UserTypeEnum.Client) {
  //   return <Redirect to="/app/home" />;
  // }

  // if (currentUser?.userType === UserTypeEnum.SuperAdmin) {
  //   return <Redirect to="/admin/home" />;
  // }

  return (
    <Container>
      <FormContainer>
        <TitleContainer>
          <Logo src={logo} />
          {/* <Caption style={{ margin: "16px auto" }}>Welcome back!</Caption> */}
        </TitleContainer>
        <Gap />
        <form onSubmit={onSubmit}>
          <FormItem error={errors.email}>
            <TextInput
              value={email || ""}
              type="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value.toLowerCase()?.trim())}
            />
          </FormItem>
          <Gap />
          <FormItem error={errors.password}>
            <TextInput
              value={password || ""}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </FormItem>
          <ButtonContainer>
            <Button
              disabled={loading}
              loading={loading}
              type="submit"
              fullWidth
              style={{ height: 40 }}
              onClick={onSubmit}
            >
              Login
            </Button>
          </ButtonContainer>
        </form>
        <FormItem>
          <ErrorBlock error={errors.serverError} />
        </FormItem>
        <div>
          <Caption style={{ textAlign: "center", marginTop: 16 }}>
            <Link to="/forgot-password">Forgot your password?</Link>
          </Caption>
        </div>
      </FormContainer>
    </Container>
  );
}
// import { useState } from "react";
// import styled from "styled-components";
// import queryString from "query-string";
// import { Link, useHistory, useLocation } from "react-router-dom";
// import { validate } from "email-validator";
// // LIB
// import setLocalStorage from "lib/helpers/setLocalStorage";
// import logo from "lib/media/logo.png";
// import config from "lib/config";
// // COMPONENST
// import Caption from "components/text/Caption";
// import FormItem from "components/common/FormItem";
// import message from "components/common/message";
// import ErrorBlock from "components/common/ErrorBlock";
// import Button from "components/common/Button";
// import TextInput from "components/inputs/TextInput";
// // APOLLO
// import ApolloClient from "ApolloClient/index";
// import {
//   CurrentUserDocument,
//   useAuthenticateMutation,
//   UserProfile,
// } from "generated/graphql";

// const Logo = styled.img`
//   height: 100px;
//   margin: auto;
// `;

// const TitleContainer = styled.div`
//   min-height: 95px;
//   text-align: center;
// `;

// const FormContainer = styled.div`
//   width: 500px;
//   padding: 16px;
//   max-width: 100%;
//   margin: auto;
//   padding-top: 20px;
//   background: #fff;
//   padding: 32px 64px;
//   border-radius: 5px;
//   @media only screen and (max-width: 414px) {
//     padding: 20px;
//   }
// `;

// const Container = styled.div`
//   align-items: center;
//   padding-top: 20px;
//   display: flex;
//   height: 100vh;
// `;

// const ButtonContainer = styled.div`
//   text-align: center;
//   margin-top: 10px;
// `;

// export default function AuthLogin({
//   currentUser,
// }: {
//   currentUser?: UserProfile;
// }) {
//   const [loading, setLoading] = useState(false);
//   const [errors, setErrors] = useState<string[] | []>([]);
//   const [email, setEmail] = useState<string | undefined>(undefined);
//   const [password, setPassword] = useState<string | undefined>(undefined);
//   const location = useLocation();
//   const history = useHistory();
//   const { redirectTo }: { redirectTo?: string } = queryString.parse(
//     location.search
//   );

//   const [authenticateMutation] = useAuthenticateMutation();

//   const onSubmit = async (e: any) => {
//     try {
//       e?.preventDefault();
//       // reset errors
//       setErrors([]);

//       // check that user added an email
//       if (!email) {
//         return setErrors(["Please provide an email"]);
//       }
//       // check if its a valid email
//       if (!validate(email)) {
//         return setErrors(["That is not a valid email"]);
//       }
//       // check that they give a password
//       if (!password) {
//         return setErrors(["Please provide a password"]);
//       }
//       setLoading(true);

//       const res = await authenticateMutation({
//         variables: {
//           params: {
//             password,
//             user: {
//               email,
//             },
//           },
//         },
//       });

//       if (
//         res?.data?.authenticate?.tokens?.accessToken &&
//         res?.data?.authenticate?.tokens?.refreshToken
//       ) {
//         setLocalStorage(
//           config.authTokenName,
//           res?.data.authenticate.tokens.accessToken
//         );
//         setLocalStorage(
//           config.refreshTokenName,
//           res?.data.authenticate.tokens.refreshToken
//         );
//       }

//       await ApolloClient.query({
//         query: CurrentUserDocument,
//         fetchPolicy: "network-only",
//       });

//       setTimeout(() => {
//         setLoading(false);
//         if (redirectTo) {
//           console.log("the redirect ran");
//           history.push(redirectTo);
//         }
//       }, 1000);

//       await ApolloClient.resetStore();

//       // window.location.reload();
//     } catch (err) {
//       let errMessage = err.message.replace("GraphQL", "");
//       if (err && err.message.includes("Incorrect password [403]")) {
//         errMessage = "You have entered an invalid username or password";
//       }
//       setLoading(false);
//       console.log([errMessage]);
//       return setErrors({
//         serverError: errMessage,
//       });
//     }
//   };

//   return (
//     <div
//       style={{
//         overflow: "hidden",
//         background: "#fff",
//       }}
//     >
//       <Container>
//         <FormContainer>
//           <TitleContainer>
//             <Logo src={logo} />
//             <Caption style={{ margin: "16px auto" }}>Welcome back!</Caption>
//           </TitleContainer>
//           <form onSubmit={onSubmit}>
//             <FormItem>
//               <TextInput
//                 value={email}
//                 placeholder="Email"
//                 onChange={(e) => setEmail(e.target.value.toLowerCase())}
//               />
//             </FormItem>
//             <FormItem>
//               <TextInput
//                 value={password}
//                 placeholder="Password"
//                 onChange={(e) => setPassword(e.target.value)}
//                 type="password"
//               />
//             </FormItem>
//             <ButtonContainer>
//               <Button
//                 disabled={loading}
//                 loading={loading}
//                 type="submit"
//                 onClick={onSubmit}
//               >
//                 Login
//               </Button>
//             </ButtonContainer>
//           </form>
//           <FormItem>
//             <ErrorBlock error={errors.serverError} />
//           </FormItem>
//           <div>
//             <Caption style={{ textAlign: "center", marginTop: 16 }}>
//               <Link to="/forgot-password">Forgot your password?</Link>
//             </Caption>
//           </div>
//         </FormContainer>
//       </Container>
//     </div>
//   );
// }
