import {
  DeviceSessionInput,
  SessionStatus,
  useGetOneBatchQuery,
  useUpdateOneDeviceSessionMutation,
} from "generated/graphql";
import message from "components/common/message";
import Loading from "components/common/Loading";
import { useState } from "react";
import Modal from "components/common/Modal";
import styled from "styled-components";
import Row from "components/common/Row";
import Col from "components/common/Col";
import theme from "lib/theme";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import DeviceSelectInput from "components/inputs/DeviceSelectInput";
import Editable, { EditableTypeEnum } from "components/common/Editable";
import OrganizationSelectInput from "components/inputs/OrganizationSelectInput";
import TrialSelectInput from "components/inputs/TrialSelectInput";
import SitesSelectInput from "components/inputs/SitesSelectInput";
import DeviceStatusSelectInput from "components/inputs/DeviceStatusSelectInput";
import graphqlErrorsIncludesCode from "lib/helpers/graphqlErrorsIncludesCode";

const { confirm } = Modal;

const columnSizes = {
  1: {
    xs: 2,
    style: { display: "flex", alignItems: "center", width: "100%" },
  },
  2: {
    xs: 4,
    style: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
  },
  3: {
    xs: 4,
  },
  4: {
    xs: 4,
  },
  5: {
    xs: 5,
  },
  6: {
    xs: 5,
  },
};

const ExpandHeader = styled.p`
  margin: 0px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.neutral6};
`;

export default function ExpandedRow({
  batchId,
  editable,
}: {
  batchId: string;
  editable: boolean;
}) {
  const [saved, setSaved] = useState<string[]>([]);
  const [updating, setUpdating] = useState<false | string>(false);
  const [updateOneDeviceSessionMutation] = useUpdateOneDeviceSessionMutation();
  const { data, loading } = useGetOneBatchQuery({
    variables: {
      id: batchId,
    },
  });

  const onUpdate = async (
    deviceSession: any,
    newValues: DeviceSessionInput,
    key: string
  ) => {
    try {
      // make sure we passed in a device session object
      if (!deviceSession?.id) return;

      const fieldKey = `${deviceSession?.id}-${key}`;

      // mark the session as updating
      setUpdating(fieldKey);

      await updateOneDeviceSessionMutation({
        variables: {
          id: deviceSession?.id,
          params: {
            ...newValues,
          },
        },
      });
      setUpdating(false);
      setSaved((saved: any) => [...saved, fieldKey]); // we basically mark that we've edited/successfully saved this field
    } catch (err: any) {
      const alreadyAssigned = graphqlErrorsIncludesCode(
        err,
        "ALREADY_ASSIGNED"
      );
      setUpdating(false);
      if (alreadyAssigned) {
        // @TODO: this should be checkking some kind of error code rather than the error message... would be more stable
        confirm({
          title: err?.message || "Your changes were not saved",
          content: "You changes were not saved.",
          icon: <ExclamationCircleOutlined />,
          type: "warning",
        });
      } else {
        message.error(err?.message);
      }
    }
  };

  return (
    <div
      style={{
        padding: 8,
      }}
    >
      <Row
        align="middle"
        gutter={16}
        style={{ height: 48, background: theme.colors.neutral10, padding: 8 }}
      >
        <Col {...columnSizes[1]}>
          <ExpandHeader>Session</ExpandHeader>
        </Col>
        <Col {...columnSizes[2]}>
          <ExpandHeader>Device</ExpandHeader>
        </Col>
        <Col {...columnSizes[3]}>
          <ExpandHeader>Client</ExpandHeader>
        </Col>
        <Col {...columnSizes[4]}>
          <ExpandHeader>Study</ExpandHeader>
        </Col>
        <Col {...columnSizes[5]}>
          <ExpandHeader>Site</ExpandHeader>
        </Col>
        <Col {...columnSizes[6]}>
          <ExpandHeader>Status</ExpandHeader>
        </Col>
        {/* <Col {...columnSizes[7]}>
          <ExpandHeader>Subject</ExpandHeader>
        </Col> */}
      </Row>
      <div style={{ height: 270, overflowY: "scroll" }}>
        {loading && <Loading height={100} />}
        {data?.getOneBatch?.deviceSessions?.results?.map((deviceSession) => {
          return (
            <Row
              key={deviceSession.id}
              style={{ height: 40, padding: 8 }}
              gutter={16}
            >
              <Col {...columnSizes[1]}>{deviceSession.sessionId}</Col>
              <Col {...columnSizes[2]}>
                <Editable
                  placeholder="Assign a device"
                  type={EditableTypeEnum.select}
                  text={deviceSession?.deviceId || ""}
                  editable={editable}
                  saving={updating === `${deviceSession?.id}-deviceId`}
                  saved={saved.includes(`${deviceSession?.id}-deviceId`)}
                >
                  <DeviceSelectInput
                    onChange={(newValue: any) =>
                      onUpdate(
                        deviceSession,
                        { deviceId: newValue },
                        "deviceId"
                      )
                    }
                  />
                </Editable>
              </Col>
              <Col {...columnSizes[3]}>
                <Editable
                  placeholder="Assign a client"
                  type={EditableTypeEnum.select}
                  editable={editable}
                  saving={updating === `${deviceSession?.id}-organizationId`}
                  saved={saved.includes(`${deviceSession?.id}-organizationId`)}
                  text={deviceSession?.organization?.name || ""}
                >
                  <OrganizationSelectInput
                    onChange={(newValue: any) =>
                      onUpdate(
                        deviceSession,
                        { organizationId: newValue },
                        "organizationId"
                      )
                    }
                  />
                </Editable>
              </Col>
              <Col {...columnSizes[4]}>
                <Editable
                  placeholder="Assign a study"
                  type={EditableTypeEnum.select}
                  editable={editable}
                  saving={updating === `${deviceSession?.id}-trialId`}
                  saved={saved.includes(`${deviceSession?.id}-trialId`)}
                  text={deviceSession?.trial?.name || ""}
                >
                  <TrialSelectInput
                    organizationIds={
                      deviceSession?.organization?.id
                        ? [deviceSession?.organization?.id]
                        : []
                    }
                    onChange={(newValue: any) =>
                      onUpdate(deviceSession, { trialId: newValue }, "trialId")
                    }
                  />
                </Editable>
              </Col>
              <Col {...columnSizes[5]}>
                <Editable
                  placeholder="Assign a site"
                  type={EditableTypeEnum.select}
                  editable={editable}
                  saving={updating === `${deviceSession?.id}-siteId`}
                  saved={saved.includes(`${deviceSession?.id}-siteId`)}
                  text={deviceSession?.site?.name || ""}
                >
                  <SitesSelectInput
                    organizationIds={
                      deviceSession?.organization?.id
                        ? [deviceSession?.organization?.id]
                        : []
                    }
                    trialIds={
                      deviceSession?.trial?.id ? [deviceSession?.trial?.id] : []
                    }
                    onChange={(newValue: any) =>
                      onUpdate(deviceSession, { siteId: newValue }, "siteId")
                    }
                  />
                </Editable>
              </Col>
              <Col {...columnSizes[6]}>
                <Editable
                  placeholder="Assign a status"
                  type={EditableTypeEnum.select}
                  editable={editable}
                  saving={updating === `${deviceSession?.id}-status`}
                  saved={saved.includes(`${deviceSession?.id}-status`)}
                  text={deviceSession.sessionStatus?.label}
                >
                  <DeviceStatusSelectInput
                    onChange={(newValue: SessionStatus) => {
                      if (deviceSession?.sessionStatus?.id === newValue?.id)
                        return;
                      return confirm({
                        title: `Are you sure you want to update ${deviceSession.sessionId} from ${deviceSession?.sessionStatus?.label} to ${newValue?.label}?`,
                        content:
                          "Please review these changes before confirming",
                        icon: <ExclamationCircleOutlined />,
                        type: "warning",
                        okText: `Yes, I'm sure`,
                        onOk: () =>
                          onUpdate(
                            deviceSession,
                            { sessionStatusId: newValue?.id },
                            "status"
                          ),
                      });
                    }}
                  />
                </Editable>
              </Col>
              {/* <Col {...columnSizes[7]}>
                <Editable
                  placeholder="Assign a subject"
                  type={EditableTypeEnum.select}
                  editable={editable}
                  saving={updating === `${deviceSession?.id}-subjectId`}
                  saved={saved.includes(`${deviceSession?.id}-subjectId`)}
                  text={deviceSession?.subjectId || ""}
                >
                  <SubjectSelectInput
                    organizationId={deviceSession?.organization?.id}
                    onChange={(newValue: any) =>
                      onUpdate(
                        deviceSession,
                        { subjectId: newValue },
                        "subjectId"
                      )
                    }
                  />
                </Editable>
              </Col> */}
            </Row>
          );
        })}
      </div>
    </div>
  );
}
