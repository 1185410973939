import { gql } from "@apollo/client";

export const updateSessionSelected = gql`
  mutation UpdateSession($input: UpdateSessionInput!) {
    updateSession(input: $input) {
      selected
      id
    }
  }
`;

export const updateSessionReviewed = gql`
  mutation UpdateSessionReviewed($input: UpdateSessionInput!) {
    updateSession(input: $input) {
      status {
        key
      }
      id
    }
  }
`;

export const updateSessionReference = gql`
  mutation UpdateSessionReference($input: UpdateSessionInput!) {
    updateSession(input: $input) {
      id
    }
  }
`;

export const resolveSessionIssue = gql`
  mutation resolveSessionIssue($input: ResolveSessionIssueInput!) {
    resolveSessionIssue(input: $input) {
      id
    }
  }
`;
