import { useEffect, useState } from "react";
import queryString from "query-string";
import {
  GetManyOrganizationsDocument,
  useCreateOrganizationMutation,
  useImpersonateOrganizationMutation,
  useFindClientsQuery,
  Study,
  Client,
} from "generated/graphql";
import Caption from "components/text/Caption";
import Table from "components/common/Table";
import Drawer from "components/common/Drawer";
import message from "components/common/message";
import Button from "components/common/Button";
import OrganizationForm from "components/forms/OrganizationForm";
import PageTitle from "components/text/PageTitle";
import useUrlChange from "lib/hooks/useUrlChange";
import ButtonText from "components/common/ButtonText";
import ClientDetail from "./ClientDetail";
import styled from "styled-components";
import { SearchOutlined } from "@ant-design/icons";
import theme from "lib/theme";
import StudiesCard from "components/common/StudiesCard";
import FilterColNames from "components/common/FilterColNames";

const TextBtn = styled.button`
  border: 0px;
  background-color: ${({ theme }) => theme.colors.buttonComplete};
  padding: 0px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white1};
  font-weight: 600;
  margin-left: 16px;
  width: 108px;
  height: 33px;
  border-radius: 8px;
`;

export type ClientsSite = {
  __typename?: "ClientsSite";
  id: string;
  name?: string;
  studies?: string[] | any;
  active?: boolean;
};

export default function AdminClients({ location, currentUser }: any) {
  const { organizationId, trialsId, studyId } = queryString.parse(
    location.search
  );
  const { onUrlChange } = useUrlChange();

  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    offset: 0,
    total: 10,
    limit: 10,
  });

  const [activeKey, setActiveKey] = useState("2");
  const onKeyChange = (key: any) => setActiveKey(key);

  const { data, loading, error } = useFindClientsQuery({
    variables: {
      pagination: {
        offset: (pagination.current - 1) * pagination.limit,
        limit: pagination.limit,
      },
    },
  });

  useEffect(() => {
    setPagination({ ...pagination, total: data?.findClients.count || 10 });
  }, [data]);

  const [createOrganizationMutation, { loading: creating }] =
    useCreateOrganizationMutation();

  const [impersonateOrganizationMutation, { loading: impersonating }] =
    useImpersonateOrganizationMutation();

  const [addNew, setAddNew] = useState<boolean>(false);
  if (loading) return <Caption>Loading...</Caption>;

  if (error) return <Caption>Error...</Caption>;

  const handleCreateOrg = async (values: { name?: string }) => {
    try {
      await createOrganizationMutation({
        variables: {
          params: {
            name: values?.name,
          },
        },
        refetchQueries: [
          {
            query: GetManyOrganizationsDocument,
          },
        ],
      });
      setAddNew(false);
      message.success("New client added");
    } catch (err) {
      console.log(err);
    }
  };

  const handleImpersonate = async (organizationId: string) => {
    try {
      // validate the input is a string
      if (!organizationId || typeof organizationId !== "string") return;
      // call the mutation
      await impersonateOrganizationMutation({
        variables: {
          organizationId,
        },
      });
      // const open = get(window, 'open')
      // open(`${window?.location?.origin}/app`, '_blank').focus();
      window.open(`${window?.location?.origin}/app`, "_blank")?.focus();
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (record: Client) => (
        <ButtonText
          style={{ textAlign: "left" }}
          onClick={() => {
            onUrlChange({ organizationId: record?.id });
            onKeyChange("2");
          }}
        >
          {record?.name}
        </ButtonText>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => {
        return (
          <FilterColNames
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            dataSource={data?.findClients?.items || []}
            placeHolder="Search By Name"
          />
        );
      },
      filterIcon: () => (
        <SearchOutlined
          style={{
            color: theme.colors.gray2,
            fontSize: 18,
          }}
        />
      ),
      onFilter: (value: string, record: Client) => {
        return (
          record.name?.toLowerCase().indexOf(value.toString().toLowerCase()) ===
          0
        );
      },
    },

    {
      title: "Studies",
      key: "studies",
      render: (client: Client) => (
        <>
          {client?.studies?.map((study: Study | any) => {
            return (
              <StudiesCard
                onClick={() => {
                  onUrlChange({ organizationId: client?.id });
                  onKeyChange("2");
                }}
              >
                {study.name}
              </StudiesCard>
            );
          })}
        </>
      ),
    },
    {
      title: "Active",
      render: (client: any) => {
        const clientStudies = client?.studies;
        let activeStudy = false;
        clientStudies.forEach((study: any) => {
          activeStudy = activeStudy || study?.active;
        });
        return (
          <div
            style={
              activeStudy
                ? { color: theme.colors.green1 }
                : { color: theme.colors.gray1 }
            }
          >
            {activeStudy ? "yes" : "no"}
          </div>
        );
      },
      sorter: (clientA: any, clientB: any) => {
        const clientAStudies = clientA?.studies;
        let clientAStudy = false;
        clientAStudies.forEach((study: any) => {
          clientAStudy = clientAStudy || study?.active;
        });

        const clientBStudies = clientB?.studies;
        let clientBStudy = false;
        clientBStudies.forEach((study: any) => {
          clientBStudy = clientBStudy || study?.active;
        });

        return Number(clientAStudy) - Number(clientBStudy);
      },
    },
    {
      title: "",
      render: (record: any) => (
        <TextBtn
          onClick={() => handleImpersonate(record?.id as string)}
          disabled={impersonating}
        >
          Launch Site
        </TextBtn>
      ),
    },
  ];

  if (organizationId) {
    return (
      <ClientDetail
        studyId={studyId as string}
        organizationId={organizationId as string}
        currentUser={currentUser}
        tabKey={activeKey as string}
        onKeyChange={onKeyChange}
        trialsId={trialsId as string}
      />
    );
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 32,
        }}
      >
        <PageTitle style={{ color: theme.colors.blue16, fontSize: 30 }}>
          Clients
        </PageTitle>
        <Button
          onClick={() => setAddNew(true)}
          style={{ width: 160, marginBottom: 16 }}
        >
          + Add Client
        </Button>
      </div>
      <Table
        dataSource={data?.findClients?.items as any}
        columns={columns?.map((item: any) => item)}
        rowKey="id"
        onChange={(tablePaginationObject) => {
          setPagination(tablePaginationObject);
        }}
        pagination={pagination}
      />
      <Drawer
        title="Create New Client"
        placement="right"
        width={500}
        onClose={() => setAddNew(false)}
        visible={addNew ? true : false}
        height={200}
        getContainer={false}
        style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      >
        <OrganizationForm
          onSubmit={handleCreateOrg}
          onCancel={() => setAddNew(false)}
          loading={creating}
        />
      </Drawer>
    </div>
  );
}
