import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { Maybe, Trial, useGetManyTrialsQuery } from "generated/graphql";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const TrialSelectInput = (props: {
  onChange: any;
  value?: Maybe<string>;
  trials?: Trial[];
  style?: React.CSSProperties;
  organizationIds?: string[];
}) => {
  const { trials, onChange, organizationIds, value = "", style } = props;
  const { data } = useGetManyTrialsQuery({
    variables: {
      organizationIds: organizationIds || [],
    },
  });

  const options = Array.isArray(trials) ? trials : data?.getManyTrials;
  return (
    <>
      <Select
        {...props}
        onChange={onChange}
        value={value || undefined}
        optionFilterProp="children"
        placeholder="Select a study"
        style={{
          width: "100%",
          margin: "auto",
          ...style,
        }}
      >
        {options?.map((trial) => {
          if (!trial?.id) return null;
          return (
            <Option key={trial?.id} value={trial?.id}>
              {trial?.name}
            </Option>
          );
        })}
      </Select>
    </>
  );
};

// EXPORT
// ================================================================
export default TrialSelectInput;
