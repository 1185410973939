import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import SitesSelectInput from "components/inputs/SitesSelectInput";
import TrialSelectInput from "components/inputs/TrialSelectInput";
import OrganizationSelectInput from "components/inputs/OrganizationSelectInput";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import {
  Organization,
  Site,
  Trial,
  useGetManyOrganizationsQuery,
  useGetManySitesQuery,
  useGetManyTrialsQuery,
} from "generated/graphql";
import { toNumber } from "lodash";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

interface SiteFormProps {
  onSubmit: any;
  onCancel: any;
  loading?: boolean;
  defaultValues?: {
    sessionCount?: number | null;
    siteId?: string | null;
    trialId?: string | null;
    organizationId?: string | null;
  };
}

export default function BatchForm({
  onSubmit,
  onCancel,
  loading,
  defaultValues,
}: SiteFormProps) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      sessionCount: defaultValues?.sessionCount,
      siteId: defaultValues?.siteId,
      trialId: defaultValues?.trialId,
      organizationId: defaultValues?.organizationId,
    },
  });
  const trialId = watch("trialId");
  const organizationId = watch("organizationId");
  const { data: organizationsQuery } = useGetManyOrganizationsQuery({
    fetchPolicy: "cache-and-network",
  });
  const { data: sitesQuery } = useGetManySitesQuery({
    variables: {
      trialIds: trialId ? [trialId] : null,
      organizationIds: organizationId ? [organizationId] : null,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: trialsQuery } = useGetManyTrialsQuery({
    variables: {
      organizationIds: organizationId ? [organizationId] : null,
    },
    fetchPolicy: "cache-and-network",
  });

  const beforeOnSubmit = handleSubmit((data) => {
    if (!data) return;
    onSubmit({
      ...data,
      sessionCount: toNumber(data.sessionCount),
    });
  });

  return (
    <Form onSubmit={beforeOnSubmit}>
      <FormItem
        label="Session Count"
        required
        error={errors?.sessionCount?.message}
      >
        <TextInput
          {...register("sessionCount", {
            required: "Session count is required.",
            max: 100,
            min: 0,
          })}
          type="number"
          max={100}
          min={0}
          placeholder="How many sessions should we create?"
        />
      </FormItem>
      <FormItem label="Client" error={errors?.organizationId?.message}>
        <Controller
          name="organizationId"
          control={control}
          render={({ field }) => (
            <DropdownStyleWrapper>
              <OrganizationSelectInput
                {...field}
                value={field?.value as string}
                organizations={
                  organizationsQuery?.getManyOrganizations as Organization[]
                }
                onChange={(organizationId: any) => {
                  field.onChange(organizationId);
                }}
              />
            </DropdownStyleWrapper>
          )}
        />
      </FormItem>
      <FormItem label="Study" error={errors?.trialId?.message}>
        <Controller
          name="trialId"
          control={control}
          render={({ field }) => (
            <DropdownStyleWrapper>
              <TrialSelectInput
                {...field}
                value={field?.value as string}
                trials={trialsQuery?.getManyTrials as Trial[]}
                onChange={(trialId: any) => {
                  field.onChange(trialId);
                }}
              />
            </DropdownStyleWrapper>
          )}
        />
      </FormItem>
      <FormItem label="Site" error={errors?.siteId?.message}>
        <Controller
          name="siteId"
          control={control}
          render={({ field }) => (
            <DropdownStyleWrapper>
              <SitesSelectInput
                {...field}
                value={field?.value as string}
                sites={sitesQuery?.getManySites as Site[]}
                onChange={(siteId: any) => {
                  field.onChange(siteId);
                }}
              />
            </DropdownStyleWrapper>
          )}
        />
      </FormItem>
      <FormItem>
        <Button
          type="button"
          onClick={onCancel}
          grey
          style={{ width: 100, marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button
          style={{ width: 100 }}
          type="submit"
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </FormItem>
    </Form>
  );
}
