import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  Currency: any;
  Date: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  SafeInt: any;
  Time: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type Access = {
  __typename?: "Access";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<AccessNameEnum>;
};

export type AccessItem = {
  __typename?: "AccessItem";
  key?: Maybe<AccessNameEnum>;
  name?: Maybe<Scalars["String"]>;
};

/**
 * ------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * ------------------------------------------------------
 */
export enum AccessNameEnum {
  Admin = "admin",
}

export type AccessSection = {
  __typename?: "AccessSection";
  sectionName?: Maybe<Scalars["String"]>;
  items?: Maybe<Array<Maybe<AccessItem>>>;
};

export type AuthenticateParamsInput = {
  access_token?: Maybe<Scalars["String"]>;
  access_token_secret?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  user?: Maybe<UserInput>;
  code?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
};

export type Base64File = {
  __typename?: "Base64File";
  fileName: Scalars["String"];
  content: Scalars["String"];
};

export type Batch = {
  __typename?: "Batch";
  id: Scalars["ID"];
  number?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  sessionCount?: Maybe<Scalars["Int"]>;
  deviceSessions?: Maybe<DeviceSessionsResult>;
};

export type BatchResults = {
  __typename?: "BatchResults";
  results?: Maybe<Array<Batch>>;
  count?: Maybe<Scalars["Int"]>;
};

export type ChartDataPoint = {
  __typename?: "ChartDataPoint";
  timestamp?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Float"]>;
  unit?: Maybe<Scalars["String"]>;
};

export type Client = {
  __typename?: "Client";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  studies?: Maybe<Array<Maybe<Study>>>;
  dataUpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type Client24HrSummary = {
  __typename?: "Client24HrSummary";
  change?: Maybe<Scalars["Float"]>;
  start?: Maybe<Scalars["Float"]>;
  end?: Maybe<Scalars["Float"]>;
};

export type ClientResultList = {
  __typename?: "ClientResultList";
  items: Array<Maybe<Client>>;
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  count?: Maybe<Scalars["Int"]>;
};

export type ClientStatusSummary = {
  __typename?: "ClientStatusSummary";
  clean?: Maybe<Scalars["Int"]>;
  withIssue?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type CoughStats = {
  __typename?: "CoughStats";
  avgFirstDay?: Maybe<Scalars["Int"]>;
  avgLastDay?: Maybe<Scalars["Int"]>;
  avgChange?: Maybe<Scalars["Int"]>;
};

export type CreateDailySubjectReportInput = {
  subjectId: Scalars["String"];
  clientName: Scalars["String"];
  data: Array<DailySubjectReportDataInput>;
};

export type CreateNewUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  organizationId?: Maybe<Scalars["ID"]>;
  permission?: Maybe<Scalars["ID"]>;
  customPermissions?: Maybe<Array<Maybe<AccessNameEnum>>>;
  organizationIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  trialIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type CreateSessionInput = {
  deviceId: Scalars["String"];
  uploadId: Scalars["String"];
  startDate: Scalars["Timestamp"];
  endDate: Scalars["Timestamp"];
  subjectId: Scalars["String"];
  clientName: Scalars["String"];
  studyName: Scalars["String"];
  siteName: Scalars["String"];
  issues?: Maybe<Array<Scalars["String"]>>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  hardwareVersion?: Maybe<Scalars["String"]>;
  deviceStorage?: Maybe<Scalars["String"]>;
};

export type CreateSessionIssueInput = {
  sessionId: Scalars["ID"];
  description: Scalars["String"];
  issueType?: Maybe<DeviceSessionIssueTypeEnum>;
  resolved?: Maybe<Scalars["Boolean"]>;
};

export type CreateSessionProjectInput = {
  uploadId: Scalars["String"];
};

export type CreateSubjectIssueInput = {
  subjectId: Scalars["ID"];
  description: Scalars["String"];
  issueType?: Maybe<DeviceSessionIssueTypeEnum>;
  resolved?: Maybe<Scalars["Boolean"]>;
};

export type CreateUserInput = {
  username?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
};

export type CreateUserResult = {
  __typename?: "CreateUserResult";
  userId?: Maybe<Scalars["ID"]>;
  loginResult?: Maybe<LoginResult>;
};

export type DailyEvent = {
  __typename?: "DailyEvent";
  day?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Float"]>;
};

export type DailySubjectReport = {
  __typename?: "DailySubjectReport";
  id?: Maybe<Scalars["ID"]>;
  subject?: Maybe<Scalars["ID"]>;
  data?: Maybe<Array<Maybe<DailySubjectReportData>>>;
  firstDayCoughs?: Maybe<Scalars["Int"]>;
  lastDayCoughs?: Maybe<Scalars["Int"]>;
  coughsChange?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
};

export type DailySubjectReportData = {
  __typename?: "DailySubjectReportData";
  day?: Maybe<Scalars["String"]>;
  coughs?: Maybe<Scalars["Int"]>;
  avgHrCoughs?: Maybe<Scalars["Float"]>;
  awakeCoughs?: Maybe<Scalars["Int"]>;
  medRespRate?: Maybe<Scalars["Float"]>;
  q1RespRate?: Maybe<Scalars["Float"]>;
  q3RespRate?: Maybe<Scalars["Float"]>;
  steps?: Maybe<Scalars["Int"]>;
};

export type DailySubjectReportDataInput = {
  day: Scalars["String"];
  coughs?: Maybe<Scalars["Float"]>;
  avgHrCoughs?: Maybe<Scalars["Float"]>;
  awakeCoughs?: Maybe<Scalars["Float"]>;
  medRespRate?: Maybe<Scalars["Float"]>;
  q1RespRate?: Maybe<Scalars["Float"]>;
  q3RespRate?: Maybe<Scalars["Float"]>;
  steps?: Maybe<Scalars["Float"]>;
};

export type Device = {
  __typename?: "Device";
  id: Scalars["ID"];
  deviceId?: Maybe<Scalars["String"]>;
  deviceStatus?: Maybe<DeviceStatusEnum>;
};

export enum DeviceAnnotationStatusEnum {
  Pending = "pending",
  Initiated = "initiated",
  UnderReview = "under_review",
  Completed = "completed",
  Reviewed = "reviewed",
  Available = "available",
}

export type DeviceEvent = {
  __typename?: "DeviceEvent";
  id?: Maybe<Scalars["ID"]>;
  value?: Maybe<Scalars["Int"]>;
  unit?: Maybe<Scalars["String"]>;
  eventType?: Maybe<EventTypeEnum>;
  sessionId?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["String"]>;
};

export type DeviceEventResults = {
  __typename?: "DeviceEventResults";
  results?: Maybe<Array<DeviceEvent>>;
  count?: Maybe<Scalars["Int"]>;
};

export type DeviceResults = {
  __typename?: "DeviceResults";
  results?: Maybe<Array<Device>>;
  count?: Maybe<Scalars["Int"]>;
};

export type DeviceSession = {
  __typename?: "DeviceSession";
  /** unique DB id */
  id: Scalars["ID"];
  /** ID of the session */
  sessionId?: Maybe<Scalars["String"]>;
  /** ID of the device */
  deviceId?: Maybe<Scalars["String"]>;
  /** aka client id */
  organizationId?: Maybe<Scalars["String"]>;
  organization?: Maybe<Organization>;
  siteId?: Maybe<Scalars["String"]>;
  site?: Maybe<Site>;
  /** aka the study id */
  trialId?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Int"]>;
  analysisStatus?: Maybe<SessionAnalysisStatus>;
  sessionStatus?: Maybe<SessionStatus>;
  issues?: Maybe<Array<DeviceSessionIssue>>;
  dataPoints?: Maybe<Array<DeviceSessionDataPoint>>;
  dailyEvents?: Maybe<Array<DailyEvent>>;
  firstDay?: Maybe<Scalars["Int"]>;
  lastDay?: Maybe<Scalars["Int"]>;
  change?: Maybe<Scalars["Int"]>;
  startDate?: Maybe<Scalars["String"]>;
  trial?: Maybe<Trial>;
  subjectName?: Maybe<Scalars["String"]>;
  sessionSummaries?: Maybe<Array<SessionSummary>>;
};

/** TODO: Change DeviceSessionEvent */
export type DeviceSessionDataPoint = {
  __typename?: "DeviceSessionDataPoint";
  startTime?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
  eventType?: Maybe<EventTypeEnum>;
  deviceId?: Maybe<Scalars["String"]>;
  sessionId?: Maybe<Scalars["String"]>;
};

export type DeviceSessionDataPoint2 = {
  __typename?: "DeviceSessionDataPoint2";
  id?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  data?: Maybe<Array<Maybe<SessionDataPoint>>>;
};

export type DeviceSessionInput = {
  sessionStatusId?: Maybe<Scalars["ID"]>;
  deviceId?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["ID"]>;
  trialId?: Maybe<Scalars["ID"]>;
  siteId?: Maybe<Scalars["ID"]>;
  subjectId?: Maybe<Scalars["String"]>;
};

export type DeviceSessionIssue = {
  __typename?: "DeviceSessionIssue";
  id?: Maybe<Scalars["ID"]>;
  issueType?: Maybe<DeviceSessionIssueTypeEnum>;
  description?: Maybe<Scalars["String"]>;
  deviceSessionId?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["String"]>;
  resolved?: Maybe<Scalars["Boolean"]>;
};

export enum DeviceSessionIssueTypeEnum {
  ClientReported = "client_reported",
  ParticipantReported = "participant_reported",
  DeviceIssue = "device_issue",
  OtherIssue = "other_issue",
}

export type DeviceSessionQueryParams = {
  organizationIds?: Maybe<Array<Scalars["ID"]>>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  trialIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  batchIds?: Maybe<Array<Scalars["ID"]>>;
  sessionStatuses?: Maybe<Array<Scalars["Int"]>>;
  annotationStatuses?: Maybe<Array<DeviceAnnotationStatusEnum>>;
  issueStatuses?: Maybe<Array<DeviceSessionIssueTypeEnum>>;
  sessionId?: Maybe<Scalars["String"]>;
  deviceId?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
};

export type DeviceSessionsResult = {
  __typename?: "DeviceSessionsResult";
  results?: Maybe<Array<DeviceSession>>;
  count?: Maybe<Scalars["Int"]>;
};

export enum DeviceStatusEnum {
  TestPassed = "testPassed",
  Available = "available",
  TestFailed = "testFailed",
  Flagged = "flagged",
  Assigned = "assigned",
}

export type EmailRecord = {
  __typename?: "EmailRecord";
  address?: Maybe<Scalars["String"]>;
  verified?: Maybe<Scalars["Boolean"]>;
};

export enum EventTypeEnum {
  Cough = "cough",
}

export type ImpersonateReturn = {
  __typename?: "ImpersonateReturn";
  authorized?: Maybe<Scalars["Boolean"]>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

export type ImpersonationUserIdentityInput = {
  userId?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type LoginResult = {
  __typename?: "LoginResult";
  sessionId?: Maybe<Scalars["String"]>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

export type Mutation = {
  __typename?: "Mutation";
  createUser?: Maybe<CreateUserResult>;
  verifyEmail?: Maybe<Scalars["Boolean"]>;
  resetPassword?: Maybe<LoginResult>;
  sendVerificationEmail?: Maybe<Scalars["Boolean"]>;
  sendResetPasswordEmail?: Maybe<Scalars["Boolean"]>;
  addEmail?: Maybe<Scalars["Boolean"]>;
  changePassword?: Maybe<Scalars["Boolean"]>;
  twoFactorSet?: Maybe<Scalars["Boolean"]>;
  twoFactorUnset?: Maybe<Scalars["Boolean"]>;
  impersonate?: Maybe<ImpersonateReturn>;
  refreshTokens?: Maybe<LoginResult>;
  logout?: Maybe<Scalars["Boolean"]>;
  authenticate?: Maybe<LoginResult>;
  verifyAuthentication?: Maybe<Scalars["Boolean"]>;
  /** Returns a URL */
  downloadDeviceEventsXpt?: Maybe<XptResponse>;
  deleteOneBatch?: Maybe<MutationResponse>;
  createOneDevice?: Maybe<Device>;
  createOneBatch?: Maybe<MutationResponse>;
  createManySubjects?: Maybe<MutationResponse>;
  deleteOneSuperAdminUser?: Maybe<MutationResponse>;
  updateOneTrial?: Maybe<Trial>;
  createPermission?: Maybe<Permission>;
  updateOneUser?: Maybe<UserProfile>;
  createOrganization?: Maybe<Organization>;
  createNewUser?: Maybe<UserProfile>;
  createSuperAdminUser?: Maybe<UserProfile>;
  /** Resend invite email */
  resendInviteEmail?: Maybe<MutationResponse>;
  /** Delete a user */
  deleteUser?: Maybe<MutationResponse>;
  /** Update an organization */
  updateOneOrganization?: Maybe<Organization>;
  createStudy?: Maybe<Study>;
  createSite?: Maybe<Site>;
  impersonateOrganization?: Maybe<UserProfile>;
  /** Updates the site a user is currently viewing */
  updateActiveSiteId?: Maybe<UserProfile>;
  /** Updates the trial a user is currently viewing */
  updateActiveTrialId?: Maybe<UserProfile>;
  releaseSessions?: Maybe<Trial>;
  updateOneDeviceSession?: Maybe<DeviceSession>;
  createOneIssue?: Maybe<DeviceSession>;
  createSessionIssue?: Maybe<Session>;
  resolveSessionIssue?: Maybe<DeviceSessionIssue>;
  createSubjectIssue?: Maybe<Subject>;
  updateSubject?: Maybe<Subject>;
  createSession?: Maybe<Session>;
  syncSession?: Maybe<Session>;
  createDailySubjectReport?: Maybe<DailySubjectReport>;
  selectDailySubjectReport?: Maybe<DailySubjectReport>;
  updateSessionAnalysisStatus?: Maybe<Session>;
  updateStudy?: Maybe<Study>;
  updateSession?: Maybe<Session>;
  createSessionProject?: Maybe<MutationResponse>;
  generateReport?: Maybe<DailySubjectReport>;
  updateSite?: Maybe<Site>;
};

export type MutationCreateUserArgs = {
  user: CreateUserInput;
};

export type MutationVerifyEmailArgs = {
  token: Scalars["String"];
};

export type MutationResetPasswordArgs = {
  token: Scalars["String"];
  newPassword: Scalars["String"];
};

export type MutationSendVerificationEmailArgs = {
  email: Scalars["String"];
};

export type MutationSendResetPasswordEmailArgs = {
  email: Scalars["String"];
};

export type MutationAddEmailArgs = {
  newEmail: Scalars["String"];
};

export type MutationChangePasswordArgs = {
  oldPassword: Scalars["String"];
  newPassword: Scalars["String"];
};

export type MutationTwoFactorSetArgs = {
  secret: TwoFactorSecretKeyInput;
  code: Scalars["String"];
};

export type MutationTwoFactorUnsetArgs = {
  code: Scalars["String"];
};

export type MutationImpersonateArgs = {
  accessToken: Scalars["String"];
  impersonated: ImpersonationUserIdentityInput;
};

export type MutationRefreshTokensArgs = {
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
};

export type MutationAuthenticateArgs = {
  serviceName: Scalars["String"];
  params: AuthenticateParamsInput;
};

export type MutationVerifyAuthenticationArgs = {
  serviceName: Scalars["String"];
  params: AuthenticateParamsInput;
};

export type MutationDownloadDeviceEventsXptArgs = {
  query?: Maybe<DeviceSessionQueryParams>;
};

export type MutationDeleteOneBatchArgs = {
  id: Scalars["ID"];
};

export type MutationCreateOneDeviceArgs = {
  deviceId: Scalars["String"];
};

export type MutationCreateOneBatchArgs = {
  sessionCount?: Maybe<Scalars["Int"]>;
  organizationId?: Maybe<Scalars["ID"]>;
  trialId?: Maybe<Scalars["ID"]>;
  siteId?: Maybe<Scalars["ID"]>;
};

export type MutationCreateManySubjectsArgs = {
  numberToCreate?: Maybe<Scalars["Int"]>;
  organizationId: Scalars["ID"];
  trialId?: Maybe<Scalars["ID"]>;
  siteId: Scalars["ID"];
};

export type MutationDeleteOneSuperAdminUserArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateOneTrialArgs = {
  id: Scalars["ID"];
  params?: Maybe<StudyInput>;
};

export type MutationCreatePermissionArgs = {
  params: PermissionInput;
};

export type MutationUpdateOneUserArgs = {
  id: Scalars["ID"];
  params?: Maybe<UpdateUserInput>;
};

export type MutationCreateOrganizationArgs = {
  params: OrganizationInput;
};

export type MutationCreateNewUserArgs = {
  params: CreateNewUserInput;
};

export type MutationCreateSuperAdminUserArgs = {
  params: CreateNewUserInput;
};

export type MutationResendInviteEmailArgs = {
  userId: Scalars["ID"];
};

export type MutationDeleteUserArgs = {
  userId: Scalars["ID"];
};

export type MutationUpdateOneOrganizationArgs = {
  id: Scalars["ID"];
  params?: Maybe<OrganizationInput>;
};

export type MutationCreateStudyArgs = {
  input: StudyInput;
};

export type MutationCreateSiteArgs = {
  input: SiteInput;
};

export type MutationImpersonateOrganizationArgs = {
  organizationId: Scalars["ID"];
};

export type MutationUpdateActiveSiteIdArgs = {
  siteId: Scalars["ID"];
};

export type MutationUpdateActiveTrialIdArgs = {
  trialId: Scalars["ID"];
};

export type MutationReleaseSessionsArgs = {
  trialId: Scalars["ID"];
};

export type MutationUpdateOneDeviceSessionArgs = {
  id: Scalars["ID"];
  params?: Maybe<DeviceSessionInput>;
};

export type MutationCreateOneIssueArgs = {
  deviceSessionId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  issueType?: Maybe<DeviceSessionIssueTypeEnum>;
};

export type MutationCreateSessionIssueArgs = {
  input: CreateSessionIssueInput;
};

export type MutationResolveSessionIssueArgs = {
  input: ResolveSessionIssueInput;
};

export type MutationCreateSubjectIssueArgs = {
  input: CreateSubjectIssueInput;
};

export type MutationUpdateSubjectArgs = {
  input: UpdateSubjectInput;
};

export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};

export type MutationSyncSessionArgs = {
  input: SyncSessionInput;
};

export type MutationCreateDailySubjectReportArgs = {
  input: CreateDailySubjectReportInput;
};

export type MutationSelectDailySubjectReportArgs = {
  input: SelectDailySubjectReportInput;
};

export type MutationUpdateSessionAnalysisStatusArgs = {
  input?: Maybe<UpdateSessionAnalysisStatus>;
};

export type MutationUpdateStudyArgs = {
  input: UpdateStudyInput;
};

export type MutationUpdateSessionArgs = {
  input: UpdateSessionInput;
};

export type MutationCreateSessionProjectArgs = {
  input: CreateSessionProjectInput;
};

export type MutationGenerateReportArgs = {
  subjectId?: Maybe<Scalars["String"]>;
};

export type MutationUpdateSiteArgs = {
  input: UpdateSiteInput;
};

export type MutationResponse = {
  __typename?: "MutationResponse";
  success?: Maybe<Scalars["Boolean"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type OldSubject = {
  __typename?: "OldSubject";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  trial?: Maybe<Trial>;
  site?: Maybe<Site>;
  organization?: Maybe<Organization>;
};

/** Deprecated */
export type Organization = {
  __typename?: "Organization";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  users?: Maybe<Array<Maybe<UserProfile>>>;
  dataUpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OrganizationInput = {
  name?: Maybe<Scalars["String"]>;
};

export type OtherDataOption = {
  __typename?: "OtherDataOption";
  key: OtherDataUnitEnum;
  label: Scalars["String"];
};

export type OtherDataResponse = {
  __typename?: "OtherDataResponse";
  unit?: Maybe<OtherDataUnitEnum>;
  sessionId?: Maybe<Scalars["String"]>;
  deviceId?: Maybe<Scalars["String"]>;
  firstDay?: Maybe<Scalars["Float"]>;
  lastDay?: Maybe<Scalars["Float"]>;
  change?: Maybe<Scalars["Float"]>;
  dailyEvents?: Maybe<Array<Maybe<DailyEvent>>>;
  label?: Maybe<Scalars["String"]>;
};

export enum OtherDataUnitEnum {
  Coughs = "coughs",
  AvgHrCoughs = "avgHrCoughs",
  AwakeCoughs = "awakeCoughs",
  MedRespRate = "medRespRate",
  Steps = "steps",
}

export type PaginationOpts = {
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type Permission = {
  __typename?: "Permission";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  accesses?: Maybe<Array<Maybe<Access>>>;
};

export type PermissionInput = {
  name: Scalars["String"];
  accesses: Array<AccessNameEnum>;
  description?: Maybe<Scalars["String"]>;
  admin_only?: Maybe<Scalars["Boolean"]>;
};

export type Query = {
  __typename?: "Query";
  twoFactorSecret?: Maybe<TwoFactorSecretKey>;
  getUser?: Maybe<User>;
  getManySessionSummaries?: Maybe<SessionSummariesResult>;
  otherDataOptions: Array<OtherDataOption>;
  getManyDeviceEvents?: Maybe<DeviceEventResults>;
  getManySessionStatuses?: Maybe<Array<SessionStatus>>;
  getManyDevices?: Maybe<DeviceResults>;
  getOtherData?: Maybe<Array<OtherDataResponse>>;
  getOneBatch?: Maybe<Batch>;
  getManyBatches?: Maybe<BatchResults>;
  getOneUser?: Maybe<UserProfile>;
  getManyTrials?: Maybe<Array<Maybe<Trial>>>;
  currentUser?: Maybe<UserProfile>;
  getManyOrganizations?: Maybe<Array<Organization>>;
  getManyUsers?: Maybe<Array<UserProfile>>;
  /** Return a single organization given an ID */
  getOneOrganization?: Maybe<Organization>;
  /** Returns a list of users for a given organization */
  getUsersByOrganization?: Maybe<Array<UserProfile>>;
  /** Returns a list of sites */
  getManySites?: Maybe<Array<Site>>;
  getManySubjects?: Maybe<Array<OldSubject>>;
  /** Return a single site given a site ID */
  getOneSite?: Maybe<Site>;
  /** Return a single trial given a trial ID */
  getOneTrial?: Maybe<Trial>;
  /** Return a paginated list of device sessions */
  getManyDeviceSessions?: Maybe<DeviceSessionsResult>;
  getLastSubjectCompletedAt?: Maybe<Scalars["String"]>;
  getStats_ClientWakingCoughs?: Maybe<Client24HrSummary>;
  getStats_24HourCoughs?: Maybe<Client24HrSummary>;
  getStats_ClientSessionStatus?: Maybe<ClientStatusSummary>;
  getStats_DeviceSessionAnnotationStatuses?: Maybe<Array<StatBlockDataItem>>;
  getStats_Issues?: Maybe<Stats_SessionsIssues>;
  /** Returns an object with device session totals */
  getStats_DeviceSessionsStatuses?: Maybe<Array<StatBlockDataItem>>;
  findSubjectSummaries?: Maybe<SubjectSummaryResultList>;
  findSubjectStatusTotals?: Maybe<Array<Maybe<SubjectStatusTotal>>>;
  findSubjectIssuesTotals?: Maybe<Array<Maybe<SubjectIssuesTotal>>>;
  findSubjectSummariesStats?: Maybe<SubjectSummariesStats>;
  findClients: ClientResultList;
  findStudies: StudyResultList;
  getSubjectReportFiles?: Maybe<Base64File>;
  getSessionReportFiles?: Maybe<Base64File>;
  getManyDailySubjectReports?: Maybe<Array<Maybe<DailySubjectReport>>>;
  getSessionByUploadId?: Maybe<Session>;
  getSessionLogs?: Maybe<Array<Maybe<SessionLog>>>;
  getSelectedReport?: Maybe<DailySubjectReport>;
};

export type QueryGetManySessionSummariesArgs = {
  clientIds?: Maybe<Array<Scalars["ID"]>>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  studyIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  batchIds?: Maybe<Array<Scalars["ID"]>>;
  issueStatuses?: Maybe<Array<DeviceSessionIssueTypeEnum>>;
  sessionId?: Maybe<Scalars["String"]>;
  deviceId?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  pagination?: Maybe<PaginationOpts>;
  sort?: Maybe<Array<Maybe<SortSessionDeviceInput>>>;
};

export type QueryGetManyDeviceEventsArgs = {
  organizationIds?: Maybe<Array<Scalars["ID"]>>;
  active?: Maybe<Scalars["Boolean"]>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  trialIds?: Maybe<Array<Scalars["ID"]>>;
  sessionStatuses?: Maybe<Array<Scalars["Int"]>>;
  annotationStatuses?: Maybe<Array<DeviceAnnotationStatusEnum>>;
  sessionId?: Maybe<Scalars["String"]>;
  deviceId?: Maybe<Scalars["String"]>;
};

export type QueryGetManyDevicesArgs = {
  searchText?: Maybe<Scalars["String"]>;
  deviceStatus?: Maybe<DeviceStatusEnum>;
};

export type QueryGetOtherDataArgs = {
  unit?: Maybe<OtherDataUnitEnum>;
  organizationIds?: Maybe<Array<Scalars["ID"]>>;
  sessionStatuses?: Maybe<Array<Scalars["Int"]>>;
  sessionId?: Maybe<Scalars["String"]>;
};

export type QueryGetOneBatchArgs = {
  id: Scalars["ID"];
};

export type QueryGetManyBatchesArgs = {
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type QueryGetOneUserArgs = {
  id: Scalars["ID"];
};

export type QueryGetManyTrialsArgs = {
  organizationIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  active?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetManyUsersArgs = {
  userType?: Maybe<UserTypeEnum>;
  deactivated?: Maybe<Scalars["Boolean"]>;
  organizationId?: Maybe<Scalars["ID"]>;
};

export type QueryGetOneOrganizationArgs = {
  id: Scalars["ID"];
};

export type QueryGetUsersByOrganizationArgs = {
  organizationId: Scalars["ID"];
  deactivated?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetManySitesArgs = {
  trialIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  organizationIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type QueryGetManySubjectsArgs = {
  trialIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  siteIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  organizationIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  searchText?: Maybe<Scalars["String"]>;
};

export type QueryGetOneSiteArgs = {
  siteId?: Maybe<Scalars["ID"]>;
};

export type QueryGetOneTrialArgs = {
  trialId: Scalars["ID"];
};

export type QueryGetManyDeviceSessionsArgs = {
  organizationIds?: Maybe<Array<Scalars["ID"]>>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  trialIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  batchIds?: Maybe<Array<Scalars["ID"]>>;
  sessionStatuses?: Maybe<Array<Scalars["Int"]>>;
  annotationStatuses?: Maybe<Array<DeviceAnnotationStatusEnum>>;
  issueStatuses?: Maybe<Array<DeviceSessionIssueTypeEnum>>;
  sessionId?: Maybe<Scalars["String"]>;
  deviceId?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  pagination?: Maybe<PaginationOpts>;
  sort?: Maybe<Array<Maybe<SortSessionDeviceInput>>>;
};

export type QueryGetStats_ClientSessionStatusArgs = {
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  trialIds?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryGetStats_DeviceSessionAnnotationStatusesArgs = {
  organizationIds?: Maybe<Array<Scalars["ID"]>>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  trialIds?: Maybe<Array<Scalars["ID"]>>;
  sessionStatuses?: Maybe<Array<Scalars["ID"]>>;
  issueStatuses?: Maybe<Array<Maybe<DeviceSessionIssueTypeEnum>>>;
  active?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetStats_IssuesArgs = {
  organizationIds?: Maybe<Array<Scalars["ID"]>>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  trialIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  annotationStatuses?: Maybe<Array<Scalars["ID"]>>;
  sessionStatuses?: Maybe<Array<Scalars["ID"]>>;
  active?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetStats_DeviceSessionsStatusesArgs = {
  organizationIds?: Maybe<Array<Scalars["ID"]>>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  trialIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  issueStatuses?: Maybe<Array<Maybe<DeviceSessionIssueTypeEnum>>>;
  annotationStatuses?: Maybe<Array<Scalars["ID"]>>;
  active?: Maybe<Scalars["Boolean"]>;
};

export type QueryFindSubjectSummariesArgs = {
  subjectId?: Maybe<Scalars["String"]>;
  clientIds?: Maybe<Array<Scalars["ID"]>>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  studyIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  deviceIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  uploadIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sessionIds?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  sessionStartDate?: Maybe<Scalars["DateTime"]>;
  sessionEndDate?: Maybe<Scalars["DateTime"]>;
  issueTypes?: Maybe<Array<Maybe<DeviceSessionIssueTypeEnum>>>;
  statuses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  active?: Maybe<Scalars["Boolean"]>;
  sort?: Maybe<SortSubjectSummaryInput>;
  pagination?: Maybe<PaginationOpts>;
};

export type QueryFindSubjectStatusTotalsArgs = {
  clientIds?: Maybe<Array<Scalars["ID"]>>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  studyIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  active?: Maybe<Scalars["Boolean"]>;
  issueTypes?: Maybe<Array<Maybe<DeviceSessionIssueTypeEnum>>>;
  uploadIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  deviceIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  subjectIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  sessionIds?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  sessionStartDate?: Maybe<Scalars["DateTime"]>;
  sessionEndDate?: Maybe<Scalars["DateTime"]>;
};

export type QueryFindSubjectIssuesTotalsArgs = {
  clientIds?: Maybe<Array<Scalars["ID"]>>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  studyIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  statuses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  active?: Maybe<Scalars["Boolean"]>;
  uploadIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  deviceIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  subjectIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  sessionIds?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  sessionStartDate?: Maybe<Scalars["DateTime"]>;
  sessionEndDate?: Maybe<Scalars["DateTime"]>;
};

export type QueryFindSubjectSummariesStatsArgs = {
  clientIds?: Maybe<Array<Scalars["ID"]>>;
  siteIds?: Maybe<Array<Scalars["ID"]>>;
  studyIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  issueTypes?: Maybe<Array<Maybe<DeviceSessionIssueTypeEnum>>>;
  statuses?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type QueryFindClientsArgs = {
  pagination?: Maybe<PaginationOpts>;
};

export type QueryFindStudiesArgs = {
  clientId?: Maybe<Scalars["ID"]>;
  pagination?: Maybe<PaginationOpts>;
};

export type QueryGetSubjectReportFilesArgs = {
  subjectId: Scalars["ID"];
};

export type QueryGetSessionReportFilesArgs = {
  sessionId: Scalars["ID"];
};

export type QueryGetManyDailySubjectReportsArgs = {
  subjectId: Scalars["ID"];
};

export type QueryGetSessionByUploadIdArgs = {
  uploadId: Scalars["ID"];
};

export type QueryGetSessionLogsArgs = {
  deviceSessionId: Scalars["ID"];
};

export type QueryGetSelectedReportArgs = {
  subjectId: Scalars["ID"];
};

export type ResolveSessionIssueInput = {
  sessionId: Scalars["ID"];
  sessionIssueId: Scalars["ID"];
  resolved?: Maybe<Scalars["Boolean"]>;
};

export type SelectDailySubjectReportInput = {
  reportID: Scalars["ID"];
};

export type Session = {
  __typename?: "Session";
  id: Scalars["ID"];
  index?: Maybe<Scalars["Int"]>;
  deviceId?: Maybe<Scalars["String"]>;
  siteId?: Maybe<Scalars["String"]>;
  status?: Maybe<SessionStatus>;
  issues?: Maybe<Array<DeviceSessionIssue>>;
  startDate?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  deviceStartDate?: Maybe<Scalars["DateTime"]>;
  deviceEndDate?: Maybe<Scalars["DateTime"]>;
  selected?: Maybe<Scalars["Boolean"]>;
  uploadId?: Maybe<Scalars["String"]>;
  history?: Maybe<Array<Maybe<History>>>;
  reference?: Maybe<SessionReference>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  hardwareVersion?: Maybe<Scalars["String"]>;
  deviceStorage?: Maybe<Scalars["String"]>;
  totalTasks?: Maybe<Scalars["Int"]>;
  completedTasks?: Maybe<Scalars["Int"]>;
  subject?: Maybe<Subject>;
};

export type SessionActivity = {
  __typename?: "SessionActivity";
  steps?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type SessionAnalysisStatus = {
  __typename?: "SessionAnalysisStatus";
  id?: Maybe<Scalars["ID"]>;
  label?: Maybe<Scalars["String"]>;
};

export type SessionCoughCount = {
  __typename?: "SessionCoughCount";
  daily?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  hourly?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  awake?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type SessionDataPoint = {
  __typename?: "SessionDataPoint";
  id?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  values?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type SessionLog = {
  __typename?: "SessionLog";
  id: Scalars["ID"];
  deviceSessionId: Scalars["ID"];
  changedById: Scalars["ID"];
  category: SessionLogCategoryEnum;
  createdAt: Scalars["DateTime"];
};

export enum SessionLogCategoryEnum {
  Referenced = "Referenced",
  TimeAdjusted = "TimeAdjusted",
  Analyzed = "Analyzed",
  Selected = "Selected",
  Added = "Added",
}

export type SessionReference = {
  __typename?: "SessionReference";
  prefix: Scalars["String"];
  day: Scalars["Int"];
  canDelete?: Maybe<Scalars["Boolean"]>;
  sessionId?: Maybe<Scalars["String"]>;
};

export type SessionReferenceInput = {
  prefix: Scalars["String"];
  day: Scalars["Int"];
  sessionId?: Maybe<Scalars["String"]>;
};

export type SessionRespitoryRate = {
  __typename?: "SessionRespitoryRate";
  median?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  q1?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  q3?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type SessionStatus = {
  __typename?: "SessionStatus";
  key: Scalars["String"];
  label: Scalars["String"];
  order?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
};

export enum SessionStatusEnum {
  Available = "Available",
  AnalysisInitiated = "AnalysisInitiated",
  AnalysisPending = "AnalysisPending",
  UnderReview = "UnderReview",
  Reviewed = "Reviewed",
  Archived = "Archived",
}

export type SessionSummariesResult = {
  __typename?: "SessionSummariesResult";
  results?: Maybe<Array<Maybe<SessionSummary>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type SessionSummary = {
  __typename?: "SessionSummary";
  /** unique DB id */
  id: Scalars["ID"];
  studyDay?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  coughCount?: Maybe<SessionCoughCount>;
  respiratoryRate?: Maybe<SessionRespitoryRate>;
  activity?: Maybe<SessionActivity>;
  dataPoints?: Maybe<Array<Maybe<DeviceSessionDataPoint2>>>;
};

/** A shortened version of the organizaztion the returns significantly less data than a full organization */
export type ShortOrganization = {
  __typename?: "ShortOrganization";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Site = {
  __typename?: "Site";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  contactName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  streetAddress1?: Maybe<Scalars["String"]>;
  streetAddress2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  zipCode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  studies?: Maybe<Array<Maybe<Study>>>;
};

export type SiteInput = {
  name?: Maybe<Scalars["String"]>;
  clientId?: Maybe<Scalars["ID"]>;
  contactName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  streetAddress1?: Maybe<Scalars["String"]>;
  streetAddress2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  zipCode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  studies?: Maybe<Array<Scalars["ID"]>>;
};

export enum SortOrderEnum {
  Asc = "asc",
  Desc = "desc",
}

export enum SortSessionDeviceFieldsEnum {
  SessionStatus = "sessionStatus",
  Client = "client",
  FirstDay = "firstDay",
  LastDay = "lastDay",
  Change = "change",
  AnnotationStatus = "annotationStatus",
  Issues = "issues",
}

export type SortSessionDeviceInput = {
  field?: Maybe<SortSessionDeviceFieldsEnum>;
  order?: Maybe<SortOrderEnum>;
};

export enum SortSubjectSummaryFieldsEnum {
  Status = "status",
  Client = "client",
  FirstDayCoughs = "firstDayCoughs",
  LastDayCoughs = "lastDayCoughs",
  CoughsChange = "coughsChange",
  Issues = "issues",
}

export type SortSubjectSummaryInput = {
  field: SortSubjectSummaryFieldsEnum;
  order: SortOrderEnum;
};

export type StatBlockDataItem = {
  __typename?: "StatBlockDataItem";
  id?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Int"]>;
};

export type Stats_SessionsAnnotations = {
  __typename?: "Stats_SessionsAnnotations";
  pending?: Maybe<Scalars["Int"]>;
  initiated?: Maybe<Scalars["Int"]>;
  under_review?: Maybe<Scalars["Int"]>;
  completed?: Maybe<Scalars["Int"]>;
};

export type Stats_SessionsIssues = {
  __typename?: "Stats_SessionsIssues";
  client_reported?: Maybe<Scalars["Int"]>;
  participant_reported?: Maybe<Scalars["Int"]>;
  device_issue?: Maybe<Scalars["Int"]>;
  other_issue?: Maybe<Scalars["Int"]>;
};

export type Stats_SessionsStatuses = {
  __typename?: "Stats_SessionsStatuses";
  not_yet_provisioned?: Maybe<Scalars["Int"]>;
  provisioned?: Maybe<Scalars["Int"]>;
  in_progress?: Maybe<Scalars["Int"]>;
  session_completed?: Maybe<Scalars["Int"]>;
  ready_for_release?: Maybe<Scalars["Int"]>;
  released?: Maybe<Scalars["Int"]>;
};

export type Study = {
  __typename?: "Study";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  sites?: Maybe<Array<Maybe<Site>>>;
  active?: Maybe<Scalars["Boolean"]>;
  metrics?: Maybe<Array<TrialMetricsSettings>>;
  sessionReferences?: Maybe<Array<SessionReference>>;
  subjectGroups?: Maybe<Array<SubjectGroup>>;
  startDate?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  completedSubjects?: Maybe<Scalars["Int"]>;
  totalSubjects?: Maybe<Scalars["Int"]>;
};

export type StudyInput = {
  name?: Maybe<Scalars["String"]>;
  clientId?: Maybe<Scalars["ID"]>;
  siteIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  active?: Maybe<Scalars["Boolean"]>;
  metrics?: Maybe<Array<TrialMetricsSettingsInput>>;
  startDate?: Maybe<Scalars["Date"]>;
  endDate?: Maybe<Scalars["Date"]>;
};

export type StudyResultList = {
  __typename?: "StudyResultList";
  items: Array<Maybe<Study>>;
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  count?: Maybe<Scalars["Int"]>;
};

export type Subject = {
  __typename?: "Subject";
  id?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  client?: Maybe<Client>;
  name?: Maybe<Scalars["String"]>;
  subjectId?: Maybe<Scalars["String"]>;
  study?: Maybe<Study>;
  site?: Maybe<Site>;
  issues?: Maybe<Array<DeviceSessionIssue>>;
  isCompleted?: Maybe<Scalars["Boolean"]>;
  selectedReport?: Maybe<Scalars["ID"]>;
};

export type SubjectGroup = {
  __typename?: "SubjectGroup";
  prefix?: Maybe<Scalars["String"]>;
  digits?: Maybe<Scalars["Int"]>;
  count?: Maybe<Scalars["Int"]>;
  start?: Maybe<Scalars["Int"]>;
  site?: Maybe<Site>;
  createdAt?: Maybe<Scalars["DateTime"]>;
};

export type SubjectGroupInput = {
  prefix: Scalars["String"];
  digits: Scalars["Int"];
  count: Scalars["Int"];
  start: Scalars["Int"];
  site: Scalars["ID"];
  createdAt?: Maybe<Scalars["DateTime"]>;
};

export type SubjectIssuesTotal = {
  __typename?: "SubjectIssuesTotal";
  issueType: Scalars["String"];
  total: Scalars["Int"];
};

export type SubjectStatusTotal = {
  __typename?: "SubjectStatusTotal";
  key: Scalars["String"];
  label: Scalars["String"];
  total: Scalars["Int"];
};

export type SubjectSummariesStats = {
  __typename?: "SubjectSummariesStats";
  total?: Maybe<Scalars["Int"]>;
  withIssue?: Maybe<Scalars["Int"]>;
  noIssue?: Maybe<Scalars["Int"]>;
  dailyCoughs?: Maybe<CoughStats>;
  awakeCoughs?: Maybe<CoughStats>;
};

export type SubjectSummary = {
  __typename?: "SubjectSummary";
  id?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  client?: Maybe<Client>;
  name?: Maybe<Scalars["String"]>;
  subjectId?: Maybe<Scalars["String"]>;
  study?: Maybe<Study>;
  site?: Maybe<Site>;
  status?: Maybe<SessionStatus>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  issues?: Maybe<Array<DeviceSessionIssue>>;
  dailyReport?: Maybe<DailySubjectReport>;
  isReportBeingGenerated?: Maybe<Scalars["Boolean"]>;
};

export type SubjectSummaryResultList = {
  __typename?: "SubjectSummaryResultList";
  items: Array<Maybe<SubjectSummary>>;
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  count?: Maybe<Scalars["Int"]>;
};

export type SyncSessionInput = {
  uploadId: Scalars["String"];
  selected?: Maybe<Scalars["Boolean"]>;
};

export type Tokens = {
  __typename?: "Tokens";
  refreshToken?: Maybe<Scalars["String"]>;
  accessToken?: Maybe<Scalars["String"]>;
};

/** Deprecated */
export type Trial = {
  __typename?: "Trial";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  sites?: Maybe<Array<Maybe<Site>>>;
  active?: Maybe<Scalars["Boolean"]>;
  releases?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** If true, this trial has sessions that are 'ready for release'. If false, there is nothign to release */
  numberOfSessionReadyForRelease?: Maybe<Scalars["Int"]>;
  metrics?: Maybe<Array<TrialMetricsSettings>>;
};

export type TrialMetricsSettings = {
  __typename?: "TrialMetricsSettings";
  key: OtherDataUnitEnum;
  label?: Maybe<Scalars["String"]>;
  value: Scalars["Boolean"];
};

export type TrialMetricsSettingsInput = {
  key: OtherDataUnitEnum;
  label: Scalars["String"];
  value: Scalars["Boolean"];
};

export type TwoFactorSecretKey = {
  __typename?: "TwoFactorSecretKey";
  ascii?: Maybe<Scalars["String"]>;
  base32?: Maybe<Scalars["String"]>;
  hex?: Maybe<Scalars["String"]>;
  qr_code_ascii?: Maybe<Scalars["String"]>;
  qr_code_hex?: Maybe<Scalars["String"]>;
  qr_code_base32?: Maybe<Scalars["String"]>;
  google_auth_qr?: Maybe<Scalars["String"]>;
  otpauth_url?: Maybe<Scalars["String"]>;
};

export type TwoFactorSecretKeyInput = {
  ascii?: Maybe<Scalars["String"]>;
  base32?: Maybe<Scalars["String"]>;
  hex?: Maybe<Scalars["String"]>;
  qr_code_ascii?: Maybe<Scalars["String"]>;
  qr_code_hex?: Maybe<Scalars["String"]>;
  qr_code_base32?: Maybe<Scalars["String"]>;
  google_auth_qr?: Maybe<Scalars["String"]>;
  otpauth_url?: Maybe<Scalars["String"]>;
};

export type UpdateSessionAnalysisStatus = {
  uploadId: Scalars["String"];
  totalTasks?: Maybe<Scalars["Int"]>;
  completedTasks?: Maybe<Scalars["Int"]>;
};

export type UpdateSessionInput = {
  id: Scalars["ID"];
  reference?: Maybe<SessionReferenceInput>;
  startDate?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  selected?: Maybe<Scalars["Boolean"]>;
  statusKey?: Maybe<SessionStatusEnum>;
};

export type UpdateSiteInput = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  clientId?: Maybe<Scalars["ID"]>;
  contactName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  streetAddress1?: Maybe<Scalars["String"]>;
  streetAddress2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  zipCode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  studies?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateStudyInput = {
  id: Scalars["ID"];
  active?: Maybe<Scalars["Boolean"]>;
  metrics?: Maybe<Array<TrialMetricsSettingsInput>>;
  sessionReferences?: Maybe<Array<SessionReferenceInput>>;
  subjectGroups?: Maybe<Array<SubjectGroupInput>>;
  startDate?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
};

export type UpdateSubjectInput = {
  id: Scalars["ID"];
  isCompleted?: Maybe<Scalars["Boolean"]>;
};

export type UpdateUserInput = {
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["ID"]>;
  permission?: Maybe<Scalars["ID"]>;
  customPermissions?: Maybe<Array<Maybe<AccessNameEnum>>>;
  organizationIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  trialIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  deactivated?: Maybe<Scalars["Boolean"]>;
};

export type User = {
  __typename?: "User";
  id: Scalars["ID"];
  emails?: Maybe<Array<EmailRecord>>;
  username?: Maybe<Scalars["String"]>;
};

export type UserInput = {
  id?: Maybe<Scalars["ID"]>;
  email?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** The main User object used for authentication etc */
export type UserProfile = {
  __typename?: "UserProfile";
  id?: Maybe<Scalars["String"]>;
  userType?: Maybe<UserTypeEnum>;
  email?: Maybe<Scalars["String"]>;
  permission?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  acceptedInvite?: Maybe<Scalars["Boolean"]>;
  recentlySentInvite?: Maybe<Scalars["String"]>;
  deactivated?: Maybe<Scalars["Boolean"]>;
  organizationId?: Maybe<Scalars["String"]>;
  organizationName?: Maybe<Scalars["String"]>;
  organizations?: Maybe<Array<Maybe<ShortOrganization>>>;
  activeTrial?: Maybe<Trial>;
  activeSite?: Maybe<Site>;
  trials?: Maybe<Array<Maybe<Trial>>>;
};

export enum UserTypeEnum {
  Client = "client",
  SuperAdmin = "superAdmin",
}

export type XptResponse = {
  __typename?: "XPTResponse";
  /** A signed s3 url */
  url?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
};

export type History = {
  __typename?: "history";
  user?: Maybe<HistoryUser>;
  createdAt?: Maybe<Scalars["Date"]>;
  changes?: Maybe<Array<Maybe<HistoryChange>>>;
};

export type HistoryChange = {
  __typename?: "historyChange";
  field?: Maybe<Scalars["String"]>;
  oldValue?: Maybe<Scalars["String"]>;
  newValue?: Maybe<Scalars["String"]>;
};

export type HistoryUser = {
  __typename?: "historyUser";
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type CurrentUserFragmentFragment = { __typename: "UserProfile" } & Pick<
  UserProfile,
  | "id"
  | "userType"
  | "email"
  | "firstName"
  | "lastName"
  | "organizationId"
  | "organizationName"
> & {
    trials?: Maybe<
      Array<Maybe<{ __typename?: "Trial" } & Pick<Trial, "id" | "name">>>
    >;
    activeSite?: Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "name">>;
    activeTrial?: Maybe<
      { __typename?: "Trial" } & Pick<Trial, "id" | "name"> & {
          sites?: Maybe<
            Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "name">>>
          >;
        }
    >;
  };

export type OrganizationFragmentFragment = {
  __typename?: "Organization";
} & Pick<Organization, "id" | "name" | "dataUpdatedAt">;

export type SubjectFragmentFragment = { __typename: "OldSubject" } & Pick<
  OldSubject,
  "id" | "name"
> & {
    trial?: Maybe<{ __typename?: "Trial" } & Pick<Trial, "id" | "name">>;
    site?: Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "name">>;
    organization?: Maybe<
      { __typename?: "Organization" } & Pick<Organization, "id" | "name">
    >;
  };

export type TrialFragmentFragment = { __typename: "Trial" } & Pick<
  Trial,
  "id" | "name" | "active" | "releases" | "numberOfSessionReadyForRelease"
> & {
    sites?: Maybe<
      Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "name">>>
    >;
    metrics?: Maybe<
      Array<
        { __typename?: "TrialMetricsSettings" } & Pick<
          TrialMetricsSettings,
          "key" | "label" | "value"
        >
      >
    >;
  };

export type AuthenticateMutationVariables = Exact<{
  params: AuthenticateParamsInput;
}>;

export type AuthenticateMutation = { __typename?: "Mutation" } & {
  authenticate?: Maybe<
    { __typename?: "LoginResult" } & Pick<LoginResult, "sessionId"> & {
        tokens?: Maybe<
          { __typename?: "Tokens" } & Pick<
            Tokens,
            "refreshToken" | "accessToken"
          >
        >;
      }
  >;
};

export type CreateManySubjectsMutationVariables = Exact<{
  numberToCreate?: Maybe<Scalars["Int"]>;
  organizationId: Scalars["ID"];
  trialId?: Maybe<Scalars["ID"]>;
  siteId: Scalars["ID"];
}>;

export type CreateManySubjectsMutation = { __typename?: "Mutation" } & {
  createManySubjects?: Maybe<
    { __typename?: "MutationResponse" } & Pick<
      MutationResponse,
      "success" | "errors"
    >
  >;
};

export type CreateNewUserMutationVariables = Exact<{
  params: CreateNewUserInput;
}>;

export type CreateNewUserMutation = { __typename?: "Mutation" } & {
  createNewUser?: Maybe<
    { __typename?: "UserProfile" } & Pick<
      UserProfile,
      "id" | "email" | "firstName" | "lastName"
    >
  >;
};

export type CreateOneBatchMutationVariables = Exact<{
  sessionCount: Scalars["Int"];
  organizationId?: Maybe<Scalars["ID"]>;
  trialId?: Maybe<Scalars["ID"]>;
  siteId?: Maybe<Scalars["ID"]>;
}>;

export type CreateOneBatchMutation = { __typename?: "Mutation" } & {
  createOneBatch?: Maybe<
    { __typename?: "MutationResponse" } & Pick<
      MutationResponse,
      "success" | "errors"
    >
  >;
};

export type CreateOneDeviceMutationVariables = Exact<{
  deviceId: Scalars["String"];
}>;

export type CreateOneDeviceMutation = { __typename?: "Mutation" } & {
  createOneDevice?: Maybe<
    { __typename?: "Device" } & Pick<Device, "id" | "deviceId">
  >;
};

export type CreateOneIssueMutationVariables = Exact<{
  deviceSessionId: Scalars["ID"];
  description: Scalars["String"];
  issueType?: Maybe<DeviceSessionIssueTypeEnum>;
}>;

export type CreateOneIssueMutation = { __typename?: "Mutation" } & {
  createOneIssue?: Maybe<
    { __typename?: "DeviceSession" } & Pick<DeviceSession, "id"> & {
        issues?: Maybe<
          Array<
            { __typename?: "DeviceSessionIssue" } & Pick<
              DeviceSessionIssue,
              "id" | "issueType" | "description" | "createdAt"
            >
          >
        >;
      }
  >;
};

export type CreateOrganizationMutationVariables = Exact<{
  params: OrganizationInput;
}>;

export type CreateOrganizationMutation = { __typename?: "Mutation" } & {
  createOrganization?: Maybe<
    { __typename?: "Organization" } & Pick<Organization, "id" | "name">
  >;
};

export type CreateSessionIssueMutationVariables = Exact<{
  input: CreateSessionIssueInput;
}>;

export type CreateSessionIssueMutation = { __typename?: "Mutation" } & {
  createSessionIssue?: Maybe<
    { __typename?: "Session" } & Pick<Session, "id" | "index">
  >;
};

export type CreateSessionProjectMutationVariables = Exact<{
  input: CreateSessionProjectInput;
}>;

export type CreateSessionProjectMutation = { __typename?: "Mutation" } & {
  createSessionProject?: Maybe<
    { __typename?: "MutationResponse" } & Pick<
      MutationResponse,
      "success" | "errors"
    >
  >;
};

export type CreateSiteMutationVariables = Exact<{
  input: SiteInput;
}>;

export type CreateSiteMutation = { __typename?: "Mutation" } & {
  createSite?: Maybe<
    { __typename?: "Site" } & Pick<
      Site,
      | "id"
      | "name"
      | "contactName"
      | "phone"
      | "email"
      | "streetAddress1"
      | "streetAddress2"
      | "city"
      | "state"
      | "zipCode"
      | "country"
    > & {
        studies?: Maybe<
          Array<
            Maybe<
              { __typename?: "Study" } & Pick<Study, "id" | "name" | "active">
            >
          >
        >;
      }
  >;
};

export type UpdateSiteMutationVariables = Exact<{
  input: UpdateSiteInput;
}>;

export type UpdateSiteMutation = { __typename?: "Mutation" } & {
  updateSite?: Maybe<
    { __typename?: "Site" } & Pick<
      Site,
      | "id"
      | "name"
      | "contactName"
      | "phone"
      | "email"
      | "streetAddress1"
      | "streetAddress2"
      | "city"
      | "state"
      | "zipCode"
      | "country"
    > & {
        studies?: Maybe<
          Array<Maybe<{ __typename?: "Study" } & Pick<Study, "id" | "name">>>
        >;
      }
  >;
};

export type CreateStudyMutationVariables = Exact<{
  input: StudyInput;
}>;

export type CreateStudyMutation = { __typename?: "Mutation" } & {
  createStudy?: Maybe<{ __typename?: "Study" } & Pick<Study, "id" | "name">>;
};

export type CreateSubjectIssueMutationVariables = Exact<{
  input: CreateSubjectIssueInput;
}>;

export type CreateSubjectIssueMutation = { __typename?: "Mutation" } & {
  createSubjectIssue?: Maybe<
    { __typename?: "Subject" } & Pick<Subject, "id" | "createdAt" | "subjectId">
  >;
};

export type CreateSuperAdminUserMutationVariables = Exact<{
  params: CreateNewUserInput;
}>;

export type CreateSuperAdminUserMutation = { __typename?: "Mutation" } & {
  createSuperAdminUser?: Maybe<
    { __typename?: "UserProfile" } & Pick<UserProfile, "id" | "email">
  >;
};

export type DeleteOneBatchMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteOneBatchMutation = { __typename?: "Mutation" } & {
  deleteOneBatch?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteOneSuperAdminUserMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteOneSuperAdminUserMutation = { __typename?: "Mutation" } & {
  deleteOneSuperAdminUser?: Maybe<
    { __typename?: "MutationResponse" } & Pick<
      MutationResponse,
      "success" | "errors"
    >
  >;
};

export type DownloadDeviceEventsXptMutationVariables = Exact<{
  query?: Maybe<DeviceSessionQueryParams>;
}>;

export type DownloadDeviceEventsXptMutation = { __typename?: "Mutation" } & {
  downloadDeviceEventsXpt?: Maybe<
    { __typename?: "XPTResponse" } & Pick<XptResponse, "url" | "filename">
  >;
};

export type GenerateReportMutationVariables = Exact<{
  subjectId?: Maybe<Scalars["String"]>;
}>;

export type GenerateReportMutation = { __typename?: "Mutation" } & {
  generateReport?: Maybe<
    { __typename?: "DailySubjectReport" } & Pick<
      DailySubjectReport,
      "subject" | "createdAt" | "id"
    > & {
        data?: Maybe<
          Array<
            Maybe<
              { __typename?: "DailySubjectReportData" } & Pick<
                DailySubjectReportData,
                | "day"
                | "coughs"
                | "avgHrCoughs"
                | "medRespRate"
                | "q1RespRate"
                | "q3RespRate"
                | "steps"
              >
            >
          >
        >;
      }
  >;
};

export type ImpersonateOrganizationMutationVariables = Exact<{
  organizationId: Scalars["ID"];
}>;

export type ImpersonateOrganizationMutation = { __typename?: "Mutation" } & {
  impersonateOrganization?: Maybe<
    { __typename?: "UserProfile" } & Pick<UserProfile, "id">
  >;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "logout"
>;

export type ReleaseSessionsMutationVariables = Exact<{
  trialId: Scalars["ID"];
}>;

export type ReleaseSessionsMutation = { __typename?: "Mutation" } & {
  releaseSessions?: Maybe<
    { __typename?: "Trial" } & Pick<
      Trial,
      "id" | "releases" | "numberOfSessionReadyForRelease"
    >
  >;
};

export type ResendInviteEmailMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type ResendInviteEmailMutation = { __typename?: "Mutation" } & {
  resendInviteEmail?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars["String"];
  token: Scalars["String"];
}>;

export type ResetPasswordMutation = { __typename?: "Mutation" } & {
  resetPassword?: Maybe<
    { __typename?: "LoginResult" } & Pick<LoginResult, "sessionId"> & {
        tokens?: Maybe<
          { __typename?: "Tokens" } & Pick<
            Tokens,
            "refreshToken" | "accessToken"
          >
        >;
      }
  >;
};

export type SelectDailySubjectReportMutationVariables = Exact<{
  input: SelectDailySubjectReportInput;
}>;

export type SelectDailySubjectReportMutation = { __typename?: "Mutation" } & {
  selectDailySubjectReport?: Maybe<
    { __typename?: "DailySubjectReport" } & Pick<
      DailySubjectReport,
      "subject" | "createdAt" | "id"
    >
  >;
};

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type SendResetPasswordEmailMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "sendResetPasswordEmail"
>;

export type UpdateActiveSiteIdMutationVariables = Exact<{
  siteId: Scalars["ID"];
}>;

export type UpdateActiveSiteIdMutation = { __typename?: "Mutation" } & {
  updateActiveSiteId?: Maybe<
    { __typename?: "UserProfile" } & CurrentUserFragmentFragment
  >;
};

export type UpdateActiveTrialIdMutationVariables = Exact<{
  trialId: Scalars["ID"];
}>;

export type UpdateActiveTrialIdMutation = { __typename?: "Mutation" } & {
  updateActiveTrialId?: Maybe<
    { __typename?: "UserProfile" } & CurrentUserFragmentFragment
  >;
};

export type UpdateOneDeviceSessionMutationVariables = Exact<{
  id: Scalars["ID"];
  params?: Maybe<DeviceSessionInput>;
}>;

export type UpdateOneDeviceSessionMutation = { __typename?: "Mutation" } & {
  updateOneDeviceSession?: Maybe<
    { __typename?: "DeviceSession" } & Pick<
      DeviceSession,
      "id" | "status" | "deviceId" | "subjectName"
    > & {
        organization?: Maybe<
          { __typename?: "Organization" } & Pick<Organization, "id" | "name">
        >;
        trial?: Maybe<{ __typename?: "Trial" } & Pick<Trial, "id" | "name">>;
        site?: Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "name">>;
      }
  >;
};

export type UpdateOneTrialMutationVariables = Exact<{
  id: Scalars["ID"];
  params?: Maybe<StudyInput>;
}>;

export type UpdateOneTrialMutation = { __typename?: "Mutation" } & {
  updateOneTrial?: Maybe<{ __typename?: "Trial" } & TrialFragmentFragment>;
};

export type UpdateOneUserMutationVariables = Exact<{
  id: Scalars["ID"];
  params?: Maybe<UpdateUserInput>;
}>;

export type UpdateOneUserMutation = { __typename?: "Mutation" } & {
  updateOneUser?: Maybe<
    { __typename?: "UserProfile" } & Pick<
      UserProfile,
      "id" | "firstName" | "lastName" | "email" | "deactivated"
    >
  >;
};

export type UpdateSessionMutationVariables = Exact<{
  input: UpdateSessionInput;
}>;

export type UpdateSessionMutation = { __typename?: "Mutation" } & {
  updateSession?: Maybe<
    { __typename?: "Session" } & Pick<Session, "selected" | "id">
  >;
};

export type UpdateSessionReviewedMutationVariables = Exact<{
  input: UpdateSessionInput;
}>;

export type UpdateSessionReviewedMutation = { __typename?: "Mutation" } & {
  updateSession?: Maybe<
    { __typename?: "Session" } & Pick<Session, "id"> & {
        status?: Maybe<
          { __typename?: "SessionStatus" } & Pick<SessionStatus, "key">
        >;
      }
  >;
};

export type UpdateSessionReferenceMutationVariables = Exact<{
  input: UpdateSessionInput;
}>;

export type UpdateSessionReferenceMutation = { __typename?: "Mutation" } & {
  updateSession?: Maybe<{ __typename?: "Session" } & Pick<Session, "id">>;
};

export type ResolveSessionIssueMutationVariables = Exact<{
  input: ResolveSessionIssueInput;
}>;

export type ResolveSessionIssueMutation = { __typename?: "Mutation" } & {
  resolveSessionIssue?: Maybe<
    { __typename?: "DeviceSessionIssue" } & Pick<DeviceSessionIssue, "id">
  >;
};

export type UpdateStudyMutationVariables = Exact<{
  input: UpdateStudyInput;
}>;

export type UpdateStudyMutation = { __typename?: "Mutation" } & {
  updateStudy?: Maybe<
    { __typename?: "Study" } & Pick<
      Study,
      | "id"
      | "name"
      | "active"
      | "endDate"
      | "completedSubjects"
      | "totalSubjects"
    > & {
        sites?: Maybe<
          Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "name">>>
        >;
        metrics?: Maybe<
          Array<
            { __typename?: "TrialMetricsSettings" } & Pick<
              TrialMetricsSettings,
              "key" | "label" | "value"
            >
          >
        >;
        sessionReferences?: Maybe<
          Array<
            { __typename?: "SessionReference" } & Pick<
              SessionReference,
              "prefix" | "day" | "sessionId"
            >
          >
        >;
        subjectGroups?: Maybe<
          Array<
            { __typename?: "SubjectGroup" } & Pick<
              SubjectGroup,
              "prefix" | "digits" | "count" | "start" | "createdAt"
            > & { site?: Maybe<{ __typename?: "Site" } & Pick<Site, "id">> }
          >
        >;
      }
  >;
};

export type UpdateSubjectMutationVariables = Exact<{
  input: UpdateSubjectInput;
}>;

export type UpdateSubjectMutation = { __typename?: "Mutation" } & {
  updateSubject?: Maybe<
    { __typename?: "Subject" } & Pick<Subject, "id" | "subjectId"> & {
        client?: Maybe<{ __typename?: "Client" } & Pick<Client, "name" | "id">>;
      }
  >;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = { __typename?: "Query" } & {
  currentUser?: Maybe<
    { __typename?: "UserProfile" } & CurrentUserFragmentFragment
  >;
};

export type FindClientsQueryVariables = Exact<{
  pagination?: Maybe<PaginationOpts>;
}>;

export type FindClientsQuery = { __typename?: "Query" } & {
  findClients: { __typename?: "ClientResultList" } & Pick<
    ClientResultList,
    "offset" | "limit" | "count"
  > & {
      items: Array<
        Maybe<
          { __typename?: "Client" } & Pick<Client, "id" | "name"> & {
              studies?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "Study" } & Pick<
                      Study,
                      "id" | "name" | "active"
                    >
                  >
                >
              >;
            }
        >
      >;
    };
};

export type FindStudiesQueryVariables = Exact<{
  clientId?: Maybe<Scalars["ID"]>;
  pagination?: Maybe<PaginationOpts>;
}>;

export type FindStudiesQuery = { __typename?: "Query" } & {
  findStudies: { __typename?: "StudyResultList" } & Pick<
    StudyResultList,
    "offset" | "limit" | "count"
  > & {
      items: Array<
        Maybe<
          { __typename?: "Study" } & Pick<
            Study,
            | "id"
            | "name"
            | "startDate"
            | "endDate"
            | "active"
            | "completedSubjects"
            | "totalSubjects"
          > & {
              sites?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "Site" } & Pick<
                      Site,
                      | "id"
                      | "name"
                      | "city"
                      | "country"
                      | "contactName"
                      | "phone"
                      | "state"
                      | "streetAddress1"
                      | "streetAddress2"
                      | "zipCode"
                    >
                  >
                >
              >;
              metrics?: Maybe<
                Array<
                  { __typename?: "TrialMetricsSettings" } & Pick<
                    TrialMetricsSettings,
                    "key" | "label" | "value"
                  >
                >
              >;
              sessionReferences?: Maybe<
                Array<
                  { __typename?: "SessionReference" } & Pick<
                    SessionReference,
                    "prefix" | "day" | "sessionId"
                  >
                >
              >;
              subjectGroups?: Maybe<
                Array<
                  { __typename?: "SubjectGroup" } & Pick<
                    SubjectGroup,
                    "prefix" | "digits" | "count" | "start" | "createdAt"
                  > & {
                      site?: Maybe<
                        { __typename?: "Site" } & Pick<Site, "id" | "name">
                      >;
                    }
                >
              >;
            }
        >
      >;
    };
};

export type FindSubjectIssuesTotalsQueryVariables = Exact<{
  clientIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  siteIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  studyIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  statuses?: Maybe<Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>>;
  uploadIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  deviceIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  subjectIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  sessionIds?: Maybe<Array<Maybe<Scalars["Int"]>> | Maybe<Scalars["Int"]>>;
  sessionStartDate?: Maybe<Scalars["DateTime"]>;
  sessionEndDate?: Maybe<Scalars["DateTime"]>;
}>;

export type FindSubjectIssuesTotalsQuery = { __typename?: "Query" } & {
  findSubjectIssuesTotals?: Maybe<
    Array<
      Maybe<
        { __typename?: "SubjectIssuesTotal" } & Pick<
          SubjectIssuesTotal,
          "issueType" | "total"
        >
      >
    >
  >;
};

export type FindSubjectStatusTotalsQueryVariables = Exact<{
  clientIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  siteIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  studyIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  uploadIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  deviceIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  subjectIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  sessionIds?: Maybe<Array<Maybe<Scalars["Int"]>> | Maybe<Scalars["Int"]>>;
  sessionStartDate?: Maybe<Scalars["DateTime"]>;
  sessionEndDate?: Maybe<Scalars["DateTime"]>;
}>;

export type FindSubjectStatusTotalsQuery = { __typename?: "Query" } & {
  findSubjectStatusTotals?: Maybe<
    Array<
      Maybe<
        { __typename?: "SubjectStatusTotal" } & Pick<
          SubjectStatusTotal,
          "key" | "label" | "total"
        >
      >
    >
  >;
};

export type FindSubjectSummariesQueryVariables = Exact<{
  subjectId?: Maybe<Scalars["String"]>;
  clientIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  siteIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  studyIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  deviceIds?: Maybe<Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>>;
  uploadIds?: Maybe<Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>>;
  sessionIds?: Maybe<Array<Maybe<Scalars["Int"]>> | Maybe<Scalars["Int"]>>;
  sessionStartDate?: Maybe<Scalars["DateTime"]>;
  sessionEndDate?: Maybe<Scalars["DateTime"]>;
  issueTypes?: Maybe<
    Array<Maybe<DeviceSessionIssueTypeEnum>> | Maybe<DeviceSessionIssueTypeEnum>
  >;
  statuses?: Maybe<Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>>;
  active?: Maybe<Scalars["Boolean"]>;
  pagination?: Maybe<PaginationOpts>;
  sort?: Maybe<SortSubjectSummaryInput>;
}>;

export type FindSubjectSummariesQuery = { __typename?: "Query" } & {
  findSubjectSummaries?: Maybe<
    { __typename?: "SubjectSummaryResultList" } & Pick<
      SubjectSummaryResultList,
      "offset" | "limit" | "count"
    > & {
        items: Array<
          Maybe<
            { __typename?: "SubjectSummary" } & Pick<
              SubjectSummary,
              "id" | "subjectId"
            > & {
                issues?: Maybe<
                  Array<
                    { __typename?: "DeviceSessionIssue" } & Pick<
                      DeviceSessionIssue,
                      | "createdAt"
                      | "description"
                      | "deviceSessionId"
                      | "id"
                      | "issueType"
                      | "resolved"
                    >
                  >
                >;
                status?: Maybe<
                  { __typename?: "SessionStatus" } & Pick<SessionStatus, "key">
                >;
                sessions?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: "Session" } & Pick<
                        Session,
                        | "id"
                        | "index"
                        | "deviceId"
                        | "siteId"
                        | "startDate"
                        | "endDate"
                        | "deviceStartDate"
                        | "deviceEndDate"
                        | "selected"
                        | "uploadId"
                        | "completedTasks"
                        | "totalTasks"
                        | "hardwareVersion"
                        | "firmwareVersion"
                        | "deviceStorage"
                      > & {
                          issues?: Maybe<
                            Array<
                              { __typename?: "DeviceSessionIssue" } & Pick<
                                DeviceSessionIssue,
                                | "id"
                                | "createdAt"
                                | "description"
                                | "deviceSessionId"
                                | "issueType"
                              >
                            >
                          >;
                          status?: Maybe<
                            { __typename?: "SessionStatus" } & Pick<
                              SessionStatus,
                              "key"
                            >
                          >;
                          reference?: Maybe<
                            { __typename?: "SessionReference" } & Pick<
                              SessionReference,
                              "prefix" | "day"
                            >
                          >;
                        }
                    >
                  >
                >;
                client?: Maybe<
                  { __typename?: "Client" } & Pick<Client, "name">
                >;
                dailyReport?: Maybe<
                  { __typename?: "DailySubjectReport" } & Pick<
                    DailySubjectReport,
                    | "id"
                    | "subject"
                    | "firstDayCoughs"
                    | "lastDayCoughs"
                    | "coughsChange"
                    | "createdAt"
                  > & {
                      data?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "DailySubjectReportData" } & Pick<
                              DailySubjectReportData,
                              | "avgHrCoughs"
                              | "awakeCoughs"
                              | "coughs"
                              | "day"
                              | "medRespRate"
                              | "q1RespRate"
                              | "q3RespRate"
                              | "steps"
                            >
                          >
                        >
                      >;
                    }
                >;
                study?: Maybe<
                  { __typename?: "Study" } & Pick<Study, "id" | "name"> & {
                      metrics?: Maybe<
                        Array<
                          { __typename?: "TrialMetricsSettings" } & Pick<
                            TrialMetricsSettings,
                            "key" | "value" | "label"
                          >
                        >
                      >;
                      sessionReferences?: Maybe<
                        Array<
                          { __typename?: "SessionReference" } & Pick<
                            SessionReference,
                            "prefix" | "day" | "sessionId"
                          >
                        >
                      >;
                    }
                >;
                site?: Maybe<{ __typename?: "Site" } & Pick<Site, "name">>;
              }
          >
        >;
      }
  >;
};

export type FindSubjectSummariesStatsQueryVariables = Exact<{
  siteIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  studyIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  clientIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  statuses?: Maybe<Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>>;
}>;

export type FindSubjectSummariesStatsQuery = { __typename?: "Query" } & {
  findSubjectSummariesStats?: Maybe<
    { __typename?: "SubjectSummariesStats" } & Pick<
      SubjectSummariesStats,
      "withIssue" | "noIssue" | "total"
    > & {
        dailyCoughs?: Maybe<
          { __typename?: "CoughStats" } & Pick<
            CoughStats,
            "avgFirstDay" | "avgLastDay" | "avgChange"
          >
        >;
        awakeCoughs?: Maybe<
          { __typename?: "CoughStats" } & Pick<
            CoughStats,
            "avgFirstDay" | "avgLastDay" | "avgChange"
          >
        >;
      }
  >;
};

export type GetLastSubjectCompletedAtQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLastSubjectCompletedAtQuery = { __typename?: "Query" } & Pick<
  Query,
  "getLastSubjectCompletedAt"
>;

export type GetManyBatchesQueryVariables = Exact<{
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type GetManyBatchesQuery = { __typename?: "Query" } & {
  getManyBatches?: Maybe<
    { __typename?: "BatchResults" } & Pick<BatchResults, "count"> & {
        results?: Maybe<
          Array<
            { __typename?: "Batch" } & Pick<
              Batch,
              "id" | "sessionCount" | "number" | "createdAt"
            >
          >
        >;
      }
  >;
};

export type GetManyDailySubjectReportsQueryVariables = Exact<{
  subjectId: Scalars["ID"];
}>;

export type GetManyDailySubjectReportsQuery = { __typename?: "Query" } & {
  getManyDailySubjectReports?: Maybe<
    Array<
      Maybe<
        { __typename?: "DailySubjectReport" } & Pick<
          DailySubjectReport,
          "id" | "subject" | "createdAt"
        > & {
            data?: Maybe<
              Array<
                Maybe<
                  { __typename?: "DailySubjectReportData" } & Pick<
                    DailySubjectReportData,
                    | "day"
                    | "coughs"
                    | "avgHrCoughs"
                    | "awakeCoughs"
                    | "medRespRate"
                    | "q3RespRate"
                    | "steps"
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetManyDeviceEventsQueryVariables = Exact<{
  organizationIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  trialIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  siteIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  active?: Maybe<Scalars["Boolean"]>;
  sessionStatuses?: Maybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  annotationStatuses?: Maybe<
    Array<DeviceAnnotationStatusEnum> | DeviceAnnotationStatusEnum
  >;
  sessionId?: Maybe<Scalars["String"]>;
  deviceId?: Maybe<Scalars["String"]>;
}>;

export type GetManyDeviceEventsQuery = { __typename?: "Query" } & {
  getManyDeviceEvents?: Maybe<
    { __typename?: "DeviceEventResults" } & Pick<
      DeviceEventResults,
      "count"
    > & {
        results?: Maybe<
          Array<
            { __typename?: "DeviceEvent" } & Pick<
              DeviceEvent,
              "id" | "sessionId" | "timestamp"
            >
          >
        >;
      }
  >;
};

export type GetManyDeviceSessionsQueryVariables = Exact<{
  organizationIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  trialIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  siteIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  batchIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  sessionStatuses?: Maybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  annotationStatuses?: Maybe<
    Array<DeviceAnnotationStatusEnum> | DeviceAnnotationStatusEnum
  >;
  issueStatuses?: Maybe<
    Array<DeviceSessionIssueTypeEnum> | DeviceSessionIssueTypeEnum
  >;
  sessionId?: Maybe<Scalars["String"]>;
  deviceId?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  pagination?: Maybe<PaginationOpts>;
  sort?: Maybe<
    Array<Maybe<SortSessionDeviceInput>> | Maybe<SortSessionDeviceInput>
  >;
}>;

export type GetManyDeviceSessionsQuery = { __typename?: "Query" } & {
  getManyDeviceSessions?: Maybe<
    { __typename?: "DeviceSessionsResult" } & Pick<
      DeviceSessionsResult,
      "count"
    > & {
        results?: Maybe<
          Array<
            { __typename?: "DeviceSession" } & Pick<
              DeviceSession,
              | "id"
              | "sessionId"
              | "deviceId"
              | "subjectName"
              | "siteId"
              | "trialId"
              | "status"
              | "firstDay"
              | "lastDay"
              | "change"
              | "startDate"
            > & {
                organization?: Maybe<
                  { __typename?: "Organization" } & Pick<
                    Organization,
                    "id" | "name"
                  >
                >;
                trial?: Maybe<
                  { __typename?: "Trial" } & Pick<Trial, "id" | "name"> & {
                      metrics?: Maybe<
                        Array<
                          { __typename?: "TrialMetricsSettings" } & Pick<
                            TrialMetricsSettings,
                            "key" | "label" | "value"
                          >
                        >
                      >;
                    }
                >;
                site?: Maybe<
                  { __typename?: "Site" } & Pick<Site, "id" | "name">
                >;
                analysisStatus?: Maybe<
                  { __typename?: "SessionAnalysisStatus" } & Pick<
                    SessionAnalysisStatus,
                    "id" | "label"
                  >
                >;
                sessionSummaries?: Maybe<
                  Array<
                    { __typename?: "SessionSummary" } & Pick<
                      SessionSummary,
                      "studyDay"
                    > & {
                        dataPoints?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: "DeviceSessionDataPoint2" } & Pick<
                                DeviceSessionDataPoint2,
                                "id" | "label"
                              > & {
                                  data?: Maybe<
                                    Array<
                                      Maybe<
                                        {
                                          __typename?: "SessionDataPoint";
                                        } & Pick<
                                          SessionDataPoint,
                                          "id" | "label" | "values"
                                        >
                                      >
                                    >
                                  >;
                                }
                            >
                          >
                        >;
                      }
                  >
                >;
                issues?: Maybe<
                  Array<
                    { __typename?: "DeviceSessionIssue" } & Pick<
                      DeviceSessionIssue,
                      "id" | "issueType" | "description" | "createdAt"
                    >
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type GetManyDevicesQueryVariables = Exact<{
  searchText?: Maybe<Scalars["String"]>;
  deviceStatus?: Maybe<DeviceStatusEnum>;
}>;

export type GetManyDevicesQuery = { __typename?: "Query" } & {
  getManyDevices?: Maybe<
    { __typename?: "DeviceResults" } & Pick<DeviceResults, "count"> & {
        results?: Maybe<
          Array<{ __typename?: "Device" } & Pick<Device, "id" | "deviceId">>
        >;
      }
  >;
};

export type GetManyOrganizationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManyOrganizationsQuery = { __typename?: "Query" } & {
  getManyOrganizations?: Maybe<
    Array<{ __typename?: "Organization" } & Pick<Organization, "id" | "name">>
  >;
};

export type GetManySessionStatusesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManySessionStatusesQuery = { __typename?: "Query" } & {
  getManySessionStatuses?: Maybe<
    Array<
      { __typename?: "SessionStatus" } & Pick<
        SessionStatus,
        "id" | "label" | "key"
      >
    >
  >;
};

export type GetManySessionSummariesQueryVariables = Exact<{
  clientIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  siteIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  studyIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  batchIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  issueStatuses?: Maybe<
    Array<DeviceSessionIssueTypeEnum> | DeviceSessionIssueTypeEnum
  >;
  sessionId?: Maybe<Scalars["String"]>;
  deviceId?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  pagination?: Maybe<PaginationOpts>;
  sort?: Maybe<
    Array<Maybe<SortSessionDeviceInput>> | Maybe<SortSessionDeviceInput>
  >;
}>;

export type GetManySessionSummariesQuery = { __typename?: "Query" } & {
  getManySessionSummaries?: Maybe<
    { __typename?: "SessionSummariesResult" } & Pick<
      SessionSummariesResult,
      "count"
    > & {
        results?: Maybe<
          Array<
            Maybe<
              { __typename?: "SessionSummary" } & Pick<SessionSummary, "id">
            >
          >
        >;
      }
  >;
};

export type GetManySitesQueryVariables = Exact<{
  trialIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  organizationIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
}>;

export type GetManySitesQuery = { __typename?: "Query" } & {
  getManySites?: Maybe<
    Array<
      { __typename?: "Site" } & Pick<
        Site,
        | "id"
        | "name"
        | "city"
        | "country"
        | "contactName"
        | "phone"
        | "state"
        | "streetAddress1"
        | "streetAddress2"
        | "zipCode"
        | "email"
      > & {
          studies?: Maybe<
            Array<Maybe<{ __typename?: "Study" } & Pick<Study, "id" | "name">>>
          >;
        }
    >
  >;
};

export type GetManySubjectsQueryVariables = Exact<{
  trialIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  siteIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  organizationIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  searchText?: Maybe<Scalars["String"]>;
}>;

export type GetManySubjectsQuery = { __typename?: "Query" } & {
  getManySubjects?: Maybe<
    Array<
      { __typename?: "OldSubject" } & Pick<OldSubject, "id" | "name"> & {
          trial?: Maybe<{ __typename?: "Trial" } & Pick<Trial, "id" | "name">>;
          site?: Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "name">>;
        }
    >
  >;
};

export type GetManyTrialsQueryVariables = Exact<{
  organizationIds?: Maybe<Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>>;
  active?: Maybe<Scalars["Boolean"]>;
}>;

export type GetManyTrialsQuery = { __typename?: "Query" } & {
  getManyTrials?: Maybe<
    Array<Maybe<{ __typename?: "Trial" } & TrialFragmentFragment>>
  >;
};

export type GetManyUsersQueryVariables = Exact<{
  userType?: Maybe<UserTypeEnum>;
  deactivated?: Maybe<Scalars["Boolean"]>;
  organizationId?: Maybe<Scalars["ID"]>;
}>;

export type GetManyUsersQuery = { __typename?: "Query" } & {
  getManyUsers?: Maybe<
    Array<
      { __typename?: "UserProfile" } & Pick<
        UserProfile,
        | "id"
        | "email"
        | "firstName"
        | "lastName"
        | "acceptedInvite"
        | "recentlySentInvite"
        | "deactivated"
      > & {
          trials?: Maybe<
            Array<Maybe<{ __typename?: "Trial" } & Pick<Trial, "id" | "name">>>
          >;
        }
    >
  >;
};

export type GetOneBatchQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetOneBatchQuery = { __typename?: "Query" } & {
  getOneBatch?: Maybe<
    { __typename?: "Batch" } & Pick<Batch, "id" | "number"> & {
        deviceSessions?: Maybe<
          { __typename?: "DeviceSessionsResult" } & Pick<
            DeviceSessionsResult,
            "count"
          > & {
              results?: Maybe<
                Array<
                  { __typename?: "DeviceSession" } & Pick<
                    DeviceSession,
                    "id" | "sessionId" | "deviceId" | "status" | "subjectName"
                  > & {
                      sessionStatus?: Maybe<
                        { __typename?: "SessionStatus" } & Pick<
                          SessionStatus,
                          "id" | "key" | "label"
                        >
                      >;
                      organization?: Maybe<
                        { __typename?: "Organization" } & Pick<
                          Organization,
                          "id" | "name"
                        >
                      >;
                      site?: Maybe<
                        { __typename?: "Site" } & Pick<Site, "id" | "name">
                      >;
                      trial?: Maybe<
                        { __typename?: "Trial" } & Pick<Trial, "id" | "name">
                      >;
                    }
                >
              >;
            }
        >;
      }
  >;
};

export type GetOneOrganizationQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetOneOrganizationQuery = { __typename?: "Query" } & {
  getOneOrganization?: Maybe<
    { __typename?: "Organization" } & OrganizationFragmentFragment
  >;
};

export type GetOneTrialQueryVariables = Exact<{
  trialId: Scalars["ID"];
}>;

export type GetOneTrialQuery = { __typename?: "Query" } & {
  getOneTrial?: Maybe<{ __typename?: "Trial" } & TrialFragmentFragment>;
};

export type GetOneUserQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetOneUserQuery = { __typename?: "Query" } & {
  getOneUser?: Maybe<
    { __typename?: "UserProfile" } & Pick<
      UserProfile,
      "id" | "email" | "firstName" | "lastName" | "userType" | "permission"
    > & {
        organizations?: Maybe<
          Array<
            Maybe<
              { __typename?: "ShortOrganization" } & Pick<
                ShortOrganization,
                "id" | "name"
              >
            >
          >
        >;
      }
  >;
};

export type GetOtherDataQueryVariables = Exact<{
  unit: OtherDataUnitEnum;
  organizationIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  sessionStatuses?: Maybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  sessionId?: Maybe<Scalars["String"]>;
}>;

export type GetOtherDataQuery = { __typename?: "Query" } & {
  getOtherData?: Maybe<
    Array<
      { __typename?: "OtherDataResponse" } & Pick<
        OtherDataResponse,
        | "unit"
        | "sessionId"
        | "deviceId"
        | "firstDay"
        | "lastDay"
        | "change"
        | "label"
      > & {
          dailyEvents?: Maybe<
            Array<
              Maybe<
                { __typename?: "DailyEvent" } & Pick<
                  DailyEvent,
                  "day" | "value"
                >
              >
            >
          >;
        }
    >
  >;
};

export type GetSelectedReportQueryVariables = Exact<{
  subjectId: Scalars["ID"];
}>;

export type GetSelectedReportQuery = { __typename?: "Query" } & {
  getSelectedReport?: Maybe<
    { __typename?: "DailySubjectReport" } & Pick<DailySubjectReport, "id">
  >;
};

export type GetSessionLogsQueryVariables = Exact<{
  deviceSessionId: Scalars["ID"];
}>;

export type GetSessionLogsQuery = { __typename?: "Query" } & {
  getSessionLogs?: Maybe<
    Array<
      Maybe<
        { __typename?: "SessionLog" } & Pick<
          SessionLog,
          "id" | "deviceSessionId" | "changedById" | "category" | "createdAt"
        >
      >
    >
  >;
};

export type GetSessionReportFilesQueryVariables = Exact<{
  sessionId: Scalars["ID"];
}>;

export type GetSessionReportFilesQuery = { __typename?: "Query" } & {
  getSessionReportFiles?: Maybe<
    { __typename?: "Base64File" } & Pick<Base64File, "fileName" | "content">
  >;
};

export type GetStats_24HourCoughsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetStats_24HourCoughsQuery = { __typename?: "Query" } & {
  getStats_24HourCoughs?: Maybe<
    { __typename?: "Client24HrSummary" } & Pick<
      Client24HrSummary,
      "change" | "start" | "end"
    >
  >;
};

export type GetStats_ClientSessionStatusQueryVariables = Exact<{
  siteIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  trialIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type GetStats_ClientSessionStatusQuery = { __typename?: "Query" } & {
  getStats_ClientSessionStatus?: Maybe<
    { __typename?: "ClientStatusSummary" } & Pick<
      ClientStatusSummary,
      "clean" | "withIssue" | "total"
    >
  >;
};

export type GetStats_ClientWakingCoughsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetStats_ClientWakingCoughsQuery = { __typename?: "Query" } & {
  getStats_ClientWakingCoughs?: Maybe<
    { __typename?: "Client24HrSummary" } & Pick<
      Client24HrSummary,
      "change" | "start" | "end"
    >
  >;
};

export type GetStats_DeviceSessionAnnotationStatusesQueryVariables = Exact<{
  organizationIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  siteIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  trialIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  sessionStatuses?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  issueStatuses?: Maybe<
    Array<DeviceSessionIssueTypeEnum> | DeviceSessionIssueTypeEnum
  >;
  active?: Maybe<Scalars["Boolean"]>;
}>;

export type GetStats_DeviceSessionAnnotationStatusesQuery = {
  __typename?: "Query";
} & {
  getStats_DeviceSessionAnnotationStatuses?: Maybe<
    Array<
      { __typename?: "StatBlockDataItem" } & Pick<
        StatBlockDataItem,
        "label" | "value" | "key" | "id" | "order"
      >
    >
  >;
};

export type GetStats_DeviceSessionsStatusesQueryVariables = Exact<{
  organizationIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  siteIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  trialIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  issueStatuses?: Maybe<
    Array<DeviceSessionIssueTypeEnum> | DeviceSessionIssueTypeEnum
  >;
  annotationStatuses?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  active?: Maybe<Scalars["Boolean"]>;
}>;

export type GetStats_DeviceSessionsStatusesQuery = { __typename?: "Query" } & {
  getStats_DeviceSessionsStatuses?: Maybe<
    Array<
      { __typename?: "StatBlockDataItem" } & Pick<
        StatBlockDataItem,
        "label" | "value" | "key" | "id" | "order"
      >
    >
  >;
};

export type GetStats_IssuesQueryVariables = Exact<{
  organizationIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  siteIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  trialIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  annotationStatuses?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  sessionStatuses?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  active?: Maybe<Scalars["Boolean"]>;
}>;

export type GetStats_IssuesQuery = { __typename?: "Query" } & {
  getStats_Issues?: Maybe<
    { __typename?: "Stats_SessionsIssues" } & Pick<
      Stats_SessionsIssues,
      | "client_reported"
      | "participant_reported"
      | "device_issue"
      | "other_issue"
    >
  >;
};

export type GetSubjectReportFilesQueryVariables = Exact<{
  subjectId: Scalars["ID"];
}>;

export type GetSubjectReportFilesQuery = { __typename?: "Query" } & {
  getSubjectReportFiles?: Maybe<
    { __typename?: "Base64File" } & Pick<Base64File, "fileName" | "content">
  >;
};

export type OtherDataOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type OtherDataOptionsQuery = { __typename?: "Query" } & {
  otherDataOptions: Array<
    { __typename?: "OtherDataOption" } & Pick<OtherDataOption, "label" | "key">
  >;
};

export type RefreshTokensMutationVariables = Exact<{
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
}>;

export type RefreshTokensMutation = { __typename?: "Mutation" } & {
  refreshTokens?: Maybe<
    { __typename?: "LoginResult" } & Pick<LoginResult, "sessionId"> & {
        tokens?: Maybe<
          { __typename?: "Tokens" } & Pick<
            Tokens,
            "refreshToken" | "accessToken"
          >
        >;
      }
  >;
};

export const CurrentUserFragmentFragmentDoc = gql`
  fragment currentUserFragment on UserProfile {
    id
    __typename
    userType
    email
    firstName
    lastName
    organizationId
    organizationName
    trials {
      id
      name
    }
    activeSite {
      id
      name
    }
    activeTrial {
      id
      name
      sites {
        id
        name
      }
    }
  }
`;
export const OrganizationFragmentFragmentDoc = gql`
  fragment organizationFragment on Organization {
    id
    name
    dataUpdatedAt
  }
`;
export const SubjectFragmentFragmentDoc = gql`
  fragment subjectFragment on OldSubject {
    id
    __typename
    name
    trial {
      id
      name
    }
    site {
      id
      name
    }
    organization {
      id
      name
    }
  }
`;
export const TrialFragmentFragmentDoc = gql`
  fragment trialFragment on Trial {
    id
    __typename
    name
    active
    releases
    numberOfSessionReadyForRelease
    sites {
      id
      name
    }
    metrics {
      key
      label
      value
    }
  }
`;
export const AuthenticateDocument = gql`
  mutation authenticate($params: AuthenticateParamsInput!) {
    authenticate(serviceName: "password", params: $params) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type AuthenticateMutationFn = Apollo.MutationFunction<
  AuthenticateMutation,
  AuthenticateMutationVariables
>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAuthenticateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthenticateMutation,
    AuthenticateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthenticateMutation,
    AuthenticateMutationVariables
  >(AuthenticateDocument, options);
}
export type AuthenticateMutationHookResult = ReturnType<
  typeof useAuthenticateMutation
>;
export type AuthenticateMutationResult =
  Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<
  AuthenticateMutation,
  AuthenticateMutationVariables
>;
export const CreateManySubjectsDocument = gql`
  mutation createManySubjects(
    $numberToCreate: Int
    $organizationId: ID!
    $trialId: ID
    $siteId: ID!
  ) {
    createManySubjects(
      numberToCreate: $numberToCreate
      organizationId: $organizationId
      trialId: $trialId
      siteId: $siteId
    ) {
      success
      errors
    }
  }
`;
export type CreateManySubjectsMutationFn = Apollo.MutationFunction<
  CreateManySubjectsMutation,
  CreateManySubjectsMutationVariables
>;

/**
 * __useCreateManySubjectsMutation__
 *
 * To run a mutation, you first call `useCreateManySubjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManySubjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManySubjectsMutation, { data, loading, error }] = useCreateManySubjectsMutation({
 *   variables: {
 *      numberToCreate: // value for 'numberToCreate'
 *      organizationId: // value for 'organizationId'
 *      trialId: // value for 'trialId'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useCreateManySubjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManySubjectsMutation,
    CreateManySubjectsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateManySubjectsMutation,
    CreateManySubjectsMutationVariables
  >(CreateManySubjectsDocument, options);
}
export type CreateManySubjectsMutationHookResult = ReturnType<
  typeof useCreateManySubjectsMutation
>;
export type CreateManySubjectsMutationResult =
  Apollo.MutationResult<CreateManySubjectsMutation>;
export type CreateManySubjectsMutationOptions = Apollo.BaseMutationOptions<
  CreateManySubjectsMutation,
  CreateManySubjectsMutationVariables
>;
export const CreateNewUserDocument = gql`
  mutation createNewUser($params: CreateNewUserInput!) {
    createNewUser(params: $params) {
      id
      email
      firstName
      lastName
    }
  }
`;
export type CreateNewUserMutationFn = Apollo.MutationFunction<
  CreateNewUserMutation,
  CreateNewUserMutationVariables
>;

/**
 * __useCreateNewUserMutation__
 *
 * To run a mutation, you first call `useCreateNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewUserMutation, { data, loading, error }] = useCreateNewUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateNewUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewUserMutation,
    CreateNewUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNewUserMutation,
    CreateNewUserMutationVariables
  >(CreateNewUserDocument, options);
}
export type CreateNewUserMutationHookResult = ReturnType<
  typeof useCreateNewUserMutation
>;
export type CreateNewUserMutationResult =
  Apollo.MutationResult<CreateNewUserMutation>;
export type CreateNewUserMutationOptions = Apollo.BaseMutationOptions<
  CreateNewUserMutation,
  CreateNewUserMutationVariables
>;
export const CreateOneBatchDocument = gql`
  mutation createOneBatch(
    $sessionCount: Int!
    $organizationId: ID
    $trialId: ID
    $siteId: ID
  ) {
    createOneBatch(
      sessionCount: $sessionCount
      organizationId: $organizationId
      trialId: $trialId
      siteId: $siteId
    ) {
      success
      errors
    }
  }
`;
export type CreateOneBatchMutationFn = Apollo.MutationFunction<
  CreateOneBatchMutation,
  CreateOneBatchMutationVariables
>;

/**
 * __useCreateOneBatchMutation__
 *
 * To run a mutation, you first call `useCreateOneBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneBatchMutation, { data, loading, error }] = useCreateOneBatchMutation({
 *   variables: {
 *      sessionCount: // value for 'sessionCount'
 *      organizationId: // value for 'organizationId'
 *      trialId: // value for 'trialId'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useCreateOneBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneBatchMutation,
    CreateOneBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneBatchMutation,
    CreateOneBatchMutationVariables
  >(CreateOneBatchDocument, options);
}
export type CreateOneBatchMutationHookResult = ReturnType<
  typeof useCreateOneBatchMutation
>;
export type CreateOneBatchMutationResult =
  Apollo.MutationResult<CreateOneBatchMutation>;
export type CreateOneBatchMutationOptions = Apollo.BaseMutationOptions<
  CreateOneBatchMutation,
  CreateOneBatchMutationVariables
>;
export const CreateOneDeviceDocument = gql`
  mutation createOneDevice($deviceId: String!) {
    createOneDevice(deviceId: $deviceId) {
      id
      deviceId
    }
  }
`;
export type CreateOneDeviceMutationFn = Apollo.MutationFunction<
  CreateOneDeviceMutation,
  CreateOneDeviceMutationVariables
>;

/**
 * __useCreateOneDeviceMutation__
 *
 * To run a mutation, you first call `useCreateOneDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneDeviceMutation, { data, loading, error }] = useCreateOneDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useCreateOneDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneDeviceMutation,
    CreateOneDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneDeviceMutation,
    CreateOneDeviceMutationVariables
  >(CreateOneDeviceDocument, options);
}
export type CreateOneDeviceMutationHookResult = ReturnType<
  typeof useCreateOneDeviceMutation
>;
export type CreateOneDeviceMutationResult =
  Apollo.MutationResult<CreateOneDeviceMutation>;
export type CreateOneDeviceMutationOptions = Apollo.BaseMutationOptions<
  CreateOneDeviceMutation,
  CreateOneDeviceMutationVariables
>;
export const CreateOneIssueDocument = gql`
  mutation createOneIssue(
    $deviceSessionId: ID!
    $description: String!
    $issueType: DeviceSessionIssueTypeEnum
  ) {
    createOneIssue(
      deviceSessionId: $deviceSessionId
      description: $description
      issueType: $issueType
    ) {
      id
      issues {
        id
        issueType
        description
        createdAt
      }
    }
  }
`;
export type CreateOneIssueMutationFn = Apollo.MutationFunction<
  CreateOneIssueMutation,
  CreateOneIssueMutationVariables
>;

/**
 * __useCreateOneIssueMutation__
 *
 * To run a mutation, you first call `useCreateOneIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneIssueMutation, { data, loading, error }] = useCreateOneIssueMutation({
 *   variables: {
 *      deviceSessionId: // value for 'deviceSessionId'
 *      description: // value for 'description'
 *      issueType: // value for 'issueType'
 *   },
 * });
 */
export function useCreateOneIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneIssueMutation,
    CreateOneIssueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneIssueMutation,
    CreateOneIssueMutationVariables
  >(CreateOneIssueDocument, options);
}
export type CreateOneIssueMutationHookResult = ReturnType<
  typeof useCreateOneIssueMutation
>;
export type CreateOneIssueMutationResult =
  Apollo.MutationResult<CreateOneIssueMutation>;
export type CreateOneIssueMutationOptions = Apollo.BaseMutationOptions<
  CreateOneIssueMutation,
  CreateOneIssueMutationVariables
>;
export const CreateOrganizationDocument = gql`
  mutation createOrganization($params: OrganizationInput!) {
    createOrganization(params: $params) {
      id
      name
    }
  }
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, options);
}
export type CreateOrganizationMutationHookResult = ReturnType<
  typeof useCreateOrganizationMutation
>;
export type CreateOrganizationMutationResult =
  Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const CreateSessionIssueDocument = gql`
  mutation CreateSessionIssue($input: CreateSessionIssueInput!) {
    createSessionIssue(input: $input) {
      id
      index
    }
  }
`;
export type CreateSessionIssueMutationFn = Apollo.MutationFunction<
  CreateSessionIssueMutation,
  CreateSessionIssueMutationVariables
>;

/**
 * __useCreateSessionIssueMutation__
 *
 * To run a mutation, you first call `useCreateSessionIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionIssueMutation, { data, loading, error }] = useCreateSessionIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSessionIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSessionIssueMutation,
    CreateSessionIssueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSessionIssueMutation,
    CreateSessionIssueMutationVariables
  >(CreateSessionIssueDocument, options);
}
export type CreateSessionIssueMutationHookResult = ReturnType<
  typeof useCreateSessionIssueMutation
>;
export type CreateSessionIssueMutationResult =
  Apollo.MutationResult<CreateSessionIssueMutation>;
export type CreateSessionIssueMutationOptions = Apollo.BaseMutationOptions<
  CreateSessionIssueMutation,
  CreateSessionIssueMutationVariables
>;
export const CreateSessionProjectDocument = gql`
  mutation CreateSessionProject($input: CreateSessionProjectInput!) {
    createSessionProject(input: $input) {
      success
      errors
    }
  }
`;
export type CreateSessionProjectMutationFn = Apollo.MutationFunction<
  CreateSessionProjectMutation,
  CreateSessionProjectMutationVariables
>;

/**
 * __useCreateSessionProjectMutation__
 *
 * To run a mutation, you first call `useCreateSessionProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionProjectMutation, { data, loading, error }] = useCreateSessionProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSessionProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSessionProjectMutation,
    CreateSessionProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSessionProjectMutation,
    CreateSessionProjectMutationVariables
  >(CreateSessionProjectDocument, options);
}
export type CreateSessionProjectMutationHookResult = ReturnType<
  typeof useCreateSessionProjectMutation
>;
export type CreateSessionProjectMutationResult =
  Apollo.MutationResult<CreateSessionProjectMutation>;
export type CreateSessionProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateSessionProjectMutation,
  CreateSessionProjectMutationVariables
>;
export const CreateSiteDocument = gql`
  mutation createSite($input: SiteInput!) {
    createSite(input: $input) {
      id
      name
      contactName
      phone
      email
      streetAddress1
      streetAddress2
      city
      state
      zipCode
      country
      studies {
        id
        name
        active
      }
    }
  }
`;
export type CreateSiteMutationFn = Apollo.MutationFunction<
  CreateSiteMutation,
  CreateSiteMutationVariables
>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSiteMutation,
    CreateSiteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(
    CreateSiteDocument,
    options
  );
}
export type CreateSiteMutationHookResult = ReturnType<
  typeof useCreateSiteMutation
>;
export type CreateSiteMutationResult =
  Apollo.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = Apollo.BaseMutationOptions<
  CreateSiteMutation,
  CreateSiteMutationVariables
>;
export const UpdateSiteDocument = gql`
  mutation UpdateSite($input: UpdateSiteInput!) {
    updateSite(input: $input) {
      id
      name
      contactName
      phone
      email
      streetAddress1
      streetAddress2
      city
      state
      zipCode
      country
      studies {
        id
        name
      }
    }
  }
`;
export type UpdateSiteMutationFn = Apollo.MutationFunction<
  UpdateSiteMutation,
  UpdateSiteMutationVariables
>;

/**
 * __useUpdateSiteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteMutation, { data, loading, error }] = useUpdateSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSiteMutation,
    UpdateSiteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(
    UpdateSiteDocument,
    options
  );
}
export type UpdateSiteMutationHookResult = ReturnType<
  typeof useUpdateSiteMutation
>;
export type UpdateSiteMutationResult =
  Apollo.MutationResult<UpdateSiteMutation>;
export type UpdateSiteMutationOptions = Apollo.BaseMutationOptions<
  UpdateSiteMutation,
  UpdateSiteMutationVariables
>;
export const CreateStudyDocument = gql`
  mutation createStudy($input: StudyInput!) {
    createStudy(input: $input) {
      id
      name
    }
  }
`;
export type CreateStudyMutationFn = Apollo.MutationFunction<
  CreateStudyMutation,
  CreateStudyMutationVariables
>;

/**
 * __useCreateStudyMutation__
 *
 * To run a mutation, you first call `useCreateStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudyMutation, { data, loading, error }] = useCreateStudyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStudyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStudyMutation,
    CreateStudyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateStudyMutation, CreateStudyMutationVariables>(
    CreateStudyDocument,
    options
  );
}
export type CreateStudyMutationHookResult = ReturnType<
  typeof useCreateStudyMutation
>;
export type CreateStudyMutationResult =
  Apollo.MutationResult<CreateStudyMutation>;
export type CreateStudyMutationOptions = Apollo.BaseMutationOptions<
  CreateStudyMutation,
  CreateStudyMutationVariables
>;
export const CreateSubjectIssueDocument = gql`
  mutation CreateSubjectIssue($input: CreateSubjectIssueInput!) {
    createSubjectIssue(input: $input) {
      id
      createdAt
      subjectId
    }
  }
`;
export type CreateSubjectIssueMutationFn = Apollo.MutationFunction<
  CreateSubjectIssueMutation,
  CreateSubjectIssueMutationVariables
>;

/**
 * __useCreateSubjectIssueMutation__
 *
 * To run a mutation, you first call `useCreateSubjectIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubjectIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubjectIssueMutation, { data, loading, error }] = useCreateSubjectIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubjectIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubjectIssueMutation,
    CreateSubjectIssueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSubjectIssueMutation,
    CreateSubjectIssueMutationVariables
  >(CreateSubjectIssueDocument, options);
}
export type CreateSubjectIssueMutationHookResult = ReturnType<
  typeof useCreateSubjectIssueMutation
>;
export type CreateSubjectIssueMutationResult =
  Apollo.MutationResult<CreateSubjectIssueMutation>;
export type CreateSubjectIssueMutationOptions = Apollo.BaseMutationOptions<
  CreateSubjectIssueMutation,
  CreateSubjectIssueMutationVariables
>;
export const CreateSuperAdminUserDocument = gql`
  mutation createSuperAdminUser($params: CreateNewUserInput!) {
    createSuperAdminUser(params: $params) {
      id
      email
    }
  }
`;
export type CreateSuperAdminUserMutationFn = Apollo.MutationFunction<
  CreateSuperAdminUserMutation,
  CreateSuperAdminUserMutationVariables
>;

/**
 * __useCreateSuperAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateSuperAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuperAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuperAdminUserMutation, { data, loading, error }] = useCreateSuperAdminUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateSuperAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSuperAdminUserMutation,
    CreateSuperAdminUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSuperAdminUserMutation,
    CreateSuperAdminUserMutationVariables
  >(CreateSuperAdminUserDocument, options);
}
export type CreateSuperAdminUserMutationHookResult = ReturnType<
  typeof useCreateSuperAdminUserMutation
>;
export type CreateSuperAdminUserMutationResult =
  Apollo.MutationResult<CreateSuperAdminUserMutation>;
export type CreateSuperAdminUserMutationOptions = Apollo.BaseMutationOptions<
  CreateSuperAdminUserMutation,
  CreateSuperAdminUserMutationVariables
>;
export const DeleteOneBatchDocument = gql`
  mutation deleteOneBatch($id: ID!) {
    deleteOneBatch(id: $id) {
      success
    }
  }
`;
export type DeleteOneBatchMutationFn = Apollo.MutationFunction<
  DeleteOneBatchMutation,
  DeleteOneBatchMutationVariables
>;

/**
 * __useDeleteOneBatchMutation__
 *
 * To run a mutation, you first call `useDeleteOneBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneBatchMutation, { data, loading, error }] = useDeleteOneBatchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneBatchMutation,
    DeleteOneBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOneBatchMutation,
    DeleteOneBatchMutationVariables
  >(DeleteOneBatchDocument, options);
}
export type DeleteOneBatchMutationHookResult = ReturnType<
  typeof useDeleteOneBatchMutation
>;
export type DeleteOneBatchMutationResult =
  Apollo.MutationResult<DeleteOneBatchMutation>;
export type DeleteOneBatchMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneBatchMutation,
  DeleteOneBatchMutationVariables
>;
export const DeleteOneSuperAdminUserDocument = gql`
  mutation deleteOneSuperAdminUser($id: ID!) {
    deleteOneSuperAdminUser(id: $id) {
      success
      errors
    }
  }
`;
export type DeleteOneSuperAdminUserMutationFn = Apollo.MutationFunction<
  DeleteOneSuperAdminUserMutation,
  DeleteOneSuperAdminUserMutationVariables
>;

/**
 * __useDeleteOneSuperAdminUserMutation__
 *
 * To run a mutation, you first call `useDeleteOneSuperAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneSuperAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneSuperAdminUserMutation, { data, loading, error }] = useDeleteOneSuperAdminUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneSuperAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneSuperAdminUserMutation,
    DeleteOneSuperAdminUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOneSuperAdminUserMutation,
    DeleteOneSuperAdminUserMutationVariables
  >(DeleteOneSuperAdminUserDocument, options);
}
export type DeleteOneSuperAdminUserMutationHookResult = ReturnType<
  typeof useDeleteOneSuperAdminUserMutation
>;
export type DeleteOneSuperAdminUserMutationResult =
  Apollo.MutationResult<DeleteOneSuperAdminUserMutation>;
export type DeleteOneSuperAdminUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneSuperAdminUserMutation,
  DeleteOneSuperAdminUserMutationVariables
>;
export const DownloadDeviceEventsXptDocument = gql`
  mutation downloadDeviceEventsXpt($query: DeviceSessionQueryParams) {
    downloadDeviceEventsXpt(query: $query) {
      url
      filename
    }
  }
`;
export type DownloadDeviceEventsXptMutationFn = Apollo.MutationFunction<
  DownloadDeviceEventsXptMutation,
  DownloadDeviceEventsXptMutationVariables
>;

/**
 * __useDownloadDeviceEventsXptMutation__
 *
 * To run a mutation, you first call `useDownloadDeviceEventsXptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadDeviceEventsXptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadDeviceEventsXptMutation, { data, loading, error }] = useDownloadDeviceEventsXptMutation({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useDownloadDeviceEventsXptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DownloadDeviceEventsXptMutation,
    DownloadDeviceEventsXptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DownloadDeviceEventsXptMutation,
    DownloadDeviceEventsXptMutationVariables
  >(DownloadDeviceEventsXptDocument, options);
}
export type DownloadDeviceEventsXptMutationHookResult = ReturnType<
  typeof useDownloadDeviceEventsXptMutation
>;
export type DownloadDeviceEventsXptMutationResult =
  Apollo.MutationResult<DownloadDeviceEventsXptMutation>;
export type DownloadDeviceEventsXptMutationOptions = Apollo.BaseMutationOptions<
  DownloadDeviceEventsXptMutation,
  DownloadDeviceEventsXptMutationVariables
>;
export const GenerateReportDocument = gql`
  mutation GenerateReport($subjectId: String) {
    generateReport(subjectId: $subjectId) {
      subject
      createdAt
      id
      data {
        day
        coughs
        avgHrCoughs
        medRespRate
        q1RespRate
        q3RespRate
        steps
      }
    }
  }
`;
export type GenerateReportMutationFn = Apollo.MutationFunction<
  GenerateReportMutation,
  GenerateReportMutationVariables
>;

/**
 * __useGenerateReportMutation__
 *
 * To run a mutation, you first call `useGenerateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReportMutation, { data, loading, error }] = useGenerateReportMutation({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useGenerateReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateReportMutation,
    GenerateReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateReportMutation,
    GenerateReportMutationVariables
  >(GenerateReportDocument, options);
}
export type GenerateReportMutationHookResult = ReturnType<
  typeof useGenerateReportMutation
>;
export type GenerateReportMutationResult =
  Apollo.MutationResult<GenerateReportMutation>;
export type GenerateReportMutationOptions = Apollo.BaseMutationOptions<
  GenerateReportMutation,
  GenerateReportMutationVariables
>;
export const ImpersonateOrganizationDocument = gql`
  mutation impersonateOrganization($organizationId: ID!) {
    impersonateOrganization(organizationId: $organizationId) {
      id
    }
  }
`;
export type ImpersonateOrganizationMutationFn = Apollo.MutationFunction<
  ImpersonateOrganizationMutation,
  ImpersonateOrganizationMutationVariables
>;

/**
 * __useImpersonateOrganizationMutation__
 *
 * To run a mutation, you first call `useImpersonateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateOrganizationMutation, { data, loading, error }] = useImpersonateOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useImpersonateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImpersonateOrganizationMutation,
    ImpersonateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImpersonateOrganizationMutation,
    ImpersonateOrganizationMutationVariables
  >(ImpersonateOrganizationDocument, options);
}
export type ImpersonateOrganizationMutationHookResult = ReturnType<
  typeof useImpersonateOrganizationMutation
>;
export type ImpersonateOrganizationMutationResult =
  Apollo.MutationResult<ImpersonateOrganizationMutation>;
export type ImpersonateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  ImpersonateOrganizationMutation,
  ImpersonateOrganizationMutationVariables
>;
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const ReleaseSessionsDocument = gql`
  mutation releaseSessions($trialId: ID!) {
    releaseSessions(trialId: $trialId) {
      id
      releases
      numberOfSessionReadyForRelease
    }
  }
`;
export type ReleaseSessionsMutationFn = Apollo.MutationFunction<
  ReleaseSessionsMutation,
  ReleaseSessionsMutationVariables
>;

/**
 * __useReleaseSessionsMutation__
 *
 * To run a mutation, you first call `useReleaseSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseSessionsMutation, { data, loading, error }] = useReleaseSessionsMutation({
 *   variables: {
 *      trialId: // value for 'trialId'
 *   },
 * });
 */
export function useReleaseSessionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReleaseSessionsMutation,
    ReleaseSessionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReleaseSessionsMutation,
    ReleaseSessionsMutationVariables
  >(ReleaseSessionsDocument, options);
}
export type ReleaseSessionsMutationHookResult = ReturnType<
  typeof useReleaseSessionsMutation
>;
export type ReleaseSessionsMutationResult =
  Apollo.MutationResult<ReleaseSessionsMutation>;
export type ReleaseSessionsMutationOptions = Apollo.BaseMutationOptions<
  ReleaseSessionsMutation,
  ReleaseSessionsMutationVariables
>;
export const ResendInviteEmailDocument = gql`
  mutation resendInviteEmail($userId: ID!) {
    resendInviteEmail(userId: $userId) {
      success
    }
  }
`;
export type ResendInviteEmailMutationFn = Apollo.MutationFunction<
  ResendInviteEmailMutation,
  ResendInviteEmailMutationVariables
>;

/**
 * __useResendInviteEmailMutation__
 *
 * To run a mutation, you first call `useResendInviteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteEmailMutation, { data, loading, error }] = useResendInviteEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendInviteEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendInviteEmailMutation,
    ResendInviteEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendInviteEmailMutation,
    ResendInviteEmailMutationVariables
  >(ResendInviteEmailDocument, options);
}
export type ResendInviteEmailMutationHookResult = ReturnType<
  typeof useResendInviteEmailMutation
>;
export type ResendInviteEmailMutationResult =
  Apollo.MutationResult<ResendInviteEmailMutation>;
export type ResendInviteEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendInviteEmailMutation,
  ResendInviteEmailMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SelectDailySubjectReportDocument = gql`
  mutation SelectDailySubjectReport($input: SelectDailySubjectReportInput!) {
    selectDailySubjectReport(input: $input) {
      subject
      createdAt
      id
    }
  }
`;
export type SelectDailySubjectReportMutationFn = Apollo.MutationFunction<
  SelectDailySubjectReportMutation,
  SelectDailySubjectReportMutationVariables
>;

/**
 * __useSelectDailySubjectReportMutation__
 *
 * To run a mutation, you first call `useSelectDailySubjectReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectDailySubjectReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectDailySubjectReportMutation, { data, loading, error }] = useSelectDailySubjectReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectDailySubjectReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectDailySubjectReportMutation,
    SelectDailySubjectReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SelectDailySubjectReportMutation,
    SelectDailySubjectReportMutationVariables
  >(SelectDailySubjectReportDocument, options);
}
export type SelectDailySubjectReportMutationHookResult = ReturnType<
  typeof useSelectDailySubjectReportMutation
>;
export type SelectDailySubjectReportMutationResult =
  Apollo.MutationResult<SelectDailySubjectReportMutation>;
export type SelectDailySubjectReportMutationOptions =
  Apollo.BaseMutationOptions<
    SelectDailySubjectReportMutation,
    SelectDailySubjectReportMutationVariables
  >;
export const SendResetPasswordEmailDocument = gql`
  mutation sendResetPasswordEmail($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendResetPasswordEmailMutation,
    SendResetPasswordEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendResetPasswordEmailMutation,
    SendResetPasswordEmailMutationVariables
  >(SendResetPasswordEmailDocument, options);
}
export type SendResetPasswordEmailMutationHookResult = ReturnType<
  typeof useSendResetPasswordEmailMutation
>;
export type SendResetPasswordEmailMutationResult =
  Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>;
export const UpdateActiveSiteIdDocument = gql`
  mutation updateActiveSiteId($siteId: ID!) {
    updateActiveSiteId(siteId: $siteId) {
      ...currentUserFragment
    }
  }
  ${CurrentUserFragmentFragmentDoc}
`;
export type UpdateActiveSiteIdMutationFn = Apollo.MutationFunction<
  UpdateActiveSiteIdMutation,
  UpdateActiveSiteIdMutationVariables
>;

/**
 * __useUpdateActiveSiteIdMutation__
 *
 * To run a mutation, you first call `useUpdateActiveSiteIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveSiteIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveSiteIdMutation, { data, loading, error }] = useUpdateActiveSiteIdMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useUpdateActiveSiteIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateActiveSiteIdMutation,
    UpdateActiveSiteIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateActiveSiteIdMutation,
    UpdateActiveSiteIdMutationVariables
  >(UpdateActiveSiteIdDocument, options);
}
export type UpdateActiveSiteIdMutationHookResult = ReturnType<
  typeof useUpdateActiveSiteIdMutation
>;
export type UpdateActiveSiteIdMutationResult =
  Apollo.MutationResult<UpdateActiveSiteIdMutation>;
export type UpdateActiveSiteIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateActiveSiteIdMutation,
  UpdateActiveSiteIdMutationVariables
>;
export const UpdateActiveTrialIdDocument = gql`
  mutation updateActiveTrialId($trialId: ID!) {
    updateActiveTrialId(trialId: $trialId) {
      ...currentUserFragment
    }
  }
  ${CurrentUserFragmentFragmentDoc}
`;
export type UpdateActiveTrialIdMutationFn = Apollo.MutationFunction<
  UpdateActiveTrialIdMutation,
  UpdateActiveTrialIdMutationVariables
>;

/**
 * __useUpdateActiveTrialIdMutation__
 *
 * To run a mutation, you first call `useUpdateActiveTrialIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveTrialIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveTrialIdMutation, { data, loading, error }] = useUpdateActiveTrialIdMutation({
 *   variables: {
 *      trialId: // value for 'trialId'
 *   },
 * });
 */
export function useUpdateActiveTrialIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateActiveTrialIdMutation,
    UpdateActiveTrialIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateActiveTrialIdMutation,
    UpdateActiveTrialIdMutationVariables
  >(UpdateActiveTrialIdDocument, options);
}
export type UpdateActiveTrialIdMutationHookResult = ReturnType<
  typeof useUpdateActiveTrialIdMutation
>;
export type UpdateActiveTrialIdMutationResult =
  Apollo.MutationResult<UpdateActiveTrialIdMutation>;
export type UpdateActiveTrialIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateActiveTrialIdMutation,
  UpdateActiveTrialIdMutationVariables
>;
export const UpdateOneDeviceSessionDocument = gql`
  mutation updateOneDeviceSession($id: ID!, $params: DeviceSessionInput) {
    updateOneDeviceSession(id: $id, params: $params) {
      id
      status
      deviceId
      subjectName
      organization {
        id
        name
      }
      trial {
        id
        name
      }
      site {
        id
        name
      }
    }
  }
`;
export type UpdateOneDeviceSessionMutationFn = Apollo.MutationFunction<
  UpdateOneDeviceSessionMutation,
  UpdateOneDeviceSessionMutationVariables
>;

/**
 * __useUpdateOneDeviceSessionMutation__
 *
 * To run a mutation, you first call `useUpdateOneDeviceSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneDeviceSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneDeviceSessionMutation, { data, loading, error }] = useUpdateOneDeviceSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOneDeviceSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneDeviceSessionMutation,
    UpdateOneDeviceSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOneDeviceSessionMutation,
    UpdateOneDeviceSessionMutationVariables
  >(UpdateOneDeviceSessionDocument, options);
}
export type UpdateOneDeviceSessionMutationHookResult = ReturnType<
  typeof useUpdateOneDeviceSessionMutation
>;
export type UpdateOneDeviceSessionMutationResult =
  Apollo.MutationResult<UpdateOneDeviceSessionMutation>;
export type UpdateOneDeviceSessionMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneDeviceSessionMutation,
  UpdateOneDeviceSessionMutationVariables
>;
export const UpdateOneTrialDocument = gql`
  mutation updateOneTrial($id: ID!, $params: StudyInput) {
    updateOneTrial(id: $id, params: $params) {
      ...trialFragment
    }
  }
  ${TrialFragmentFragmentDoc}
`;
export type UpdateOneTrialMutationFn = Apollo.MutationFunction<
  UpdateOneTrialMutation,
  UpdateOneTrialMutationVariables
>;

/**
 * __useUpdateOneTrialMutation__
 *
 * To run a mutation, you first call `useUpdateOneTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneTrialMutation, { data, loading, error }] = useUpdateOneTrialMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOneTrialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneTrialMutation,
    UpdateOneTrialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOneTrialMutation,
    UpdateOneTrialMutationVariables
  >(UpdateOneTrialDocument, options);
}
export type UpdateOneTrialMutationHookResult = ReturnType<
  typeof useUpdateOneTrialMutation
>;
export type UpdateOneTrialMutationResult =
  Apollo.MutationResult<UpdateOneTrialMutation>;
export type UpdateOneTrialMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneTrialMutation,
  UpdateOneTrialMutationVariables
>;
export const UpdateOneUserDocument = gql`
  mutation updateOneUser($id: ID!, $params: UpdateUserInput) {
    updateOneUser(id: $id, params: $params) {
      id
      firstName
      lastName
      email
      deactivated
    }
  }
`;
export type UpdateOneUserMutationFn = Apollo.MutationFunction<
  UpdateOneUserMutation,
  UpdateOneUserMutationVariables
>;

/**
 * __useUpdateOneUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneUserMutation, { data, loading, error }] = useUpdateOneUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOneUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneUserMutation,
    UpdateOneUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOneUserMutation,
    UpdateOneUserMutationVariables
  >(UpdateOneUserDocument, options);
}
export type UpdateOneUserMutationHookResult = ReturnType<
  typeof useUpdateOneUserMutation
>;
export type UpdateOneUserMutationResult =
  Apollo.MutationResult<UpdateOneUserMutation>;
export type UpdateOneUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneUserMutation,
  UpdateOneUserMutationVariables
>;
export const UpdateSessionDocument = gql`
  mutation UpdateSession($input: UpdateSessionInput!) {
    updateSession(input: $input) {
      selected
      id
    }
  }
`;
export type UpdateSessionMutationFn = Apollo.MutationFunction<
  UpdateSessionMutation,
  UpdateSessionMutationVariables
>;

/**
 * __useUpdateSessionMutation__
 *
 * To run a mutation, you first call `useUpdateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionMutation, { data, loading, error }] = useUpdateSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSessionMutation,
    UpdateSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSessionMutation,
    UpdateSessionMutationVariables
  >(UpdateSessionDocument, options);
}
export type UpdateSessionMutationHookResult = ReturnType<
  typeof useUpdateSessionMutation
>;
export type UpdateSessionMutationResult =
  Apollo.MutationResult<UpdateSessionMutation>;
export type UpdateSessionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSessionMutation,
  UpdateSessionMutationVariables
>;
export const UpdateSessionReviewedDocument = gql`
  mutation UpdateSessionReviewed($input: UpdateSessionInput!) {
    updateSession(input: $input) {
      status {
        key
      }
      id
    }
  }
`;
export type UpdateSessionReviewedMutationFn = Apollo.MutationFunction<
  UpdateSessionReviewedMutation,
  UpdateSessionReviewedMutationVariables
>;

/**
 * __useUpdateSessionReviewedMutation__
 *
 * To run a mutation, you first call `useUpdateSessionReviewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionReviewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionReviewedMutation, { data, loading, error }] = useUpdateSessionReviewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSessionReviewedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSessionReviewedMutation,
    UpdateSessionReviewedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSessionReviewedMutation,
    UpdateSessionReviewedMutationVariables
  >(UpdateSessionReviewedDocument, options);
}
export type UpdateSessionReviewedMutationHookResult = ReturnType<
  typeof useUpdateSessionReviewedMutation
>;
export type UpdateSessionReviewedMutationResult =
  Apollo.MutationResult<UpdateSessionReviewedMutation>;
export type UpdateSessionReviewedMutationOptions = Apollo.BaseMutationOptions<
  UpdateSessionReviewedMutation,
  UpdateSessionReviewedMutationVariables
>;
export const UpdateSessionReferenceDocument = gql`
  mutation UpdateSessionReference($input: UpdateSessionInput!) {
    updateSession(input: $input) {
      id
    }
  }
`;
export type UpdateSessionReferenceMutationFn = Apollo.MutationFunction<
  UpdateSessionReferenceMutation,
  UpdateSessionReferenceMutationVariables
>;

/**
 * __useUpdateSessionReferenceMutation__
 *
 * To run a mutation, you first call `useUpdateSessionReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionReferenceMutation, { data, loading, error }] = useUpdateSessionReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSessionReferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSessionReferenceMutation,
    UpdateSessionReferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSessionReferenceMutation,
    UpdateSessionReferenceMutationVariables
  >(UpdateSessionReferenceDocument, options);
}
export type UpdateSessionReferenceMutationHookResult = ReturnType<
  typeof useUpdateSessionReferenceMutation
>;
export type UpdateSessionReferenceMutationResult =
  Apollo.MutationResult<UpdateSessionReferenceMutation>;
export type UpdateSessionReferenceMutationOptions = Apollo.BaseMutationOptions<
  UpdateSessionReferenceMutation,
  UpdateSessionReferenceMutationVariables
>;
export const ResolveSessionIssueDocument = gql`
  mutation resolveSessionIssue($input: ResolveSessionIssueInput!) {
    resolveSessionIssue(input: $input) {
      id
    }
  }
`;
export type ResolveSessionIssueMutationFn = Apollo.MutationFunction<
  ResolveSessionIssueMutation,
  ResolveSessionIssueMutationVariables
>;

/**
 * __useResolveSessionIssueMutation__
 *
 * To run a mutation, you first call `useResolveSessionIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveSessionIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveSessionIssueMutation, { data, loading, error }] = useResolveSessionIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveSessionIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResolveSessionIssueMutation,
    ResolveSessionIssueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResolveSessionIssueMutation,
    ResolveSessionIssueMutationVariables
  >(ResolveSessionIssueDocument, options);
}
export type ResolveSessionIssueMutationHookResult = ReturnType<
  typeof useResolveSessionIssueMutation
>;
export type ResolveSessionIssueMutationResult =
  Apollo.MutationResult<ResolveSessionIssueMutation>;
export type ResolveSessionIssueMutationOptions = Apollo.BaseMutationOptions<
  ResolveSessionIssueMutation,
  ResolveSessionIssueMutationVariables
>;
export const UpdateStudyDocument = gql`
  mutation UpdateStudy($input: UpdateStudyInput!) {
    updateStudy(input: $input) {
      id
      name
      sites {
        id
        name
      }
      active
      metrics {
        key
        label
        value
      }
      sessionReferences {
        prefix
        day
        sessionId
      }
      subjectGroups {
        prefix
        digits
        count
        start
        site {
          id
        }
        createdAt
      }
      endDate
      completedSubjects
      totalSubjects
    }
  }
`;
export type UpdateStudyMutationFn = Apollo.MutationFunction<
  UpdateStudyMutation,
  UpdateStudyMutationVariables
>;

/**
 * __useUpdateStudyMutation__
 *
 * To run a mutation, you first call `useUpdateStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudyMutation, { data, loading, error }] = useUpdateStudyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStudyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStudyMutation,
    UpdateStudyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStudyMutation, UpdateStudyMutationVariables>(
    UpdateStudyDocument,
    options
  );
}
export type UpdateStudyMutationHookResult = ReturnType<
  typeof useUpdateStudyMutation
>;
export type UpdateStudyMutationResult =
  Apollo.MutationResult<UpdateStudyMutation>;
export type UpdateStudyMutationOptions = Apollo.BaseMutationOptions<
  UpdateStudyMutation,
  UpdateStudyMutationVariables
>;
export const UpdateSubjectDocument = gql`
  mutation UpdateSubject($input: UpdateSubjectInput!) {
    updateSubject(input: $input) {
      id
      client {
        name
        id
      }
      subjectId
    }
  }
`;
export type UpdateSubjectMutationFn = Apollo.MutationFunction<
  UpdateSubjectMutation,
  UpdateSubjectMutationVariables
>;

/**
 * __useUpdateSubjectMutation__
 *
 * To run a mutation, you first call `useUpdateSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubjectMutation, { data, loading, error }] = useUpdateSubjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubjectMutation,
    UpdateSubjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSubjectMutation,
    UpdateSubjectMutationVariables
  >(UpdateSubjectDocument, options);
}
export type UpdateSubjectMutationHookResult = ReturnType<
  typeof useUpdateSubjectMutation
>;
export type UpdateSubjectMutationResult =
  Apollo.MutationResult<UpdateSubjectMutation>;
export type UpdateSubjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubjectMutation,
  UpdateSubjectMutationVariables
>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...currentUserFragment
    }
  }
  ${CurrentUserFragmentFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const FindClientsDocument = gql`
  query findClients($pagination: PaginationOpts) {
    findClients(pagination: $pagination) {
      offset
      limit
      count
      items {
        id
        name
        studies {
          id
          name
          active
        }
      }
    }
  }
`;

/**
 * __useFindClientsQuery__
 *
 * To run a query within a React component, call `useFindClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindClientsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindClientsQuery,
    FindClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindClientsQuery, FindClientsQueryVariables>(
    FindClientsDocument,
    options
  );
}
export function useFindClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindClientsQuery,
    FindClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindClientsQuery, FindClientsQueryVariables>(
    FindClientsDocument,
    options
  );
}
export type FindClientsQueryHookResult = ReturnType<typeof useFindClientsQuery>;
export type FindClientsLazyQueryHookResult = ReturnType<
  typeof useFindClientsLazyQuery
>;
export type FindClientsQueryResult = Apollo.QueryResult<
  FindClientsQuery,
  FindClientsQueryVariables
>;
export const FindStudiesDocument = gql`
  query findStudies($clientId: ID, $pagination: PaginationOpts) {
    findStudies(clientId: $clientId, pagination: $pagination) {
      offset
      limit
      count
      items {
        id
        name
        sites {
          id
          name
          city
          country
          contactName
          phone
          state
          streetAddress1
          streetAddress2
          zipCode
        }
        startDate
        endDate
        active
        metrics {
          key
          label
          value
        }
        sessionReferences {
          prefix
          day
          sessionId
        }
        subjectGroups {
          prefix
          digits
          count
          start
          createdAt
          site {
            id
            name
          }
        }
        completedSubjects
        totalSubjects
      }
    }
  }
`;

/**
 * __useFindStudiesQuery__
 *
 * To run a query within a React component, call `useFindStudiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStudiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStudiesQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindStudiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindStudiesQuery,
    FindStudiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindStudiesQuery, FindStudiesQueryVariables>(
    FindStudiesDocument,
    options
  );
}
export function useFindStudiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindStudiesQuery,
    FindStudiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindStudiesQuery, FindStudiesQueryVariables>(
    FindStudiesDocument,
    options
  );
}
export type FindStudiesQueryHookResult = ReturnType<typeof useFindStudiesQuery>;
export type FindStudiesLazyQueryHookResult = ReturnType<
  typeof useFindStudiesLazyQuery
>;
export type FindStudiesQueryResult = Apollo.QueryResult<
  FindStudiesQuery,
  FindStudiesQueryVariables
>;
export const FindSubjectIssuesTotalsDocument = gql`
  query FindSubjectIssuesTotals(
    $clientIds: [ID!]
    $siteIds: [ID!]
    $studyIds: [ID]
    $statuses: [String]
    $uploadIds: [ID]
    $deviceIds: [ID]
    $subjectIds: [ID]
    $sessionIds: [Int]
    $sessionStartDate: DateTime
    $sessionEndDate: DateTime
  ) {
    findSubjectIssuesTotals(
      clientIds: $clientIds
      siteIds: $siteIds
      studyIds: $studyIds
      statuses: $statuses
      uploadIds: $uploadIds
      deviceIds: $deviceIds
      subjectIds: $subjectIds
      sessionIds: $sessionIds
      sessionStartDate: $sessionStartDate
      sessionEndDate: $sessionEndDate
    ) {
      issueType
      total
    }
  }
`;

/**
 * __useFindSubjectIssuesTotalsQuery__
 *
 * To run a query within a React component, call `useFindSubjectIssuesTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSubjectIssuesTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSubjectIssuesTotalsQuery({
 *   variables: {
 *      clientIds: // value for 'clientIds'
 *      siteIds: // value for 'siteIds'
 *      studyIds: // value for 'studyIds'
 *      statuses: // value for 'statuses'
 *      uploadIds: // value for 'uploadIds'
 *      deviceIds: // value for 'deviceIds'
 *      subjectIds: // value for 'subjectIds'
 *      sessionIds: // value for 'sessionIds'
 *      sessionStartDate: // value for 'sessionStartDate'
 *      sessionEndDate: // value for 'sessionEndDate'
 *   },
 * });
 */
export function useFindSubjectIssuesTotalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindSubjectIssuesTotalsQuery,
    FindSubjectIssuesTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindSubjectIssuesTotalsQuery,
    FindSubjectIssuesTotalsQueryVariables
  >(FindSubjectIssuesTotalsDocument, options);
}
export function useFindSubjectIssuesTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSubjectIssuesTotalsQuery,
    FindSubjectIssuesTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindSubjectIssuesTotalsQuery,
    FindSubjectIssuesTotalsQueryVariables
  >(FindSubjectIssuesTotalsDocument, options);
}
export type FindSubjectIssuesTotalsQueryHookResult = ReturnType<
  typeof useFindSubjectIssuesTotalsQuery
>;
export type FindSubjectIssuesTotalsLazyQueryHookResult = ReturnType<
  typeof useFindSubjectIssuesTotalsLazyQuery
>;
export type FindSubjectIssuesTotalsQueryResult = Apollo.QueryResult<
  FindSubjectIssuesTotalsQuery,
  FindSubjectIssuesTotalsQueryVariables
>;
export const FindSubjectStatusTotalsDocument = gql`
  query FindSubjectStatusTotals(
    $clientIds: [ID!]
    $siteIds: [ID!]
    $studyIds: [ID]
    $uploadIds: [ID]
    $deviceIds: [ID]
    $subjectIds: [ID]
    $sessionIds: [Int]
    $sessionStartDate: DateTime
    $sessionEndDate: DateTime
  ) {
    findSubjectStatusTotals(
      clientIds: $clientIds
      siteIds: $siteIds
      studyIds: $studyIds
      uploadIds: $uploadIds
      deviceIds: $deviceIds
      subjectIds: $subjectIds
      sessionIds: $sessionIds
      sessionStartDate: $sessionStartDate
      sessionEndDate: $sessionEndDate
    ) {
      key
      label
      total
    }
  }
`;

/**
 * __useFindSubjectStatusTotalsQuery__
 *
 * To run a query within a React component, call `useFindSubjectStatusTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSubjectStatusTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSubjectStatusTotalsQuery({
 *   variables: {
 *      clientIds: // value for 'clientIds'
 *      siteIds: // value for 'siteIds'
 *      studyIds: // value for 'studyIds'
 *      uploadIds: // value for 'uploadIds'
 *      deviceIds: // value for 'deviceIds'
 *      subjectIds: // value for 'subjectIds'
 *      sessionIds: // value for 'sessionIds'
 *      sessionStartDate: // value for 'sessionStartDate'
 *      sessionEndDate: // value for 'sessionEndDate'
 *   },
 * });
 */
export function useFindSubjectStatusTotalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindSubjectStatusTotalsQuery,
    FindSubjectStatusTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindSubjectStatusTotalsQuery,
    FindSubjectStatusTotalsQueryVariables
  >(FindSubjectStatusTotalsDocument, options);
}
export function useFindSubjectStatusTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSubjectStatusTotalsQuery,
    FindSubjectStatusTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindSubjectStatusTotalsQuery,
    FindSubjectStatusTotalsQueryVariables
  >(FindSubjectStatusTotalsDocument, options);
}
export type FindSubjectStatusTotalsQueryHookResult = ReturnType<
  typeof useFindSubjectStatusTotalsQuery
>;
export type FindSubjectStatusTotalsLazyQueryHookResult = ReturnType<
  typeof useFindSubjectStatusTotalsLazyQuery
>;
export type FindSubjectStatusTotalsQueryResult = Apollo.QueryResult<
  FindSubjectStatusTotalsQuery,
  FindSubjectStatusTotalsQueryVariables
>;
export const FindSubjectSummariesDocument = gql`
  query FindSubjectSummaries(
    $subjectId: String
    $clientIds: [ID!]
    $siteIds: [ID!]
    $studyIds: [ID]
    $deviceIds: [String]
    $uploadIds: [String]
    $sessionIds: [Int]
    $sessionStartDate: DateTime
    $sessionEndDate: DateTime
    $issueTypes: [DeviceSessionIssueTypeEnum]
    $statuses: [String]
    $active: Boolean
    $pagination: PaginationOpts
    $sort: SortSubjectSummaryInput
  ) {
    findSubjectSummaries(
      subjectId: $subjectId
      clientIds: $clientIds
      siteIds: $siteIds
      studyIds: $studyIds
      deviceIds: $deviceIds
      uploadIds: $uploadIds
      sessionIds: $sessionIds
      sessionStartDate: $sessionStartDate
      sessionEndDate: $sessionEndDate
      issueTypes: $issueTypes
      statuses: $statuses
      active: $active
      pagination: $pagination
      sort: $sort
    ) {
      offset
      limit
      count
      items {
        id
        subjectId
        issues {
          createdAt
          description
          deviceSessionId
          id
          issueType
          resolved
        }
        status {
          key
        }
        sessions {
          id
          index
          deviceId
          siteId
          issues {
            id
            createdAt
            description
            deviceSessionId
            issueType
          }
          status {
            key
          }
          reference {
            prefix
            day
          }
          startDate
          endDate
          deviceStartDate
          deviceEndDate
          selected
          uploadId
          completedTasks
          totalTasks
          hardwareVersion
          firmwareVersion
          deviceStorage
        }
        client {
          name
        }
        dailyReport {
          id
          subject
          data {
            avgHrCoughs
            awakeCoughs
            coughs
            day
            medRespRate
            q1RespRate
            q3RespRate
            steps
          }
          firstDayCoughs
          lastDayCoughs
          coughsChange
          createdAt
        }
        study {
          id
          name
          metrics {
            key
            value
            label
          }
          sessionReferences {
            prefix
            day
            sessionId
          }
        }
        site {
          name
        }
      }
    }
  }
`;

/**
 * __useFindSubjectSummariesQuery__
 *
 * To run a query within a React component, call `useFindSubjectSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSubjectSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSubjectSummariesQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *      clientIds: // value for 'clientIds'
 *      siteIds: // value for 'siteIds'
 *      studyIds: // value for 'studyIds'
 *      deviceIds: // value for 'deviceIds'
 *      uploadIds: // value for 'uploadIds'
 *      sessionIds: // value for 'sessionIds'
 *      sessionStartDate: // value for 'sessionStartDate'
 *      sessionEndDate: // value for 'sessionEndDate'
 *      issueTypes: // value for 'issueTypes'
 *      statuses: // value for 'statuses'
 *      active: // value for 'active'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFindSubjectSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindSubjectSummariesQuery,
    FindSubjectSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindSubjectSummariesQuery,
    FindSubjectSummariesQueryVariables
  >(FindSubjectSummariesDocument, options);
}
export function useFindSubjectSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSubjectSummariesQuery,
    FindSubjectSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindSubjectSummariesQuery,
    FindSubjectSummariesQueryVariables
  >(FindSubjectSummariesDocument, options);
}
export type FindSubjectSummariesQueryHookResult = ReturnType<
  typeof useFindSubjectSummariesQuery
>;
export type FindSubjectSummariesLazyQueryHookResult = ReturnType<
  typeof useFindSubjectSummariesLazyQuery
>;
export type FindSubjectSummariesQueryResult = Apollo.QueryResult<
  FindSubjectSummariesQuery,
  FindSubjectSummariesQueryVariables
>;
export const FindSubjectSummariesStatsDocument = gql`
  query findSubjectSummariesStats(
    $siteIds: [ID!]
    $studyIds: [ID]
    $clientIds: [ID!]
    $statuses: [String]
  ) {
    findSubjectSummariesStats(
      siteIds: $siteIds
      studyIds: $studyIds
      clientIds: $clientIds
      statuses: $statuses
    ) {
      withIssue
      noIssue
      total
      dailyCoughs {
        avgFirstDay
        avgLastDay
        avgChange
      }
      awakeCoughs {
        avgFirstDay
        avgLastDay
        avgChange
      }
    }
  }
`;

/**
 * __useFindSubjectSummariesStatsQuery__
 *
 * To run a query within a React component, call `useFindSubjectSummariesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSubjectSummariesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSubjectSummariesStatsQuery({
 *   variables: {
 *      siteIds: // value for 'siteIds'
 *      studyIds: // value for 'studyIds'
 *      clientIds: // value for 'clientIds'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useFindSubjectSummariesStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindSubjectSummariesStatsQuery,
    FindSubjectSummariesStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindSubjectSummariesStatsQuery,
    FindSubjectSummariesStatsQueryVariables
  >(FindSubjectSummariesStatsDocument, options);
}
export function useFindSubjectSummariesStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSubjectSummariesStatsQuery,
    FindSubjectSummariesStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindSubjectSummariesStatsQuery,
    FindSubjectSummariesStatsQueryVariables
  >(FindSubjectSummariesStatsDocument, options);
}
export type FindSubjectSummariesStatsQueryHookResult = ReturnType<
  typeof useFindSubjectSummariesStatsQuery
>;
export type FindSubjectSummariesStatsLazyQueryHookResult = ReturnType<
  typeof useFindSubjectSummariesStatsLazyQuery
>;
export type FindSubjectSummariesStatsQueryResult = Apollo.QueryResult<
  FindSubjectSummariesStatsQuery,
  FindSubjectSummariesStatsQueryVariables
>;
export const GetLastSubjectCompletedAtDocument = gql`
  query getLastSubjectCompletedAt {
    getLastSubjectCompletedAt
  }
`;

/**
 * __useGetLastSubjectCompletedAtQuery__
 *
 * To run a query within a React component, call `useGetLastSubjectCompletedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastSubjectCompletedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastSubjectCompletedAtQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLastSubjectCompletedAtQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLastSubjectCompletedAtQuery,
    GetLastSubjectCompletedAtQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLastSubjectCompletedAtQuery,
    GetLastSubjectCompletedAtQueryVariables
  >(GetLastSubjectCompletedAtDocument, options);
}
export function useGetLastSubjectCompletedAtLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastSubjectCompletedAtQuery,
    GetLastSubjectCompletedAtQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLastSubjectCompletedAtQuery,
    GetLastSubjectCompletedAtQueryVariables
  >(GetLastSubjectCompletedAtDocument, options);
}
export type GetLastSubjectCompletedAtQueryHookResult = ReturnType<
  typeof useGetLastSubjectCompletedAtQuery
>;
export type GetLastSubjectCompletedAtLazyQueryHookResult = ReturnType<
  typeof useGetLastSubjectCompletedAtLazyQuery
>;
export type GetLastSubjectCompletedAtQueryResult = Apollo.QueryResult<
  GetLastSubjectCompletedAtQuery,
  GetLastSubjectCompletedAtQueryVariables
>;
export const GetManyBatchesDocument = gql`
  query getManyBatches($skip: Int, $limit: Int) {
    getManyBatches(skip: $skip, limit: $limit) {
      results {
        id
        sessionCount
        number
        createdAt
      }
      count
    }
  }
`;

/**
 * __useGetManyBatchesQuery__
 *
 * To run a query within a React component, call `useGetManyBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyBatchesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetManyBatchesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyBatchesQuery,
    GetManyBatchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyBatchesQuery, GetManyBatchesQueryVariables>(
    GetManyBatchesDocument,
    options
  );
}
export function useGetManyBatchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyBatchesQuery,
    GetManyBatchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyBatchesQuery, GetManyBatchesQueryVariables>(
    GetManyBatchesDocument,
    options
  );
}
export type GetManyBatchesQueryHookResult = ReturnType<
  typeof useGetManyBatchesQuery
>;
export type GetManyBatchesLazyQueryHookResult = ReturnType<
  typeof useGetManyBatchesLazyQuery
>;
export type GetManyBatchesQueryResult = Apollo.QueryResult<
  GetManyBatchesQuery,
  GetManyBatchesQueryVariables
>;
export const GetManyDailySubjectReportsDocument = gql`
  query getManyDailySubjectReports($subjectId: ID!) {
    getManyDailySubjectReports(subjectId: $subjectId) {
      id
      subject
      createdAt
      data {
        day
        coughs
        avgHrCoughs
        awakeCoughs
        medRespRate
        q3RespRate
        steps
      }
    }
  }
`;

/**
 * __useGetManyDailySubjectReportsQuery__
 *
 * To run a query within a React component, call `useGetManyDailySubjectReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyDailySubjectReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyDailySubjectReportsQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useGetManyDailySubjectReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManyDailySubjectReportsQuery,
    GetManyDailySubjectReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyDailySubjectReportsQuery,
    GetManyDailySubjectReportsQueryVariables
  >(GetManyDailySubjectReportsDocument, options);
}
export function useGetManyDailySubjectReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyDailySubjectReportsQuery,
    GetManyDailySubjectReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyDailySubjectReportsQuery,
    GetManyDailySubjectReportsQueryVariables
  >(GetManyDailySubjectReportsDocument, options);
}
export type GetManyDailySubjectReportsQueryHookResult = ReturnType<
  typeof useGetManyDailySubjectReportsQuery
>;
export type GetManyDailySubjectReportsLazyQueryHookResult = ReturnType<
  typeof useGetManyDailySubjectReportsLazyQuery
>;
export type GetManyDailySubjectReportsQueryResult = Apollo.QueryResult<
  GetManyDailySubjectReportsQuery,
  GetManyDailySubjectReportsQueryVariables
>;
export const GetManyDeviceEventsDocument = gql`
  query getManyDeviceEvents(
    $organizationIds: [ID!]
    $trialIds: [ID!]
    $siteIds: [ID!]
    $active: Boolean
    $sessionStatuses: [Int!]
    $annotationStatuses: [DeviceAnnotationStatusEnum!]
    $sessionId: String
    $deviceId: String
  ) {
    getManyDeviceEvents(
      organizationIds: $organizationIds
      trialIds: $trialIds
      siteIds: $siteIds
      active: $active
      sessionStatuses: $sessionStatuses
      annotationStatuses: $annotationStatuses
      sessionId: $sessionId
      deviceId: $deviceId
    ) {
      results {
        id
        sessionId
        timestamp
      }
      count
    }
  }
`;

/**
 * __useGetManyDeviceEventsQuery__
 *
 * To run a query within a React component, call `useGetManyDeviceEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyDeviceEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyDeviceEventsQuery({
 *   variables: {
 *      organizationIds: // value for 'organizationIds'
 *      trialIds: // value for 'trialIds'
 *      siteIds: // value for 'siteIds'
 *      active: // value for 'active'
 *      sessionStatuses: // value for 'sessionStatuses'
 *      annotationStatuses: // value for 'annotationStatuses'
 *      sessionId: // value for 'sessionId'
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGetManyDeviceEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyDeviceEventsQuery,
    GetManyDeviceEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyDeviceEventsQuery,
    GetManyDeviceEventsQueryVariables
  >(GetManyDeviceEventsDocument, options);
}
export function useGetManyDeviceEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyDeviceEventsQuery,
    GetManyDeviceEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyDeviceEventsQuery,
    GetManyDeviceEventsQueryVariables
  >(GetManyDeviceEventsDocument, options);
}
export type GetManyDeviceEventsQueryHookResult = ReturnType<
  typeof useGetManyDeviceEventsQuery
>;
export type GetManyDeviceEventsLazyQueryHookResult = ReturnType<
  typeof useGetManyDeviceEventsLazyQuery
>;
export type GetManyDeviceEventsQueryResult = Apollo.QueryResult<
  GetManyDeviceEventsQuery,
  GetManyDeviceEventsQueryVariables
>;
export const GetManyDeviceSessionsDocument = gql`
  query getManyDeviceSessions(
    $organizationIds: [ID!]
    $trialIds: [ID!]
    $siteIds: [ID!]
    $batchIds: [ID!]
    $sessionStatuses: [Int!]
    $annotationStatuses: [DeviceAnnotationStatusEnum!]
    $issueStatuses: [DeviceSessionIssueTypeEnum!]
    $sessionId: String
    $deviceId: String
    $active: Boolean
    $pagination: PaginationOpts
    $sort: [SortSessionDeviceInput]
  ) {
    getManyDeviceSessions(
      organizationIds: $organizationIds
      trialIds: $trialIds
      siteIds: $siteIds
      batchIds: $batchIds
      sessionStatuses: $sessionStatuses
      annotationStatuses: $annotationStatuses
      issueStatuses: $issueStatuses
      sessionId: $sessionId
      deviceId: $deviceId
      active: $active
      pagination: $pagination
      sort: $sort
    ) {
      count
      results {
        id
        sessionId
        deviceId
        subjectName
        organization {
          id
          name
        }
        trial {
          id
          name
          metrics {
            key
            label
            value
          }
        }
        site {
          id
          name
        }
        siteId
        trialId
        status
        analysisStatus {
          id
          label
        }
        sessionSummaries {
          studyDay
          dataPoints {
            id
            label
            data {
              id
              label
              values
            }
          }
        }
        issues {
          id
          issueType
          description
          createdAt
        }
        firstDay
        lastDay
        change
        startDate
      }
    }
  }
`;

/**
 * __useGetManyDeviceSessionsQuery__
 *
 * To run a query within a React component, call `useGetManyDeviceSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyDeviceSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyDeviceSessionsQuery({
 *   variables: {
 *      organizationIds: // value for 'organizationIds'
 *      trialIds: // value for 'trialIds'
 *      siteIds: // value for 'siteIds'
 *      batchIds: // value for 'batchIds'
 *      sessionStatuses: // value for 'sessionStatuses'
 *      annotationStatuses: // value for 'annotationStatuses'
 *      issueStatuses: // value for 'issueStatuses'
 *      sessionId: // value for 'sessionId'
 *      deviceId: // value for 'deviceId'
 *      active: // value for 'active'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetManyDeviceSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyDeviceSessionsQuery,
    GetManyDeviceSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyDeviceSessionsQuery,
    GetManyDeviceSessionsQueryVariables
  >(GetManyDeviceSessionsDocument, options);
}
export function useGetManyDeviceSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyDeviceSessionsQuery,
    GetManyDeviceSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyDeviceSessionsQuery,
    GetManyDeviceSessionsQueryVariables
  >(GetManyDeviceSessionsDocument, options);
}
export type GetManyDeviceSessionsQueryHookResult = ReturnType<
  typeof useGetManyDeviceSessionsQuery
>;
export type GetManyDeviceSessionsLazyQueryHookResult = ReturnType<
  typeof useGetManyDeviceSessionsLazyQuery
>;
export type GetManyDeviceSessionsQueryResult = Apollo.QueryResult<
  GetManyDeviceSessionsQuery,
  GetManyDeviceSessionsQueryVariables
>;
export const GetManyDevicesDocument = gql`
  query getManyDevices($searchText: String, $deviceStatus: DeviceStatusEnum) {
    getManyDevices(searchText: $searchText, deviceStatus: $deviceStatus) {
      results {
        id
        deviceId
      }
      count
    }
  }
`;

/**
 * __useGetManyDevicesQuery__
 *
 * To run a query within a React component, call `useGetManyDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyDevicesQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      deviceStatus: // value for 'deviceStatus'
 *   },
 * });
 */
export function useGetManyDevicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyDevicesQuery,
    GetManyDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyDevicesQuery, GetManyDevicesQueryVariables>(
    GetManyDevicesDocument,
    options
  );
}
export function useGetManyDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyDevicesQuery,
    GetManyDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyDevicesQuery, GetManyDevicesQueryVariables>(
    GetManyDevicesDocument,
    options
  );
}
export type GetManyDevicesQueryHookResult = ReturnType<
  typeof useGetManyDevicesQuery
>;
export type GetManyDevicesLazyQueryHookResult = ReturnType<
  typeof useGetManyDevicesLazyQuery
>;
export type GetManyDevicesQueryResult = Apollo.QueryResult<
  GetManyDevicesQuery,
  GetManyDevicesQueryVariables
>;
export const GetManyOrganizationsDocument = gql`
  query getManyOrganizations {
    getManyOrganizations {
      id
      name
    }
  }
`;

/**
 * __useGetManyOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetManyOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyOrganizationsQuery,
    GetManyOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyOrganizationsQuery,
    GetManyOrganizationsQueryVariables
  >(GetManyOrganizationsDocument, options);
}
export function useGetManyOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyOrganizationsQuery,
    GetManyOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyOrganizationsQuery,
    GetManyOrganizationsQueryVariables
  >(GetManyOrganizationsDocument, options);
}
export type GetManyOrganizationsQueryHookResult = ReturnType<
  typeof useGetManyOrganizationsQuery
>;
export type GetManyOrganizationsLazyQueryHookResult = ReturnType<
  typeof useGetManyOrganizationsLazyQuery
>;
export type GetManyOrganizationsQueryResult = Apollo.QueryResult<
  GetManyOrganizationsQuery,
  GetManyOrganizationsQueryVariables
>;
export const GetManySessionStatusesDocument = gql`
  query getManySessionStatuses {
    getManySessionStatuses {
      id
      label
      key
    }
  }
`;

/**
 * __useGetManySessionStatusesQuery__
 *
 * To run a query within a React component, call `useGetManySessionStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManySessionStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManySessionStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManySessionStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManySessionStatusesQuery,
    GetManySessionStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManySessionStatusesQuery,
    GetManySessionStatusesQueryVariables
  >(GetManySessionStatusesDocument, options);
}
export function useGetManySessionStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManySessionStatusesQuery,
    GetManySessionStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManySessionStatusesQuery,
    GetManySessionStatusesQueryVariables
  >(GetManySessionStatusesDocument, options);
}
export type GetManySessionStatusesQueryHookResult = ReturnType<
  typeof useGetManySessionStatusesQuery
>;
export type GetManySessionStatusesLazyQueryHookResult = ReturnType<
  typeof useGetManySessionStatusesLazyQuery
>;
export type GetManySessionStatusesQueryResult = Apollo.QueryResult<
  GetManySessionStatusesQuery,
  GetManySessionStatusesQueryVariables
>;
export const GetManySessionSummariesDocument = gql`
  query getManySessionSummaries(
    $clientIds: [ID!]
    $siteIds: [ID!]
    $studyIds: [ID]
    $batchIds: [ID!]
    $issueStatuses: [DeviceSessionIssueTypeEnum!]
    $sessionId: String
    $deviceId: String
    $active: Boolean
    $pagination: PaginationOpts
    $sort: [SortSessionDeviceInput]
  ) {
    getManySessionSummaries(
      clientIds: $clientIds
      studyIds: $studyIds
      siteIds: $siteIds
      batchIds: $batchIds
      issueStatuses: $issueStatuses
      sessionId: $sessionId
      deviceId: $deviceId
      active: $active
      pagination: $pagination
      sort: $sort
    ) {
      count
      results {
        id
      }
    }
  }
`;

/**
 * __useGetManySessionSummariesQuery__
 *
 * To run a query within a React component, call `useGetManySessionSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManySessionSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManySessionSummariesQuery({
 *   variables: {
 *      clientIds: // value for 'clientIds'
 *      siteIds: // value for 'siteIds'
 *      studyIds: // value for 'studyIds'
 *      batchIds: // value for 'batchIds'
 *      issueStatuses: // value for 'issueStatuses'
 *      sessionId: // value for 'sessionId'
 *      deviceId: // value for 'deviceId'
 *      active: // value for 'active'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetManySessionSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManySessionSummariesQuery,
    GetManySessionSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManySessionSummariesQuery,
    GetManySessionSummariesQueryVariables
  >(GetManySessionSummariesDocument, options);
}
export function useGetManySessionSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManySessionSummariesQuery,
    GetManySessionSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManySessionSummariesQuery,
    GetManySessionSummariesQueryVariables
  >(GetManySessionSummariesDocument, options);
}
export type GetManySessionSummariesQueryHookResult = ReturnType<
  typeof useGetManySessionSummariesQuery
>;
export type GetManySessionSummariesLazyQueryHookResult = ReturnType<
  typeof useGetManySessionSummariesLazyQuery
>;
export type GetManySessionSummariesQueryResult = Apollo.QueryResult<
  GetManySessionSummariesQuery,
  GetManySessionSummariesQueryVariables
>;
export const GetManySitesDocument = gql`
  query getManySites($trialIds: [ID], $organizationIds: [ID]) {
    getManySites(trialIds: $trialIds, organizationIds: $organizationIds) {
      id
      name
      city
      country
      contactName
      phone
      state
      streetAddress1
      streetAddress2
      zipCode
      email
      studies {
        id
        name
      }
    }
  }
`;

/**
 * __useGetManySitesQuery__
 *
 * To run a query within a React component, call `useGetManySitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManySitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManySitesQuery({
 *   variables: {
 *      trialIds: // value for 'trialIds'
 *      organizationIds: // value for 'organizationIds'
 *   },
 * });
 */
export function useGetManySitesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManySitesQuery,
    GetManySitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManySitesQuery, GetManySitesQueryVariables>(
    GetManySitesDocument,
    options
  );
}
export function useGetManySitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManySitesQuery,
    GetManySitesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManySitesQuery, GetManySitesQueryVariables>(
    GetManySitesDocument,
    options
  );
}
export type GetManySitesQueryHookResult = ReturnType<
  typeof useGetManySitesQuery
>;
export type GetManySitesLazyQueryHookResult = ReturnType<
  typeof useGetManySitesLazyQuery
>;
export type GetManySitesQueryResult = Apollo.QueryResult<
  GetManySitesQuery,
  GetManySitesQueryVariables
>;
export const GetManySubjectsDocument = gql`
  query getManySubjects(
    $trialIds: [ID]
    $siteIds: [ID]
    $organizationIds: [ID]
    $searchText: String
  ) {
    getManySubjects(
      trialIds: $trialIds
      siteIds: $siteIds
      organizationIds: $organizationIds
      searchText: $searchText
    ) {
      id
      name
      trial {
        id
        name
      }
      site {
        id
        name
      }
    }
  }
`;

/**
 * __useGetManySubjectsQuery__
 *
 * To run a query within a React component, call `useGetManySubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManySubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManySubjectsQuery({
 *   variables: {
 *      trialIds: // value for 'trialIds'
 *      siteIds: // value for 'siteIds'
 *      organizationIds: // value for 'organizationIds'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetManySubjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManySubjectsQuery,
    GetManySubjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManySubjectsQuery, GetManySubjectsQueryVariables>(
    GetManySubjectsDocument,
    options
  );
}
export function useGetManySubjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManySubjectsQuery,
    GetManySubjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManySubjectsQuery,
    GetManySubjectsQueryVariables
  >(GetManySubjectsDocument, options);
}
export type GetManySubjectsQueryHookResult = ReturnType<
  typeof useGetManySubjectsQuery
>;
export type GetManySubjectsLazyQueryHookResult = ReturnType<
  typeof useGetManySubjectsLazyQuery
>;
export type GetManySubjectsQueryResult = Apollo.QueryResult<
  GetManySubjectsQuery,
  GetManySubjectsQueryVariables
>;
export const GetManyTrialsDocument = gql`
  query getManyTrials($organizationIds: [ID], $active: Boolean) {
    getManyTrials(organizationIds: $organizationIds, active: $active) {
      ...trialFragment
    }
  }
  ${TrialFragmentFragmentDoc}
`;

/**
 * __useGetManyTrialsQuery__
 *
 * To run a query within a React component, call `useGetManyTrialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyTrialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyTrialsQuery({
 *   variables: {
 *      organizationIds: // value for 'organizationIds'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetManyTrialsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyTrialsQuery,
    GetManyTrialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyTrialsQuery, GetManyTrialsQueryVariables>(
    GetManyTrialsDocument,
    options
  );
}
export function useGetManyTrialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyTrialsQuery,
    GetManyTrialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyTrialsQuery, GetManyTrialsQueryVariables>(
    GetManyTrialsDocument,
    options
  );
}
export type GetManyTrialsQueryHookResult = ReturnType<
  typeof useGetManyTrialsQuery
>;
export type GetManyTrialsLazyQueryHookResult = ReturnType<
  typeof useGetManyTrialsLazyQuery
>;
export type GetManyTrialsQueryResult = Apollo.QueryResult<
  GetManyTrialsQuery,
  GetManyTrialsQueryVariables
>;
export const GetManyUsersDocument = gql`
  query getManyUsers(
    $userType: UserTypeEnum
    $deactivated: Boolean
    $organizationId: ID
  ) {
    getManyUsers(
      userType: $userType
      deactivated: $deactivated
      organizationId: $organizationId
    ) {
      id
      email
      firstName
      lastName
      acceptedInvite
      recentlySentInvite
      deactivated
      trials {
        id
        name
      }
    }
  }
`;

/**
 * __useGetManyUsersQuery__
 *
 * To run a query within a React component, call `useGetManyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyUsersQuery({
 *   variables: {
 *      userType: // value for 'userType'
 *      deactivated: // value for 'deactivated'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetManyUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyUsersQuery,
    GetManyUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyUsersQuery, GetManyUsersQueryVariables>(
    GetManyUsersDocument,
    options
  );
}
export function useGetManyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyUsersQuery,
    GetManyUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyUsersQuery, GetManyUsersQueryVariables>(
    GetManyUsersDocument,
    options
  );
}
export type GetManyUsersQueryHookResult = ReturnType<
  typeof useGetManyUsersQuery
>;
export type GetManyUsersLazyQueryHookResult = ReturnType<
  typeof useGetManyUsersLazyQuery
>;
export type GetManyUsersQueryResult = Apollo.QueryResult<
  GetManyUsersQuery,
  GetManyUsersQueryVariables
>;
export const GetOneBatchDocument = gql`
  query getOneBatch($id: ID!) {
    getOneBatch(id: $id) {
      id
      number
      deviceSessions {
        results {
          id
          sessionId
          deviceId
          status
          subjectName
          sessionStatus {
            id
            key
            label
          }
          organization {
            id
            name
          }
          site {
            id
            name
          }
          trial {
            id
            name
          }
        }
        count
      }
    }
  }
`;

/**
 * __useGetOneBatchQuery__
 *
 * To run a query within a React component, call `useGetOneBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneBatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneBatchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneBatchQuery,
    GetOneBatchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneBatchQuery, GetOneBatchQueryVariables>(
    GetOneBatchDocument,
    options
  );
}
export function useGetOneBatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneBatchQuery,
    GetOneBatchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneBatchQuery, GetOneBatchQueryVariables>(
    GetOneBatchDocument,
    options
  );
}
export type GetOneBatchQueryHookResult = ReturnType<typeof useGetOneBatchQuery>;
export type GetOneBatchLazyQueryHookResult = ReturnType<
  typeof useGetOneBatchLazyQuery
>;
export type GetOneBatchQueryResult = Apollo.QueryResult<
  GetOneBatchQuery,
  GetOneBatchQueryVariables
>;
export const GetOneOrganizationDocument = gql`
  query getOneOrganization($id: ID!) {
    getOneOrganization(id: $id) {
      ...organizationFragment
    }
  }
  ${OrganizationFragmentFragmentDoc}
`;

/**
 * __useGetOneOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOneOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneOrganizationQuery,
    GetOneOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOneOrganizationQuery,
    GetOneOrganizationQueryVariables
  >(GetOneOrganizationDocument, options);
}
export function useGetOneOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneOrganizationQuery,
    GetOneOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOneOrganizationQuery,
    GetOneOrganizationQueryVariables
  >(GetOneOrganizationDocument, options);
}
export type GetOneOrganizationQueryHookResult = ReturnType<
  typeof useGetOneOrganizationQuery
>;
export type GetOneOrganizationLazyQueryHookResult = ReturnType<
  typeof useGetOneOrganizationLazyQuery
>;
export type GetOneOrganizationQueryResult = Apollo.QueryResult<
  GetOneOrganizationQuery,
  GetOneOrganizationQueryVariables
>;
export const GetOneTrialDocument = gql`
  query getOneTrial($trialId: ID!) {
    getOneTrial(trialId: $trialId) {
      ...trialFragment
    }
  }
  ${TrialFragmentFragmentDoc}
`;

/**
 * __useGetOneTrialQuery__
 *
 * To run a query within a React component, call `useGetOneTrialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneTrialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneTrialQuery({
 *   variables: {
 *      trialId: // value for 'trialId'
 *   },
 * });
 */
export function useGetOneTrialQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneTrialQuery,
    GetOneTrialQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneTrialQuery, GetOneTrialQueryVariables>(
    GetOneTrialDocument,
    options
  );
}
export function useGetOneTrialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneTrialQuery,
    GetOneTrialQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneTrialQuery, GetOneTrialQueryVariables>(
    GetOneTrialDocument,
    options
  );
}
export type GetOneTrialQueryHookResult = ReturnType<typeof useGetOneTrialQuery>;
export type GetOneTrialLazyQueryHookResult = ReturnType<
  typeof useGetOneTrialLazyQuery
>;
export type GetOneTrialQueryResult = Apollo.QueryResult<
  GetOneTrialQuery,
  GetOneTrialQueryVariables
>;
export const GetOneUserDocument = gql`
  query getOneUser($id: ID!) {
    getOneUser(id: $id) {
      id
      email
      firstName
      lastName
      userType
      permission
      organizations {
        id
        name
      }
    }
  }
`;

/**
 * __useGetOneUserQuery__
 *
 * To run a query within a React component, call `useGetOneUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneUserQuery,
    GetOneUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneUserQuery, GetOneUserQueryVariables>(
    GetOneUserDocument,
    options
  );
}
export function useGetOneUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneUserQuery,
    GetOneUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneUserQuery, GetOneUserQueryVariables>(
    GetOneUserDocument,
    options
  );
}
export type GetOneUserQueryHookResult = ReturnType<typeof useGetOneUserQuery>;
export type GetOneUserLazyQueryHookResult = ReturnType<
  typeof useGetOneUserLazyQuery
>;
export type GetOneUserQueryResult = Apollo.QueryResult<
  GetOneUserQuery,
  GetOneUserQueryVariables
>;
export const GetOtherDataDocument = gql`
  query getOtherData(
    $unit: OtherDataUnitEnum!
    $organizationIds: [ID!]
    $sessionStatuses: [Int!]
    $sessionId: String
  ) {
    getOtherData(
      unit: $unit
      organizationIds: $organizationIds
      sessionStatuses: $sessionStatuses
      sessionId: $sessionId
    ) {
      unit
      sessionId
      deviceId
      firstDay
      lastDay
      change
      label
      dailyEvents {
        day
        value
      }
    }
  }
`;

/**
 * __useGetOtherDataQuery__
 *
 * To run a query within a React component, call `useGetOtherDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtherDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtherDataQuery({
 *   variables: {
 *      unit: // value for 'unit'
 *      organizationIds: // value for 'organizationIds'
 *      sessionStatuses: // value for 'sessionStatuses'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetOtherDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOtherDataQuery,
    GetOtherDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOtherDataQuery, GetOtherDataQueryVariables>(
    GetOtherDataDocument,
    options
  );
}
export function useGetOtherDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOtherDataQuery,
    GetOtherDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOtherDataQuery, GetOtherDataQueryVariables>(
    GetOtherDataDocument,
    options
  );
}
export type GetOtherDataQueryHookResult = ReturnType<
  typeof useGetOtherDataQuery
>;
export type GetOtherDataLazyQueryHookResult = ReturnType<
  typeof useGetOtherDataLazyQuery
>;
export type GetOtherDataQueryResult = Apollo.QueryResult<
  GetOtherDataQuery,
  GetOtherDataQueryVariables
>;
export const GetSelectedReportDocument = gql`
  query getSelectedReport($subjectId: ID!) {
    getSelectedReport(subjectId: $subjectId) {
      id
    }
  }
`;

/**
 * __useGetSelectedReportQuery__
 *
 * To run a query within a React component, call `useGetSelectedReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedReportQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useGetSelectedReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSelectedReportQuery,
    GetSelectedReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSelectedReportQuery,
    GetSelectedReportQueryVariables
  >(GetSelectedReportDocument, options);
}
export function useGetSelectedReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSelectedReportQuery,
    GetSelectedReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSelectedReportQuery,
    GetSelectedReportQueryVariables
  >(GetSelectedReportDocument, options);
}
export type GetSelectedReportQueryHookResult = ReturnType<
  typeof useGetSelectedReportQuery
>;
export type GetSelectedReportLazyQueryHookResult = ReturnType<
  typeof useGetSelectedReportLazyQuery
>;
export type GetSelectedReportQueryResult = Apollo.QueryResult<
  GetSelectedReportQuery,
  GetSelectedReportQueryVariables
>;
export const GetSessionLogsDocument = gql`
  query GetSessionLogs($deviceSessionId: ID!) {
    getSessionLogs(deviceSessionId: $deviceSessionId) {
      id
      deviceSessionId
      changedById
      category
      createdAt
    }
  }
`;

/**
 * __useGetSessionLogsQuery__
 *
 * To run a query within a React component, call `useGetSessionLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionLogsQuery({
 *   variables: {
 *      deviceSessionId: // value for 'deviceSessionId'
 *   },
 * });
 */
export function useGetSessionLogsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSessionLogsQuery,
    GetSessionLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSessionLogsQuery, GetSessionLogsQueryVariables>(
    GetSessionLogsDocument,
    options
  );
}
export function useGetSessionLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSessionLogsQuery,
    GetSessionLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSessionLogsQuery, GetSessionLogsQueryVariables>(
    GetSessionLogsDocument,
    options
  );
}
export type GetSessionLogsQueryHookResult = ReturnType<
  typeof useGetSessionLogsQuery
>;
export type GetSessionLogsLazyQueryHookResult = ReturnType<
  typeof useGetSessionLogsLazyQuery
>;
export type GetSessionLogsQueryResult = Apollo.QueryResult<
  GetSessionLogsQuery,
  GetSessionLogsQueryVariables
>;
export const GetSessionReportFilesDocument = gql`
  query GetSessionReportFiles($sessionId: ID!) {
    getSessionReportFiles(sessionId: $sessionId) {
      fileName
      content
    }
  }
`;

/**
 * __useGetSessionReportFilesQuery__
 *
 * To run a query within a React component, call `useGetSessionReportFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionReportFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionReportFilesQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetSessionReportFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSessionReportFilesQuery,
    GetSessionReportFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSessionReportFilesQuery,
    GetSessionReportFilesQueryVariables
  >(GetSessionReportFilesDocument, options);
}
export function useGetSessionReportFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSessionReportFilesQuery,
    GetSessionReportFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSessionReportFilesQuery,
    GetSessionReportFilesQueryVariables
  >(GetSessionReportFilesDocument, options);
}
export type GetSessionReportFilesQueryHookResult = ReturnType<
  typeof useGetSessionReportFilesQuery
>;
export type GetSessionReportFilesLazyQueryHookResult = ReturnType<
  typeof useGetSessionReportFilesLazyQuery
>;
export type GetSessionReportFilesQueryResult = Apollo.QueryResult<
  GetSessionReportFilesQuery,
  GetSessionReportFilesQueryVariables
>;
export const GetStats_24HourCoughsDocument = gql`
  query getStats_24HourCoughs {
    getStats_24HourCoughs {
      change
      start
      end
    }
  }
`;

/**
 * __useGetStats_24HourCoughsQuery__
 *
 * To run a query within a React component, call `useGetStats_24HourCoughsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStats_24HourCoughsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStats_24HourCoughsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStats_24HourCoughsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStats_24HourCoughsQuery,
    GetStats_24HourCoughsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStats_24HourCoughsQuery,
    GetStats_24HourCoughsQueryVariables
  >(GetStats_24HourCoughsDocument, options);
}
export function useGetStats_24HourCoughsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStats_24HourCoughsQuery,
    GetStats_24HourCoughsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStats_24HourCoughsQuery,
    GetStats_24HourCoughsQueryVariables
  >(GetStats_24HourCoughsDocument, options);
}
export type GetStats_24HourCoughsQueryHookResult = ReturnType<
  typeof useGetStats_24HourCoughsQuery
>;
export type GetStats_24HourCoughsLazyQueryHookResult = ReturnType<
  typeof useGetStats_24HourCoughsLazyQuery
>;
export type GetStats_24HourCoughsQueryResult = Apollo.QueryResult<
  GetStats_24HourCoughsQuery,
  GetStats_24HourCoughsQueryVariables
>;
export const GetStats_ClientSessionStatusDocument = gql`
  query getStats_ClientSessionStatus($siteIds: [ID!], $trialIds: [ID!]) {
    getStats_ClientSessionStatus(siteIds: $siteIds, trialIds: $trialIds) {
      clean
      withIssue
      total
    }
  }
`;

/**
 * __useGetStats_ClientSessionStatusQuery__
 *
 * To run a query within a React component, call `useGetStats_ClientSessionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStats_ClientSessionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStats_ClientSessionStatusQuery({
 *   variables: {
 *      siteIds: // value for 'siteIds'
 *      trialIds: // value for 'trialIds'
 *   },
 * });
 */
export function useGetStats_ClientSessionStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStats_ClientSessionStatusQuery,
    GetStats_ClientSessionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStats_ClientSessionStatusQuery,
    GetStats_ClientSessionStatusQueryVariables
  >(GetStats_ClientSessionStatusDocument, options);
}
export function useGetStats_ClientSessionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStats_ClientSessionStatusQuery,
    GetStats_ClientSessionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStats_ClientSessionStatusQuery,
    GetStats_ClientSessionStatusQueryVariables
  >(GetStats_ClientSessionStatusDocument, options);
}
export type GetStats_ClientSessionStatusQueryHookResult = ReturnType<
  typeof useGetStats_ClientSessionStatusQuery
>;
export type GetStats_ClientSessionStatusLazyQueryHookResult = ReturnType<
  typeof useGetStats_ClientSessionStatusLazyQuery
>;
export type GetStats_ClientSessionStatusQueryResult = Apollo.QueryResult<
  GetStats_ClientSessionStatusQuery,
  GetStats_ClientSessionStatusQueryVariables
>;
export const GetStats_ClientWakingCoughsDocument = gql`
  query getStats_ClientWakingCoughs {
    getStats_ClientWakingCoughs {
      change
      start
      end
    }
  }
`;

/**
 * __useGetStats_ClientWakingCoughsQuery__
 *
 * To run a query within a React component, call `useGetStats_ClientWakingCoughsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStats_ClientWakingCoughsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStats_ClientWakingCoughsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStats_ClientWakingCoughsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStats_ClientWakingCoughsQuery,
    GetStats_ClientWakingCoughsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStats_ClientWakingCoughsQuery,
    GetStats_ClientWakingCoughsQueryVariables
  >(GetStats_ClientWakingCoughsDocument, options);
}
export function useGetStats_ClientWakingCoughsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStats_ClientWakingCoughsQuery,
    GetStats_ClientWakingCoughsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStats_ClientWakingCoughsQuery,
    GetStats_ClientWakingCoughsQueryVariables
  >(GetStats_ClientWakingCoughsDocument, options);
}
export type GetStats_ClientWakingCoughsQueryHookResult = ReturnType<
  typeof useGetStats_ClientWakingCoughsQuery
>;
export type GetStats_ClientWakingCoughsLazyQueryHookResult = ReturnType<
  typeof useGetStats_ClientWakingCoughsLazyQuery
>;
export type GetStats_ClientWakingCoughsQueryResult = Apollo.QueryResult<
  GetStats_ClientWakingCoughsQuery,
  GetStats_ClientWakingCoughsQueryVariables
>;
export const GetStats_DeviceSessionAnnotationStatusesDocument = gql`
  query getStats_DeviceSessionAnnotationStatuses(
    $organizationIds: [ID!]
    $siteIds: [ID!]
    $trialIds: [ID!]
    $sessionStatuses: [ID!]
    $issueStatuses: [DeviceSessionIssueTypeEnum!]
    $active: Boolean
  ) {
    getStats_DeviceSessionAnnotationStatuses(
      organizationIds: $organizationIds
      siteIds: $siteIds
      trialIds: $trialIds
      sessionStatuses: $sessionStatuses
      issueStatuses: $issueStatuses
      active: $active
    ) {
      label
      value
      key
      id
      order
    }
  }
`;

/**
 * __useGetStats_DeviceSessionAnnotationStatusesQuery__
 *
 * To run a query within a React component, call `useGetStats_DeviceSessionAnnotationStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStats_DeviceSessionAnnotationStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStats_DeviceSessionAnnotationStatusesQuery({
 *   variables: {
 *      organizationIds: // value for 'organizationIds'
 *      siteIds: // value for 'siteIds'
 *      trialIds: // value for 'trialIds'
 *      sessionStatuses: // value for 'sessionStatuses'
 *      issueStatuses: // value for 'issueStatuses'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetStats_DeviceSessionAnnotationStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStats_DeviceSessionAnnotationStatusesQuery,
    GetStats_DeviceSessionAnnotationStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStats_DeviceSessionAnnotationStatusesQuery,
    GetStats_DeviceSessionAnnotationStatusesQueryVariables
  >(GetStats_DeviceSessionAnnotationStatusesDocument, options);
}
export function useGetStats_DeviceSessionAnnotationStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStats_DeviceSessionAnnotationStatusesQuery,
    GetStats_DeviceSessionAnnotationStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStats_DeviceSessionAnnotationStatusesQuery,
    GetStats_DeviceSessionAnnotationStatusesQueryVariables
  >(GetStats_DeviceSessionAnnotationStatusesDocument, options);
}
export type GetStats_DeviceSessionAnnotationStatusesQueryHookResult =
  ReturnType<typeof useGetStats_DeviceSessionAnnotationStatusesQuery>;
export type GetStats_DeviceSessionAnnotationStatusesLazyQueryHookResult =
  ReturnType<typeof useGetStats_DeviceSessionAnnotationStatusesLazyQuery>;
export type GetStats_DeviceSessionAnnotationStatusesQueryResult =
  Apollo.QueryResult<
    GetStats_DeviceSessionAnnotationStatusesQuery,
    GetStats_DeviceSessionAnnotationStatusesQueryVariables
  >;
export const GetStats_DeviceSessionsStatusesDocument = gql`
  query getStats_DeviceSessionsStatuses(
    $organizationIds: [ID!]
    $siteIds: [ID!]
    $trialIds: [ID!]
    $issueStatuses: [DeviceSessionIssueTypeEnum!]
    $annotationStatuses: [ID!]
    $active: Boolean
  ) {
    getStats_DeviceSessionsStatuses(
      organizationIds: $organizationIds
      siteIds: $siteIds
      trialIds: $trialIds
      issueStatuses: $issueStatuses
      annotationStatuses: $annotationStatuses
      active: $active
    ) {
      label
      value
      key
      id
      order
    }
  }
`;

/**
 * __useGetStats_DeviceSessionsStatusesQuery__
 *
 * To run a query within a React component, call `useGetStats_DeviceSessionsStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStats_DeviceSessionsStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStats_DeviceSessionsStatusesQuery({
 *   variables: {
 *      organizationIds: // value for 'organizationIds'
 *      siteIds: // value for 'siteIds'
 *      trialIds: // value for 'trialIds'
 *      issueStatuses: // value for 'issueStatuses'
 *      annotationStatuses: // value for 'annotationStatuses'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetStats_DeviceSessionsStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStats_DeviceSessionsStatusesQuery,
    GetStats_DeviceSessionsStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStats_DeviceSessionsStatusesQuery,
    GetStats_DeviceSessionsStatusesQueryVariables
  >(GetStats_DeviceSessionsStatusesDocument, options);
}
export function useGetStats_DeviceSessionsStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStats_DeviceSessionsStatusesQuery,
    GetStats_DeviceSessionsStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStats_DeviceSessionsStatusesQuery,
    GetStats_DeviceSessionsStatusesQueryVariables
  >(GetStats_DeviceSessionsStatusesDocument, options);
}
export type GetStats_DeviceSessionsStatusesQueryHookResult = ReturnType<
  typeof useGetStats_DeviceSessionsStatusesQuery
>;
export type GetStats_DeviceSessionsStatusesLazyQueryHookResult = ReturnType<
  typeof useGetStats_DeviceSessionsStatusesLazyQuery
>;
export type GetStats_DeviceSessionsStatusesQueryResult = Apollo.QueryResult<
  GetStats_DeviceSessionsStatusesQuery,
  GetStats_DeviceSessionsStatusesQueryVariables
>;
export const GetStats_IssuesDocument = gql`
  query getStats_Issues(
    $organizationIds: [ID!]
    $siteIds: [ID!]
    $trialIds: [ID!]
    $annotationStatuses: [ID!]
    $sessionStatuses: [ID!]
    $active: Boolean
  ) {
    getStats_Issues(
      organizationIds: $organizationIds
      siteIds: $siteIds
      trialIds: $trialIds
      annotationStatuses: $annotationStatuses
      sessionStatuses: $sessionStatuses
      active: $active
    ) {
      client_reported
      participant_reported
      device_issue
      other_issue
    }
  }
`;

/**
 * __useGetStats_IssuesQuery__
 *
 * To run a query within a React component, call `useGetStats_IssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStats_IssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStats_IssuesQuery({
 *   variables: {
 *      organizationIds: // value for 'organizationIds'
 *      siteIds: // value for 'siteIds'
 *      trialIds: // value for 'trialIds'
 *      annotationStatuses: // value for 'annotationStatuses'
 *      sessionStatuses: // value for 'sessionStatuses'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetStats_IssuesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStats_IssuesQuery,
    GetStats_IssuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStats_IssuesQuery, GetStats_IssuesQueryVariables>(
    GetStats_IssuesDocument,
    options
  );
}
export function useGetStats_IssuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStats_IssuesQuery,
    GetStats_IssuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStats_IssuesQuery,
    GetStats_IssuesQueryVariables
  >(GetStats_IssuesDocument, options);
}
export type GetStats_IssuesQueryHookResult = ReturnType<
  typeof useGetStats_IssuesQuery
>;
export type GetStats_IssuesLazyQueryHookResult = ReturnType<
  typeof useGetStats_IssuesLazyQuery
>;
export type GetStats_IssuesQueryResult = Apollo.QueryResult<
  GetStats_IssuesQuery,
  GetStats_IssuesQueryVariables
>;
export const GetSubjectReportFilesDocument = gql`
  query GetSubjectReportFiles($subjectId: ID!) {
    getSubjectReportFiles(subjectId: $subjectId) {
      fileName
      content
    }
  }
`;

/**
 * __useGetSubjectReportFilesQuery__
 *
 * To run a query within a React component, call `useGetSubjectReportFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectReportFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectReportFilesQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useGetSubjectReportFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubjectReportFilesQuery,
    GetSubjectReportFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubjectReportFilesQuery,
    GetSubjectReportFilesQueryVariables
  >(GetSubjectReportFilesDocument, options);
}
export function useGetSubjectReportFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubjectReportFilesQuery,
    GetSubjectReportFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubjectReportFilesQuery,
    GetSubjectReportFilesQueryVariables
  >(GetSubjectReportFilesDocument, options);
}
export type GetSubjectReportFilesQueryHookResult = ReturnType<
  typeof useGetSubjectReportFilesQuery
>;
export type GetSubjectReportFilesLazyQueryHookResult = ReturnType<
  typeof useGetSubjectReportFilesLazyQuery
>;
export type GetSubjectReportFilesQueryResult = Apollo.QueryResult<
  GetSubjectReportFilesQuery,
  GetSubjectReportFilesQueryVariables
>;
export const OtherDataOptionsDocument = gql`
  query otherDataOptions {
    otherDataOptions {
      label
      key
    }
  }
`;

/**
 * __useOtherDataOptionsQuery__
 *
 * To run a query within a React component, call `useOtherDataOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtherDataOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtherDataOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOtherDataOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OtherDataOptionsQuery,
    OtherDataOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OtherDataOptionsQuery, OtherDataOptionsQueryVariables>(
    OtherDataOptionsDocument,
    options
  );
}
export function useOtherDataOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OtherDataOptionsQuery,
    OtherDataOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OtherDataOptionsQuery,
    OtherDataOptionsQueryVariables
  >(OtherDataOptionsDocument, options);
}
export type OtherDataOptionsQueryHookResult = ReturnType<
  typeof useOtherDataOptionsQuery
>;
export type OtherDataOptionsLazyQueryHookResult = ReturnType<
  typeof useOtherDataOptionsLazyQuery
>;
export type OtherDataOptionsQueryResult = Apollo.QueryResult<
  OtherDataOptionsQuery,
  OtherDataOptionsQueryVariables
>;
export const RefreshTokensDocument = gql`
  mutation refreshTokens($accessToken: String!, $refreshToken: String!) {
    refreshTokens(accessToken: $accessToken, refreshToken: $refreshToken) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type RefreshTokensMutationFn = Apollo.MutationFunction<
  RefreshTokensMutation,
  RefreshTokensMutationVariables
>;

/**
 * __useRefreshTokensMutation__
 *
 * To run a mutation, you first call `useRefreshTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokensMutation, { data, loading, error }] = useRefreshTokensMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokensMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokensMutation,
    RefreshTokensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshTokensMutation,
    RefreshTokensMutationVariables
  >(RefreshTokensDocument, options);
}
export type RefreshTokensMutationHookResult = ReturnType<
  typeof useRefreshTokensMutation
>;
export type RefreshTokensMutationResult =
  Apollo.MutationResult<RefreshTokensMutation>;
export type RefreshTokensMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokensMutation,
  RefreshTokensMutationVariables
>;
