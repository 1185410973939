import FiltersRow from "pages/admin-home/FiltersRow";
import SessionsTable, {
  subjectTableColEnum,
} from "components/common/SessionsTable";
import StatsRow from "pages/admin-home/StatsRow";
import useUrlChange from "lib/hooks/useUrlChange";
import {
  SortOrderEnum,
  UserProfile,
  FindSubjectSummariesQuery,
  GetSubjectReportFilesQuery,
  GetSubjectReportFilesQueryVariables,
  Base64File,
  SubjectSummary,
  FindSubjectSummariesQueryVariables,
} from "generated/graphql";
import { toNumber } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { ParsedQuery } from "query-string";
import findSubjectSummaries from "ApolloClient/Queries/findSubjectSummaries";
import getSubjectReportFiles from "ApolloClient/Queries/getSubjectReportFiles";
import { downloadBlob } from "lib/helpers/downloadBlob";
import { message } from "antd";
import { format } from "date-fns";
import useDownloadCSV from "lib/hooks/useDownloadCSV";
import AdvancedFiltersRow from "./AdvancedFiltersRow";
import moment from "moment";

const DEFAULT_LIMIT = 10;

const sessionToNumber = (sessionStatuses: string | string[]): number[] => {
  if (typeof sessionStatuses === "string") return [toNumber(sessionStatuses)];
  if (Array.isArray(sessionStatuses))
    return sessionStatuses?.map((item) => toNumber(item));
  return [];
};

const transformSubjectSummaryForCsv = (summary: SubjectSummary[] = []) =>
  summary.map((s) => {
    const firstDayCoughs = s?.dailyReport?.firstDayCoughs ?? 0;
    const lastDayCoughs = s?.dailyReport?.lastDayCoughs ?? 0;
    const change = lastDayCoughs - firstDayCoughs;
    const subjectIssues = s?.issues?.length ?? 0;
    const subjectSessions =
      s?.sessions?.reduce(
        (acc, sess) => acc + (sess?.issues?.length ?? 0),
        0
      ) ?? 0;

    return {
      subjectId: s?.subjectId ?? "",
      status: s?.status?.key ?? "",
      client: s?.client?.name ?? "",
      firstDayCoughs,
      lastDayCoughs,
      change,
      issues: subjectIssues + subjectSessions,
    };
  });

interface GatherQueryVariablesResult {
  organizationIds: string[];
  siteIds: string[];
  trialIds: string[];
  sessionStatus: number[];
  annotationStatus: number[];
  issueStatuses: number[];
  skip: number;
  limit: number;
  current: number;
  sessionId: string;
  deviceId: string;
  active: boolean;
  statusData?: string[];
  issueTypes?: string[];
  uploadIds?: string[];
  deviceIds?: string[];
  sessionIds?: string[];
  sessionStartDate?: string[];
  sessionEndDate?: string[];
  reload?: string;
}

const gatherQueryVariables = (query: ParsedQuery<string>) => {
  const result: Partial<GatherQueryVariablesResult> = {};

  if (query.organizationIds) {
    Object.assign(result, { organizationIds: query.organizationIds });
  }
  if (query.siteIds) {
    Object.assign(result, { siteIds: query.siteIds });
  }
  if (query.trialIds) {
    Object.assign(result, { trialIds: query.trialIds });
  }
  if (query.statusData) {
    Object.assign(result, { statusData: query.statusData });
  }
  if (query.sessionStatuses) {
    Object.assign(result, { sessionStatus: query.sessionStatus });
  }
  if (query.annotationStatuses) {
    Object.assign(result, { annotationSta: query.annotationSta });
  }
  if (query.issueStatuses) {
    Object.assign(result, { issueStatuses: query.issueStatuses });
  }
  if (query.skip) {
    Object.assign(result, { skip: query.skip });
  }
  if (query.limit) {
    Object.assign(result, { limit: query.limit });
  }
  if (query.current) {
    Object.assign(result, { current: query.current });
  }
  if (query.sessionId) {
    Object.assign(result, { sessionId: query.sessionId });
  }
  if (query.deviceId) {
    Object.assign(result, { deviceId: query.deviceId });
  }
  if (query.issueTypes) {
    Object.assign(result, { issueTypes: query.issueTypes });
  }
  if (query.uploadIds) {
    Object.assign(result, { uploadIds: query.uploadIds });
  }
  if (query.deviceIds) {
    Object.assign(result, { deviceIds: query.deviceIds });
  }
  if (query.sessionIds) {
    Object.assign(result, { sessionIds: query.sessionIds });
  }
  if (query.sessionStartDate) {
    Object.assign(result, { sessionStartDate: query.sessionStartDate });
  }
  if (query.sessionEndDate) {
    Object.assign(result, { sessionEndDate: query.sessionEndDate });
  }
  if (query.reload) {
    Object.assign(result, { reload: query.reload });
  }

  return result;
};

export interface FilterChangeParam {
  clientIds?: string[];
  studyIds?: string[];
  siteIds?: string[];
  sessionStatuses?: string[];
  annotationStatuses?: string[];
  issueTypes?: string[];
  uploadIds?: string[];
  deviceIds?: string[];
  sessionIds?: string[];
  sessionStartDate?: Date[];
  sessionEndDate?: Date[];
  statusData?: string[];
  reload?: number;
}

export default function AdminHome({
  currentUser,
}: {
  currentUser: UserProfile;
}) {
  const { onUrlChange, query } = useUrlChange();
  const [sort, setSort] = useState<
    { field: subjectTableColEnum; order: SortOrderEnum }[]
  >([]);
  const [showAdvancedFilters, setShowAdvancedFilters] =
    useState<Boolean>(false);
  const {
    leanVariables,
    variables,
    queryVariables,
    didPageSizeChanged,
    currentPage,
  } = useMemo<any>(() => {
    const queryVariables = gatherQueryVariables(query);
    const {
      siteIds,
      trialIds: studyIds,
      organizationIds: clientIds,
      skip,
      limit,
      deviceId,
      statusData: statuses,
      issueTypes,
      uploadIds,
      deviceIds,
      sessionIds,
      sessionStartDate,
      sessionEndDate,
    } = queryVariables;
    const pageSize = (() => {
      try {
        const limitNumber = toNumber(limit);
        return Number.isSafeInteger(limitNumber) ? limitNumber : DEFAULT_LIMIT;
      } catch (error) {
        console.debug("[limitNumber] We got a <<funny>> user:", error);
        return DEFAULT_LIMIT;
      }
    })();

    const sk = (() => {
      try {
        const skipNumber = toNumber(skip);
        return Number.isSafeInteger(skipNumber) ? skipNumber : 1;
      } catch (error) {
        console.debug("[skipNumber]We got a <<funny>> user:", error);
        return 1;
      }
    })();
    const offset = sk * pageSize - pageSize;
    const variables = showAdvancedFilters
      ? {
          siteIds,
          clientIds,
          pagination: {
            ...(offset > 0 ? { offset } : {}),
            limit: pageSize,
          },
          studyIds,
          deviceId: (deviceId?.[3] ? deviceId : "") as string,
          statuses,
          issueTypes,
          uploadIds,
          deviceIds,
          sessionIds,
          sessionStartDate:
            sessionStartDate && moment(sessionStartDate).toISOString(),
          sessionEndDate:
            sessionEndDate && moment(sessionEndDate).toISOString(),
        }
      : {
          siteIds,
          clientIds,
          pagination: {
            ...(offset > 0 ? { offset } : {}),
            limit: pageSize,
          },
          studyIds,
          deviceId: (deviceId?.[3] ? deviceId : "") as string,
          statuses,
          issueTypes,
        };
    const leanVariables = Object.entries(variables)
      .filter(([, value]) => {
        if (Array.isArray(value) && value.length < 1) {
          return false;
        }

        if (typeof value === "boolean") {
          return true;
        }

        return !!value;
      })
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    if (query?.active?.includes("true")) {
      Object.assign(leanVariables, { active: true });
    }

    return {
      variables,
      leanVariables,
      queryVariables,
      didPageSizeChanged: (newPageSize: number) => newPageSize !== pageSize,
      currentPage: sk > 0 ? sk : 1,
    };
  }, [query]);
  const { data, loading, refetch } = useQuery<FindSubjectSummariesQuery>(
    findSubjectSummaries,
    {
      variables: {
        ...leanVariables,
        ...queryVariables,
        sessionIds: queryVariables?.sessionIds?.map((value: string) =>
          parseInt(value)
        ),
        sort: sort[0],
      },
    }
  );

  const apolloClient = useApolloClient();
  const [downloadFile] = useDownloadCSV();

  const downloadSubjectReportFiles = useCallback(
    (subjectId: string) => {
      (async () => {
        const messageKey = `msg-download-${subjectId}`;
        try {
          message.loading({
            content: "Downloading Report Files...",
            key: messageKey,
            duration: 0,
          });
          const { data } = await apolloClient.query<
            GetSubjectReportFilesQuery,
            GetSubjectReportFilesQueryVariables
          >({
            query: getSubjectReportFiles,
            variables: {
              subjectId,
            },
          });

          const { content = "", fileName = "" } =
            data.getSubjectReportFiles ?? ({} as Base64File);

          if (content.length === 0) {
            message.warn({
              content: "Report files are not available.",
              key: messageKey,
              duration: 6,
            });
            return;
          }

          const url = `data:application/zip;base64,${content}`;
          const raw = await fetch(url);
          const blob = await raw.blob();
          downloadBlob(blob, fileName);
          message.success({
            content: "Report Files downloaded.",
            key: messageKey,
            duration: 3,
          });
        } catch (error) {
          message.error({
            content: "There was a problem when fetching the Report Files.",
            key: messageKey,
            duration: 6,
          });
          console.error(error);
        }
      })();
    },
    [apolloClient]
  );

  const downloadSubjectsSummaries = useCallback(() => {
    (async () => {
      const messageKey = `msg-subject-summaries-download`;
      try {
        message.loading({
          content: "Downloading Subject Summaries...",
          key: messageKey,
          duration: 0,
        });
        const { data } = await apolloClient.query<
          FindSubjectSummariesQuery,
          FindSubjectSummariesQueryVariables
        >({
          query: findSubjectSummaries,
          variables: {
            ...leanVariables,
            ...queryVariables,
            sessionIds: queryVariables?.sessionIds?.map((value: string) =>
              parseInt(value)
            ),
            pagination: {
              limit: 100000,
              offset: 0,
            },
          },
        });

        const summaries = (data.findSubjectSummaries?.items ??
          {}) as SubjectSummary[];

        const csvObjects = transformSubjectSummaryForCsv(summaries);
        downloadFile(
          csvObjects,
          `SubjectSummary-${format(Date.now(), "yyyy-MM-dd'T'HH-mm-ss")}.csv`
        );
        message.success({
          content: "Subjects' summaries downloaded.",
          key: messageKey,
          duration: 3,
        });
      } catch (error) {
        message.error({
          content: "There was a problem when fetching the Subjects' summaries.",
          key: messageKey,
          duration: 6,
        });
        console.error(error);
      }
    })();
  }, [apolloClient, leanVariables, downloadFile]);

  const onFiltersChange = useCallback(
    (newFilters: FilterChangeParam) => {
      onUrlChange({
        ...newFilters,
        current: 1,
        skip: 0,
      });
    },
    [onchange]
  );

  const cols = [
    subjectTableColEnum.SubjectId,
    subjectTableColEnum.Status,
    subjectTableColEnum.Client,
    subjectTableColEnum.FirstDayCoughs,
    subjectTableColEnum.LastDayCoughs,
    subjectTableColEnum.Change,
    subjectTableColEnum.Issues,
    subjectTableColEnum.clientDownload,
  ];
  const reloadQuery = useCallback(() => {
    refetch({
      ...leanVariables,
      ...queryVariables,
      sessionIds: queryVariables?.sessionIds?.map((value: string) =>
        parseInt(value)
      ),
      sort: sort[0],
    });
    onFiltersChange({ reload: Date.now() });
  }, [refetch, leanVariables, queryVariables, onFiltersChange]);

  return (
    <div>
      <FiltersRow onFiltersChange={onFiltersChange} />
      <StatsRow
        onFiltersChange={onFiltersChange}
        statusData={new Set<string>(queryVariables.statusData || [])}
        issuesTypes={new Set<string>(queryVariables.issueTypes || [])}
        clients={new Set<string>(queryVariables.organizationIds || [])}
        sites={new Set<string>(queryVariables.siteIds || [])}
        studies={new Set<string>(queryVariables.trialIds || [])}
        uploads={new Set<string>(queryVariables.uploadIds || [])}
        devices={new Set<string>(queryVariables.deviceIds || [])}
        sessions={new Set<string>(queryVariables.sessionIds || [])}
        startDate={
          queryVariables.sessionStartDate &&
          moment(queryVariables.sessionStartDate).toDate()
        }
        endDate={
          queryVariables.sessionEndDate &&
          moment(queryVariables.sessionEndDate).toDate()
        }
        reload={queryVariables.reload}
      />
      <AdvancedFiltersRow
        onFiltersChange={onFiltersChange}
        setShowAdvancedFilters={setShowAdvancedFilters}
      />
      <div style={{ marginTop: 40 }}>
        <SessionsTable
          reloadQuery={reloadQuery}
          loading={loading}
          cols={cols}
          currentUser={currentUser}
          dataSource={data?.findSubjectSummaries?.items as any[]}
          sort={sort}
          variables={variables}
          downloadSubjectReportFiles={downloadSubjectReportFiles}
          downloadSubjectsSummaries={downloadSubjectsSummaries}
          onChange={(pagination: any, filters: any, sorter: any) => {
            if (sorter?.columnKey) {
              if (!sorter?.order) {
                setSort(
                  sort?.filter((item) => item.field !== sorter?.columnKey)
                );

                // reloadQuery();
              } else {
                setSort([
                  {
                    field: sorter?.columnKey,
                    order:
                      sorter?.order === "descend"
                        ? SortOrderEnum.Desc
                        : SortOrderEnum.Asc,
                  },
                ]);
                // reloadQuery();
              }
            }
            // onUrlChange({
            //   current: pagination?.current,
            //   limit: pagination?.pageSize,
            //   skip: pagination?.pageSize * (pagination.current - 1),
            // });
          }}
          pagination={{
            total: data?.findSubjectSummaries?.count,
            pageSize: toNumber(
              data?.findSubjectSummaries?.limit || DEFAULT_LIMIT
            ),
            current: currentPage,
            showSizeChanger: true,
            onChange(page: number = 1, pageSize: number = DEFAULT_LIMIT) {
              const limit = pageSize;
              const skip = didPageSizeChanged(limit) ? 0 : page;

              onUrlChange({
                limit,
                skip,
              });
            },
          }}
        />
      </div>
    </div>
  );
}
