import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import {
  Maybe,
  useGetManyDevicesQuery,
  DeviceStatusEnum,
} from "generated/graphql";
import { useState } from "react";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const DeviceSelectInput = (props: {
  onChange: any;
  value?: Maybe<string>;
  style?: React.CSSProperties;
}) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const { data } = useGetManyDevicesQuery({
    variables: {
      searchText,
      deviceStatus: DeviceStatusEnum.Available,
    },
    fetchPolicy: "network-only",
  });

  const { value, style } = props;

  return (
    <>
      <Select
        {...props}
        value={value || undefined}
        showArrow={false}
        filterOption={false}
        optionFilterProp="children"
        placeholder="Search devices"
        onSearch={(search) => setSearchText(search)}
        showSearch
        style={{
          width: "100%",
          margin: "auto",
          ...style,
        }}
        // dropdownRender={(menu) => (
        //   <div>
        //     {menu}
        //     <Divider style={{ margin: "4px 0" }} />
        //     <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
        //       <TextInput
        //         placeholder="Add a new device"
        //         style={{ flex: "auto", height: 40, marginRight: 4 }}
        //         onChange={(e) => setNewDeviceId(e.target.value)}
        //       />
        //       <Button
        //         secondary
        //         disabled={creating}
        //         loading={creating}
        //         onClick={async () => {
        //           try {
        //             const result = await createOneDeviceMutation({
        //               variables: {
        //                 deviceId: newDeviceId,
        //               },
        //               refetchQueries: [
        //                 {
        //                   query: GetManyDevicesDocument,
        //                 },
        //               ],
        //             });
        //             onChange(result?.data?.createOneDevice?.deviceId);
        //             setNewDeviceId("");
        //           } catch (err: any) {
        //             if (err?.message?.includes("E11000")) {
        //               return message.error(
        //                 "A device with that ID already exists"
        //               );
        //             }
        //             message.error(err?.message);
        //             console.log(err);
        //           }
        //         }}
        //       >
        //         Create
        //       </Button>
        //     </div>
        //   </div>
        // )}
      >
        {data?.getManyDevices?.results?.map((device) => {
          if (!device?.deviceId) return null;
          return (
            <Option key={device?.deviceId} value={device?.deviceId}>
              {device?.deviceId}
            </Option>
          );
        })}
      </Select>
    </>
  );
};

// EXPORT
// ================================================================
export default DeviceSelectInput;
