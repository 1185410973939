import Table from "components/common/Table";
import {
  Batch,
  GetManyBatchesDocument,
  GetOneBatchDocument,
  GetOneBatchQuery,
  useCreateOneBatchMutation,
  useDeleteOneBatchMutation,
  useGetManyBatchesQuery,
  UserProfile,
} from "generated/graphql";
import Button from "components/common/Button";
import BatchForm from "components/forms/BatchForm";
import Drawer from "components/common/Drawer";
import message from "components/common/message";
import Loading from "components/common/Loading";
import Modal from "components/common/Modal";
import { useState } from "react";
import Caption from "components/text/Caption";
import ModalTitle from "components/text/ModalTitle";
import TextInput from "components/inputs/TextInput";
import moment from "moment";
import {
  DeleteOutlined,
  CloudDownloadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import client from "ApolloClient/index";
import ExpandedRow from "./ExpandedRow";
import { ApolloQueryResult } from "@apollo/client";
import useDownloadCSV from "lib/hooks/useDownloadCSV";

import theme from "lib/theme";
import { deviceStatusMap } from "lib/helpers/deviceStatusMap";

const DeleteBtn = ({
  batch,
  currentUser,
}: {
  batch: Batch;
  currentUser?: UserProfile;
}) => {
  const [deleteOneBatchMutation] = useDeleteOneBatchMutation();
  const [visible, setVisible] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string | undefined>(undefined);

  const onDelete = async () => {
    try {
      if (confirmText?.trim() !== "permanently delete") return null;
      await deleteOneBatchMutation({
        variables: {
          id: batch?.id,
        },
        refetchQueries: ["getManyBatches"],
      });
      message.success("Batch successfully deleted");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        footer={false}
        visible={visible}
        onCancel={() => {
          setConfirmText(undefined);
          setVisible(false);
        }}
        destroyOnClose
      >
        <img
          alt="police"
          height="180"
          src="https://previews.123rf.com/images/shakzu/shakzu1404/shakzu140400085/27714062-male-caucasian-police-officer-in-blue-cop-uniform-holds-up-hand-in-stop-gesture-on-white-background.jpg"
        />
        <ModalTitle style={{ marginBottom: 8 }}>
          STOP {currentUser?.firstName}!
        </ModalTitle>
        <div
          style={{
            display: "flex",
            marginBottom: 0,
            width: "95%",
            marginTop: 16,
          }}
        >
          <div style={{ marginRight: 16 }}>
            <div
              style={{
                borderRadius: "50%",
                height: 40,
                width: 40,
                background: theme.colors.red8,
                marginBottom: 16,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <WarningOutlined
                style={{
                  fontSize: 20,
                  color: theme.colors.red2,
                }}
              />
            </div>
          </div>
          <div>
            <ModalTitle style={{ marginBottom: 8 }}>
              Are you sure you that you want to delete this batch and all
              related device sessions?
            </ModalTitle>
            <Caption style={{ marginBottom: 16 }}>
              Please type "permanently delete" to confirm you understand
            </Caption>
            <TextInput
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
            />
            <Button
              disabled={confirmText?.trim() !== "permanently delete"}
              style={{ marginTop: 16 }}
              onClick={onDelete}
              danger
            >
              Delete this batch
            </Button>
          </div>
        </div>
      </Modal>
      <Button
        onClick={() => setVisible(true)}
        danger
        style={{ marginRight: 24, fontSize: 18 }}
      >
        <DeleteOutlined />
      </Button>
    </>
  );
};

export default function AdminBatches({
  currentUser,
}: {
  currentUser: UserProfile;
}) {
  const [addNew, setAddNew] = useState<boolean>(false);
  const [editable, setEditable] = useState<false | string>(false);
  const { data, loading, error } = useGetManyBatchesQuery({});

  const [createOneBatchMutation, { loading: creating }] =
    useCreateOneBatchMutation();

  const [downloadFile, downloadingCSV] = useDownloadCSV();

  const onDownloadBatch = async (batch: Batch) => {
    try {
      const result: ApolloQueryResult<GetOneBatchQuery> = await client.query({
        query: GetOneBatchDocument,
        variables: {
          id: batch.id,
        },
      });

      const formatted =
        result?.data?.getOneBatch?.deviceSessions?.results?.map((item) => {
          return {
            ID: item.id,
            "Session ID": item.sessionId,
            "Device ID": item.deviceId,
            Client: item?.organization?.name,
            Study: item?.trial?.name,
            Site: item?.site?.name,
            Status: Object.keys(deviceStatusMap)?.find(
              (key) => key === item?.status?.toString()
            ),
          };
        }) || [];

      downloadFile(
        formatted,
        `Download Batch ${batch.number} - ${moment().format("DD/MM/YY")}`
      );
      // get data
      // format data
      // create spreadsheet
      // download spreadsheet
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      key: "id",
      title: "Batch",
      render: (record: Batch) => record.number,
    },
    {
      title: "Sessions",
      render: (record: Batch) => record.sessionCount,
    },
    {
      title: "Date",
      render: (record: Batch) =>
        record.createdAt &&
        moment(parseInt(record.createdAt))?.format("MMM DD, YYYY"),
    },
    {
      title: "Time",
      render: (record: Batch) =>
        record.createdAt && moment(parseInt(record.createdAt))?.format("h:mma"),
    },
    {
      title: "",
      render: (record: Batch) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <DeleteBtn batch={record} currentUser={currentUser} />
          <Button
            grey
            loading={downloadingCSV}
            disabled={downloadingCSV}
            style={{ marginRight: 24, fontSize: 18 }}
            onClick={() => onDownloadBatch(record)}
          >
            <CloudDownloadOutlined />
          </Button>
          <Button
            onClick={
              () => setEditable((editable) => (editable ? false : record.id)) // toggle between false OR the id of record you're editing
            }
            secondary={editable === record.id ? false : true}
            // grey={!editable}
            style={{ width: 75 }}
          >
            {editable === record.id ? "Done" : "Assign"}
          </Button>
        </div>
      ),
    },
  ];

  const handleCreateSite = async (values: any) => {
    try {
      await createOneBatchMutation({
        variables: {
          ...values,
        },
        refetchQueries: [
          {
            query: GetManyBatchesDocument,
          },
        ],
      });

      setAddNew(false);
      message.success("New batch created");
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Caption>Something went wrong</Caption>;
  }

  return (
    <div>
      <Button
        onClick={() => setAddNew(true)}
        style={{ margin: 16, marginLeft: 0, width: 130 }}
      >
        + New Batch
      </Button>
      <Table
        dataSource={data?.getManyBatches?.results as any}
        columns={columns}
        rowKey="id"
        expandable={{
          expandedRowRender: (record: Batch, index, indent, expanded) => (
            <ExpandedRow
              batchId={record.id}
              editable={editable === record.id}
            />
          ),
          rowExpandable: () => true,
        }}
        className="tableClass"
        rowClassName={
          (record, index) => (Boolean(index % 2) ? "table-row-bg" : "") // clickup=1rp4pd3
        }
      />
      <Drawer
        title="Create New Batch of Sessions"
        onClose={() => setAddNew(false)}
        visible={addNew ? true : false}
      >
        <BatchForm
          onSubmit={handleCreateSite}
          onCancel={() => setAddNew(false)}
          loading={creating}
        />
      </Drawer>
    </div>
  );
}
