import { gql } from "@apollo/client";

export default gql`
  query FindSubjectSummaries(
    $subjectId: String
    $clientIds: [ID!]
    $siteIds: [ID!]
    $studyIds: [ID]
    $deviceIds: [String]
    $uploadIds: [String]
    $sessionIds: [Int]
    $sessionStartDate: DateTime
    $sessionEndDate: DateTime
    $issueTypes: [DeviceSessionIssueTypeEnum]
    $statuses: [String]
    $active: Boolean
    $pagination: PaginationOpts
    $sort: SortSubjectSummaryInput
  ) {
    findSubjectSummaries(
      subjectId: $subjectId
      clientIds: $clientIds
      siteIds: $siteIds
      studyIds: $studyIds
      deviceIds: $deviceIds
      uploadIds: $uploadIds
      sessionIds: $sessionIds
      sessionStartDate: $sessionStartDate
      sessionEndDate: $sessionEndDate
      issueTypes: $issueTypes
      statuses: $statuses
      active: $active
      pagination: $pagination
      sort: $sort
    ) {
      offset
      limit
      count
      items {
        id
        subjectId
        issues {
          createdAt
          description
          deviceSessionId
          id
          issueType
          resolved
        }
        status {
          key
        }
        sessions {
          id
          index
          deviceId
          siteId
          issues {
            id
            createdAt
            description
            deviceSessionId
            issueType
          }
          status {
            key
          }
          reference {
            prefix
            day
          }
          startDate
          endDate
          deviceStartDate
          deviceEndDate
          selected
          uploadId
          completedTasks
          totalTasks
          hardwareVersion
          firmwareVersion
          deviceStorage
        }
        client {
          name
        }
        dailyReport {
          id
          subject
          data {
            avgHrCoughs
            awakeCoughs
            coughs
            day
            medRespRate
            q1RespRate
            q3RespRate
            steps
          }
          firstDayCoughs
          lastDayCoughs
          coughsChange
          createdAt
        }
        study {
          id
          name
          metrics {
            key
            value
            label
          }
          sessionReferences {
            prefix
            day
            sessionId
          }
        }
        site {
          name
        }
      }
    }
  }
`;
