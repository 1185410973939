import styled from "styled-components";
// COMPONENTS

import { useGetManySitesQuery } from "generated/graphql";
import Caption from "components/text/Caption";

export default function StudySites({
  trialsId,
  selectSite,
  organizationId,
}: {
  trialsId: string;
  selectSite: any;
  organizationId?: string;
}) {
  const { data } = useGetManySitesQuery({
    variables: {
      organizationIds: organizationId ? organizationId : "",
      trialIds: trialsId,
    },
  });
  return (
    <div
      style={{
        width: "95%",
        border: "rgba(0, 0, 0, 0.2) 0.5px solid",
        padding: "12px 8px",
      }}
    >
      {data?.getManySites?.map((value) => (
        <Caption
          onClick={() => {
            selectSite({ trialsId: value?.id });
          }}
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color={"#127FBF"}
        >
          {value?.name}
        </Caption>
      ))}
    </div>
  );
}
