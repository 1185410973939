import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import { UserProfile } from "generated/graphql";
import TrialsMultiSelect from "components/inputs/TrialsMultiSelect";
import { useEffect, useMemo, useState } from "react";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

interface UserFormProps {
  onSubmit: (values: any) => void;
  onCancel: any;
  loading?: boolean;
  edit?: boolean;
  organizationId?: string;
  editData?: UserProfile;
}

export default function UserForm({
  onSubmit,
  onCancel,
  loading,
  organizationId,
  editData,
  edit,
}: UserFormProps) {
  const [formData, setFormData] = useState({
    firstName: editData?.firstName ?? "",
    lastName: editData?.lastName ?? "",
    email: editData?.email ?? "",
    trialIds:
      editData?.trials
        ?.map((t) => t?.id)
        .filter((t) => !!t)
        .map((t) => t as string) ?? [],
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const beforeOnSubmit = handleSubmit((data) => {
    if (!data) return;
    onSubmit(data);
  });

  const emailRegister = useMemo(
    () =>
      register("email", {
        required: "Email is required.",
        value: formData.email,
      }),
    [formData.email]
  );

  const firstNameRegister = useMemo(
    () =>
      register("firstName", {
        required: "First name is required.",
        value: formData.firstName,
      }),
    [formData.firstName]
  );

  const lastNameRegister = useMemo(
    () =>
      register("lastName", {
        required: "Last name is required.",
        value: formData.lastName,
      }),
    [formData.lastName]
  );

  const trialIdsValue = formData.trialIds;

  useEffect(() => {
    const email = editData?.email ?? "";
    const firstName = editData?.firstName ?? "";
    const lastName = editData?.lastName ?? "";
    const trialIds =
      editData?.trials
        ?.map((t) => t?.id)
        .filter((t) => !!t)
        .map((t) => t as string) ?? [];

    setFormData({
      firstName,
      lastName,
      email,
      trialIds,
    });

    setValue("email", email);
    setValue("firstName", firstName);
    setValue("lastName", lastName);
    setValue("trialIds", trialIds);
  }, [
    editData?.firstName,
    editData?.lastName,
    editData?.email,
    editData?.trials,
  ]);

  return (
    <Form onSubmit={beforeOnSubmit}>
      <FormItem label="Email" required error={errors?.email?.message}>
        <TextInput
          type="email"
          {...emailRegister}
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
      </FormItem>
      <FormItem label="First Name" required error={errors?.firstName?.message}>
        <TextInput
          type="firstName"
          {...firstNameRegister}
          value={formData.firstName}
          onChange={(e) =>
            setFormData({ ...formData, firstName: e.target.value })
          }
        />
      </FormItem>
      <FormItem label="Last Name" required error={errors?.lastName?.message}>
        <TextInput
          type="lastName"
          {...lastNameRegister}
          value={formData.lastName}
          onChange={(e) =>
            setFormData({ ...formData, lastName: e.target.value })
          }
        />
      </FormItem>
      {organizationId && (
        <FormItem label="Studies" required error={errors?.trialIds?.message}>
          <Controller
            name="trialIds"
            control={control}
            rules={{ required: "At least one trial is required" }}
            defaultValue={trialIdsValue}
            render={({ field }) => {
              const { ref: innerRef, value: fieldValue, ...fieldProps } = field;
              return (
                <TrialsMultiSelect
                  {...fieldProps}
                  innerRef={innerRef}
                  organizationIds={organizationId ? [organizationId] : []}
                  value={trialIdsValue}
                  onChange={(trialIds: any) => {
                    setFormData({ ...formData, trialIds });
                    field.onChange(trialIds);
                  }}
                />
              );
            }}
          />
        </FormItem>
      )}
      <FormItem>
        <Button
          type="button"
          onClick={onCancel}
          grey
          style={{ width: 100, marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button
          style={{ width: 100 }}
          type="submit"
          loading={loading}
          disabled={loading}
        >
          {edit ? "Update" : "Submit"}
        </Button>
      </FormItem>
    </Form>
  );
}
