import styled from "styled-components";

import { SessionReference, useUpdateStudyMutation } from "generated/graphql";

import { Col, message, Popover, Row } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";

import PrimaryButton from "components/common/Button";
import { CloseCircleFilled } from "@ant-design/icons";
import { onNegativeNumberFieldKeydown } from "lib/helpers/numericInputFields";
import AssignedSubjectsPopup from "./assignedSubjectsPopup";

const ContainerSession = styled.div`
  max-width: 100%;
  margin-bottom: 10px;
`;
const Input = styled.input`
  width: 90px;
  height: 25px;
  background: #fff;
  border: 1px solid #bab9b9;
  border-radius: 7px;
`;

const NEGATIVE_NUMBER_INPUT_EXP = /^-?[0-9]+$/;

export type SessionDatesTypes = {
  __typename?: "SessionDatesTypes";
  id: string;
  prefix?: string;
  day?: number;
};

interface ReferenceProps {
  index: number;
  prefix?: string;
  day?: number;
  action: (index: number) => void;
}

const Reference = ({ index, prefix = "", day = 0, action }: ReferenceProps) => {
  const onDelete = useCallback(() => {
    action(index);
  }, [action, index]);

  return (
    <Row style={{ paddingTop: 4, paddingBottom: 4 }}>
      <Col
        span={17}
        style={{ textAlign: "left", fontSize: "15px", fontWeight: 300 }}
      >
        {prefix}
        {day}
      </Col>

      <Col
        span={7}
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
          fontWeight: 300,
          color: "gray",
        }}
      >
        <Popover
          content={
            <AssignedSubjectsPopup
              assignedSubjects={[
                { id: "a13tas", subjectName: "AMGENI-23486" },
                { id: "t32csd", subjectName: "AMGENI-23486" },
              ]}
            />
          }
          // title="Can not delete reference. It is already assigned to the following subject(s)"
        >
          <CloseCircleFilled onClick={onDelete} />
        </Popover>
      </Col>
    </Row>
  );
};

interface AddReferenceProps {
  action: (ref: SessionReference, clear: () => void) => void;
  disable: boolean;
}

const AddReference = ({ action, disable }: AddReferenceProps) => {
  const [prefix, setPrefix] = useState<string>();
  const [day, setDay] = useState<string>();
  const [disableSubmit, setDisableSubmit] = useState<boolean>(disable);

  const clear = useCallback(() => {
    setPrefix("");
    setDay("");
  }, [setPrefix, setDay]);
  const onAdd = useCallback(() => {
    if (prefix && day) {
      action({ prefix, day: parseInt(day) }, clear);
    }
  }, [prefix, day, clear, action]);

  useEffect(() => {
    const isSubmittable =
      (prefix?.length ?? 0) > 0 && NEGATIVE_NUMBER_INPUT_EXP.test(day ?? "");
    setDisableSubmit(disable || !isSubmittable);
  }, [prefix, day, disable]);

  return (
    <div>
      <Row>
        <Col span={12} style={{ textAlign: "center" }}>
          <h3 style={{ fontSize: 15 }}>Prefix</h3>
        </Col>
        <Col span={5} style={{ textAlign: "center" }}>
          <h3 style={{ fontSize: 15 }}>Day</h3>
        </Col>
        <Col span={7} />
      </Row>
      <Row>
        <Col span={12}>
          <Input
            type="text"
            onChange={(e) => setPrefix(e.currentTarget.value)}
            value={prefix}
            style={{
              width: "90%",
              paddingLeft: "7px",
              textAlign: "center",
            }}
          />
        </Col>
        <Col span={5}>
          <Input
            onChange={(e) => setDay(e.currentTarget.value)}
            onKeyDown={onNegativeNumberFieldKeydown}
            style={{
              width: "90%",
              height: "25px",
              textAlign: "center",
              borderRadius: "7px",
            }}
            type="text"
            value={day}
          />
        </Col>
        <Col
          span={7}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrimaryButton onClick={onAdd} disabled={disableSubmit}>
            Add
          </PrimaryButton>
        </Col>
      </Row>
    </div>
  );
};

export default function SessionColumnDates({
  id,
  defaultValues,
}: {
  id: string;
  defaultValues?: SessionReference[];
}) {
  const [elements, setElements] = useState(defaultValues as SessionReference[]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [updateStudyMutation] = useUpdateStudyMutation();
  const addReferenceAction = useCallback(
    (ref: SessionReference, clear: () => void) => {
      (async () => {
        try {
          if (ref.prefix && !Number.isNaN(ref.day)) {
            setDisableSubmit(true);
            await updateStudyMutation({
              variables: {
                input: {
                  id,
                  sessionReferences: [
                    ...elements.map(({ day, prefix, sessionId }) => ({
                      prefix,
                      day,
                      sessionId,
                    })),
                    ref,
                  ],
                },
              },
            });
            clear();
            message.success("Reference added.");
          } else {
            message.warn(
              "Please verify you are entering a valid prefix and a day."
            );
          }
        } catch (error) {
          message.error("There was an error while adding the reference.");
        } finally {
          setDisableSubmit(false);
        }
      })();
    },
    [id, elements, setDisableSubmit, updateStudyMutation]
  );
  const removeReferenceAction = useCallback(
    (index: number) => {
      (async () => {
        try {
          const sessionReferences = elements
            .filter((_, i) => i !== index)
            .map(({ day, prefix, sessionId }) => ({ prefix, day, sessionId }));
          await updateStudyMutation({
            variables: {
              input: {
                id,
                sessionReferences,
              },
            },
          });
          message.success("Reference removed.");
        } catch (error) {
          message.error("There was an error while removing the reference.");
          console.error(error);
        }
      })();
    },
    [id, elements, updateStudyMutation]
  );
  const sortedElements = useMemo(
    () =>
      elements.reduce(
        (acc, element) => [element, ...acc],
        [] as SessionReference[]
      ),
    [elements]
  );
  const elementCount = useMemo(() => sortedElements.length, [sortedElements]);

  // This effect is needed to manage state from incoming elements in case the
  // component is re-rendered.
  useEffect(() => setElements(defaultValues ?? []), [defaultValues]);

  return (
    <div style={{ height: "120px" }}>
      <div style={{ marginBottom: "10px" }}>
        <AddReference action={addReferenceAction} disable={disableSubmit} />
        <ContainerSession
          style={{ overflowY: "auto", maxHeight: "78px", marginTop: 8 }}
        >
          {sortedElements.map((element, index) => (
            <Reference
              index={elementCount - index - 1}
              prefix={element.prefix}
              day={element.day}
              key={index}
              action={removeReferenceAction}
            />
          ))}
        </ContainerSession>
      </div>
    </div>
  );
}
