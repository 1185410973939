// Editable.js
import { useState } from "react";
import styled from "styled-components";
import {
  LoadingOutlined,
  CheckCircleFilled,
  CloseOutlined,
} from "@ant-design/icons";
import theme from "lib/theme";
import TruncateMarkup from "react-truncate-markup";
import Tooltip from "components/common/Tooltip";

const EditableBlock = styled.div<{ editable?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  border-radius: 5px;
  cursor: ${({ theme, editable }) => (editable ? "text" : "default")};
  padding: 4px;
  &:hover {
    background-color: ${({ theme, editable }) =>
      editable ? theme.colors.neutral10 : null};
  }
`;
export enum EditableTypeEnum {
  text = "text",
  select = "select",
}

const StatusContainer = styled.div`
  position: absolute;
  left: -14px;
  top: 7px;
`;

const getFormattedText = (text: string) => {
  if (text?.length < 14) return text;
  return (
    <TruncateMarkup lines={1}>
      <div>
        <Tooltip title={text}>{text}</Tooltip>
      </div>
    </TruncateMarkup>
  );
};

// Component accept text, placeholder values and also pass what type of Input - input, textarea so that we can use it for styling accordingly
const Editable = ({
  text,
  type,
  placeholder,
  children,
  saving,
  saved,
  editable = true,
  ...props
}: {
  text?: string;
  type: EditableTypeEnum;
  placeholder?: string;
  editable?: boolean;
  saving?: boolean;
  saved?: boolean;
  children?: React.ReactChild;
}) => {
  // Manage the state whether to show the label or the input box. By default, label will be shown.
  // Exercise: It can be made dynamic by accepting initial state as props outside the component
  const [isEditing, setEditing] = useState(false);

  // Event handler while pressing any key while editing
  const handleKeyDown = (event: any, type: EditableTypeEnum) => {
    // Handle when key is pressed
  };

  /*
    - It will display a label if `isEditing` is false
    - It will display the children (input or textarea) if `isEditing` is true
    - when input `onBlur`, we will set the default non edit mode
      Note: For simplicity purpose, I removed all the classnames, you can check the repo for CSS styles
  */

  return (
    <section {...props} style={{ width: "90%", position: "relative" }}>
      {saving ? (
        <StatusContainer>
          <LoadingOutlined style={{ fontSize: 8 }} />
        </StatusContainer>
      ) : null}
      {saved ? (
        <StatusContainer>
          <CheckCircleFilled
            style={{ fontSize: 12, color: theme.colors.success6 }}
          />
        </StatusContainer>
      ) : null}
      {isEditing ? (
        <div
          onBlur={() => setEditing(false)}
          onKeyDown={(e) => handleKeyDown(e, type)}
        >
          {children || <EditableBlock />}
        </div>
      ) : (
        <EditableBlock
          editable={editable}
          onClick={() => editable && setEditing(true)}
        >
          {text ? (
            getFormattedText(text)
          ) : (
            <span style={{ color: theme.colors.neutral6 }}>{placeholder}</span>
          )}
        </EditableBlock>
      )}
      {isEditing ? (
        <StatusContainer
          style={{
            right: -18,
            left: "inherit",
            top: 6,
            cursor: "pointer",
            width: 20,
            height: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => setEditing(false)}
        >
          <CloseOutlined
            style={{ fontSize: 12, color: theme.colors.neutral4 }}
          />
        </StatusContainer>
      ) : null}
    </section>
  );
};

export default Editable;
