import Row from "components/common/Row";
import Col from "components/common/Col";
import styled from "styled-components";
import PageSubtitle from "components/text/PageSubtitle";
import { useFindSubjectSummariesStatsQuery } from "generated/graphql";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import theme from "lib/theme";
import useUrlChange from "lib/hooks/useUrlChange";
import TruncateText from "components/common/TruncateText";
import { useWindowWidth } from "@react-hook/window-size";
import { FilterChangeParam } from "..";

const StatsCardLabel = styled.label`
  color: ${({ theme }) => theme.colors.neutral1};
  /* width: 85%; */
`;

const StatsCardValue = styled.p`
  margin: 0px;
  color: ${({ theme }) => theme.colors.primary4};
  text-align: right;
  width: 15%;
  position: relative;
`;

const StatRow = styled.div<{ active?: boolean }>`
  display: flex;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 5px;
  justify-content: space-between;
  background: ${({ theme, active }) => {
    if (active) return theme.colors.success10;
    return "#fff";
  }};
  &:hover {
    background: ${({ theme, active }) => {
      if (active) return theme.colors.success10;
      return theme.colors.neutral11;
    }};
  }
`;

const Card = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.neutral9};
  background-color: ${({ theme }) => theme.colors.neutral10};
  padding: 16px;
  border-radius: 5px;
  min-height: 170px;
  background: #fff;
  width: 100%;
`;

const SessionStatus = ({ queryVariables, onFiltersChange }: StatsRowProps) => {
  useWindowWidth();
  const { data, loading } = useFindSubjectSummariesStatsQuery({
    variables: queryVariables,
  });
  return (
    <Card>
      <StatRow
        active={queryVariables?.statusData === "no-issue"}
        onClick={() => {
          if (queryVariables?.statusData === "no-issue")
            onFiltersChange &&
              onFiltersChange({
                statusData: undefined,
              });
          else
            onFiltersChange &&
              onFiltersChange({
                statusData: "no-issue",
              });
        }}
      >
        <StatsCardLabel>
          {" "}
          <CheckCircleOutlined
            style={{
              color: theme.colors?.success4,
              marginRight: 8,
              fontSize: 17,
              position: "relative",
              // top: 2,
            }}
          />
          No Issue
        </StatsCardLabel>
        <StatsCardValue>
          {loading
            ? "..."
            : data?.findSubjectSummariesStats?.noIssue?.toLocaleString()}
        </StatsCardValue>
      </StatRow>
      <StatRow
        active={queryVariables?.statusData === "possible-issue"}
        onClick={() => {
          if (queryVariables?.statusData === "possible-issue")
            onFiltersChange &&
              onFiltersChange({
                statusData: undefined,
              });
          else
            onFiltersChange &&
              onFiltersChange({
                statusData: "possible-issue",
              });
        }}
      >
        <StatsCardLabel>
          <ExclamationCircleOutlined
            style={{
              color: theme.colors?.red6,
              marginRight: 8,
              fontSize: 17,
              position: "relative",
              //top: 2,
            }}
          />
          Possible Issue
        </StatsCardLabel>
        <StatsCardValue>
          {loading
            ? "..."
            : data?.findSubjectSummariesStats?.withIssue?.toLocaleString()}
        </StatsCardValue>
      </StatRow>
      <StatRow>
        <TruncateText text="Total Subjects Available">
          <StatsCardLabel>
            <CheckCircleOutlined
              style={{
                color: "transparent",
                marginRight: 8,
                fontSize: 17,
                position: "relative",
                // top: 2,
              }}
            />
            Total Subjects Available
          </StatsCardLabel>
        </TruncateText>
        <StatsCardValue>
          {loading
            ? "..."
            : data?.findSubjectSummariesStats?.total?.toLocaleString()}
        </StatsCardValue>
      </StatRow>
    </Card>
  );
};

const HourCoughs = ({ queryVariables }: StatsRowProps) => {
  const { data, loading } = useFindSubjectSummariesStatsQuery({
    variables: queryVariables,
  });

  return (
    <Card>
      <StatRow>
        <StatsCardLabel>Average First Day</StatsCardLabel>
        <StatsCardValue>
          {loading
            ? "..."
            : data?.findSubjectSummariesStats?.dailyCoughs?.avgFirstDay?.toLocaleString()}
        </StatsCardValue>
      </StatRow>
      <StatRow>
        <StatsCardLabel>Average Last Day</StatsCardLabel>
        <StatsCardValue>
          {loading
            ? "..."
            : data?.findSubjectSummariesStats?.dailyCoughs?.avgLastDay?.toLocaleString()}
        </StatsCardValue>
      </StatRow>
      <StatRow>
        <StatsCardLabel>Average Change</StatsCardLabel>
        <StatsCardValue>
          {loading
            ? "..."
            : data?.findSubjectSummariesStats?.dailyCoughs?.avgChange?.toLocaleString()}
        </StatsCardValue>
      </StatRow>
    </Card>
  );
};

const WakingCoughs = ({ queryVariables }: StatsRowProps) => {
  const { data, loading } = useFindSubjectSummariesStatsQuery({
    variables: queryVariables,
  });
  return (
    <Card>
      <StatRow>
        <StatsCardLabel>Average First Day</StatsCardLabel>
        <StatsCardValue>
          {loading
            ? "..."
            : data?.findSubjectSummariesStats?.awakeCoughs?.avgFirstDay?.toLocaleString()}
        </StatsCardValue>
      </StatRow>
      <StatRow>
        <StatsCardLabel>Average Last Day</StatsCardLabel>
        <StatsCardValue>
          {loading
            ? "..."
            : data?.findSubjectSummariesStats?.awakeCoughs?.avgLastDay?.toLocaleString()}
        </StatsCardValue>
      </StatRow>
      <StatRow>
        <StatsCardLabel>Average Change</StatsCardLabel>
        <StatsCardValue>
          {loading
            ? "..."
            : data?.findSubjectSummariesStats?.awakeCoughs?.avgChange?.toLocaleString()}
        </StatsCardValue>
      </StatRow>
    </Card>
  );
};

interface StatsRowQueryVariable {
  siteIds?: string[];
  studyIds?: string[];
  clientIds?: string[];
  statuses?: string[];
  statusData?: string;
}

interface StatsRowProps {
  queryVariables: StatsRowQueryVariable;
  onFiltersChange?: (params: FilterChangeParam) => void;
}

export default function StatsRow({
  queryVariables,
  onFiltersChange,
}: StatsRowProps) {
  return (
    <Row gutter={16}>
      <Col xs={8}>
        <PageSubtitle style={{ textAlign: "center" }}>
          Subject Status
        </PageSubtitle>
        <SessionStatus
          onFiltersChange={onFiltersChange}
          queryVariables={queryVariables}
        />
      </Col>
      <Col xs={8}>
        <PageSubtitle style={{ textAlign: "center" }}>
          24-Hr Coughs
        </PageSubtitle>
        <HourCoughs queryVariables={queryVariables} />
      </Col>
      <Col xs={8}>
        <PageSubtitle style={{ textAlign: "center" }}>
          Awake Coughs
        </PageSubtitle>
        <WakingCoughs queryVariables={queryVariables} />
      </Col>
    </Row>
  );
}
