import {
  DailySubjectReport,
  Session,
  Study,
  SubjectSummary,
  useGenerateReportMutation,
} from "generated/graphql";
import { AnalysisSessionTable } from "components/tables/AnalysisSessionTable";
import {
  ArchivedSessionTable,
  AvailableSessionTable,
} from "components/tables/AvailableSessionTable";
import Chart from "components/common/SessionsTable/ExpandedRow/Chart2";
import theme from "lib/theme";
import styled from "styled-components";
import { Col, message, Modal, Row } from "antd";
import PrimaryButton from "components/common/Button";
import "components/modals/SubjectSessions/sessions.css";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import useUrlChange from "lib/hooks/useUrlChange";

interface QueryResult {
  findSubjectSummaries: SubjectSummary[];
}

const StyledModalHeaderContainer = styled.div`
  display: block;
  width: 100%;
  height: 55px;
  background-color: ${theme.colors.gray3};
  padding: 10px 26px;
`;

const StyledModalHeaderText = styled.span`
  font-family: ${theme.fontFamily};
  font-weight: 600;
  font-size: 20px;
  color: ${theme.colors.gray1};
`;

const BUTTONS_STYLES: CSSProperties = {
  width: "180px",
  float: "right",
  marginTop: "1px",
  marginLeft: "10px",
  height: "35px",
};

interface Props {
  refetchReports: () => void;
  close: () => void;
  open: boolean;
  dataPoints: any;
  sessionUploadId: string;
  summary: SubjectSummary;
  reloadQuery?: () => void;
}

export default function AdminSession({
  refetchReports,
  dataPoints,
  close,
  open,
  summary,
  sessionUploadId,
  reloadQuery = () => void 36,
}: Props) {
  const {
    subjectId = "N/A",
    // dailyReport = {},
    sessions = [],
    study = {},
    id,
  } = summary;

  const { onUrlChange } = useUrlChange();
  const analysisSessions =
    sessions?.filter(
      (session) =>
        session?.status?.key !== "Available" &&
        session?.status?.key !== "Archived"
    ) || [];
  const availableSessions =
    sessions?.filter((session) => session?.status?.key === "Available") || [];
  const archivedSessions =
    sessions?.filter((session) => session?.status?.key === "Archived") || [];
  const [isMutationRunning, setIsMutationRunning] = useState(false);
  const [generateReport] = useGenerateReportMutation();
  const onGenerateReport = useCallback(() => {
    (async () => {
      const messageKey = `generate-report-${id}`;
      setIsMutationRunning(true);
      try {
        message.loading({
          content: "Generating report...",
          duration: 30,
          key: messageKey,
        });
        const response = await generateReport({
          variables: { subjectId: id },
          refetchQueries: ["FindSubjectSummaries"],
        });

        if (!response?.data?.generateReport) {
          message.info({
            content: "Report is not available.",
            key: messageKey,
            duration: 5,
          });
          return;
        }

        message.success({
          content: "Report generated successfully",
          duration: 3,
          key: messageKey,
        });

        try {
          refetchReports();
          reloadQuery();
        } catch (error) {
          console.error("Failed to reload query", error);
        }
      } catch (error) {
        message.warning({
          content: "Failed to generate report.",
          duration: 5,
          key: messageKey,
        });
      } finally {
        setIsMutationRunning(false);
      }
    })();
  }, [id, generateReport, reloadQuery]);

  return (
    <Modal visible={open} width={1450} closable={false} centered footer={null}>
      <StyledModalHeaderContainer>
        <Row>
          <Col span={12}>
            <StyledModalHeaderText>
              Manage Sessions: Subject {subjectId}
            </StyledModalHeaderText>
          </Col>
          <Col span={12}>
            <PrimaryButton
              style={BUTTONS_STYLES}
              onClick={() => {
                close();
                onUrlChange({ sessionSubject: "" });
              }}
              select
              disabled={isMutationRunning}
            >
              Close
            </PrimaryButton>
            <PrimaryButton
              style={BUTTONS_STYLES}
              onClick={onGenerateReport}
              green
              disabled={isMutationRunning}
            >
              Generate Report
            </PrimaryButton>
          </Col>
        </Row>
      </StyledModalHeaderContainer>
      <Chart
        dataPoints={dataPoints as any}
        subjectId={subjectId as string}
        studyData={study as Study}
      />
      <AnalysisSessionTable
        reloadQuery={reloadQuery}
        sessionUploadId={sessionUploadId}
        sessions={analysisSessions as Session[]}
        studyData={study as Study}
        subjectId={subjectId as string}
      />
      <AvailableSessionTable sessions={availableSessions as Session[]} />
      <ArchivedSessionTable sessions={archivedSessions as Session[]} />
    </Modal>
  );
}
