import { useCallback, useEffect, useMemo, useState } from "react";
import SessionDataChart2 from "./SessionDataChart2";
import OtherDataSelectInput from "components/inputs/OtherDataSelectInput";
import {
  DailySubjectReport,
  OtherDataUnitEnum,
  Study,
  useGetSelectedReportQuery,
  useSelectDailySubjectReportMutation,
} from "generated/graphql";
import PageSubtitle from "components/text/PageSubtitle";
import moment from "moment";
import Button from "components/common/Button";
import { message } from "antd";
import ShowDetailsModal from "./ShowDetailsModal";

export type COUGH_FILTERS = "coughs" | "awakeCoughs" | "avgHrCoughs";

export const COUGH_FIELDS: Record<COUGH_FILTERS, string> = {
  coughs: "24-Hr Coughs",
  awakeCoughs: "Awake Coughs",
  avgHrCoughs: "Avg Hour Coughs",
};

interface ChartOptionProps {
  value?: string;
  onChange: any;
  selectedOption?: any;
  pushedToClient?: any;
  options: { key: string; label: string }[];
}

interface Props {
  dataPoints: DailySubjectReport[];
  sessionId?: string;
  subjectId: string;
  clientView?: boolean;
  studyData?: Study;
  adminView?: boolean;
}

export default function Chart({
  dataPoints,
  studyData,
  clientView,
  adminView = false,
  subjectId,
}: Props) {
  const [selectDailySubjectReport] = useSelectDailySubjectReportMutation();
  const { data: selectedReport } = useGetSelectedReportQuery({
    variables: {
      subjectId: subjectId,
    },
  });
  const [isMutationRunning, setIsMutationRunning] = useState(false);
  const [showDetailsModalOpen, setShowDetailsModalOpen] = useState(false);
  const [chartOptionCreatedAt, setChartOptionCreatedAt] = useState<string>(
    dataPoints
      ? dataPoints.find(
          (value) => value?.id === selectedReport?.getSelectedReport?.id
        )?.id
        ? (dataPoints.find(
            (value) => value?.id === selectedReport?.getSelectedReport?.id
          )?.id as string)
        : (dataPoints?.[dataPoints?.length - 1]?.id as string)
      : ""
  );
  const onPushToClient = useCallback(() => {
    (async () => {
      const messageKey = `push-to-client`;
      setIsMutationRunning(true);
      try {
        message.loading({
          content: "Pushing to client...",
          duration: 30,
          key: messageKey,
        });
        const response = await selectDailySubjectReport({
          variables: {
            input: { reportID: chartOptionCreatedAt },
          },
          refetchQueries: ["FindSubjectSummaries"],
        });

        if (!response?.data?.selectDailySubjectReport) {
          message.info({
            content: "Pushed to client successfully.",
            key: messageKey,
            duration: 5,
          });
          return;
        }

        message.success({
          content: "Pushed to client successfully",
          duration: 3,
          key: messageKey,
        });
      } catch (error) {
        message.warning({
          content: "Failed to push to client.",
          duration: 5,
          key: messageKey,
        });
      } finally {
        setIsMutationRunning(false);
      }
    })();
  }, [chartOptionCreatedAt, selectDailySubjectReport]);

  const availableDates = useMemo(
    () =>
      dataPoints
        ?.filter((entry) => adminView || entry)
        ?.reverse()
        ?.map((value) => ({
          key: value?.id as string,
          // selectedDefault: value?.selected ? value?.selected : false,
          label: moment(value?.createdAt).format(
            "MM/DD/YY, hh:mm:ss a"
          ) as string,
        })) ?? [],
    [dataPoints, adminView]
  );

  const availableMetrics = useMemo(
    () =>
      studyData?.metrics
        ?.filter(({ value }) => adminView || value)
        ?.map(({ key, label }) => ({
          key: key as string,
          label: label as string,
        })) ?? [],
    [studyData, adminView]
  );

  useEffect(() => {
    setChartOptionCreatedAt(
      dataPoints
        ? dataPoints.find(
            (value) => value?.id === selectedReport?.getSelectedReport?.id
          )?.id
          ? (dataPoints.find(
              (value) => value?.id === selectedReport?.getSelectedReport?.id
            )?.id as string)
          : (dataPoints?.[dataPoints?.length - 1]?.id as string)
        : ""
    );
  }, [selectedReport, dataPoints]);

  // useEffect(() => {
  //   setChartOptionCreatedAt(
  //     dataPoints ? (dataPoints?.[dataPoints?.length - 1]?.id as string) : ""
  //   );
  // }, [dataPoints]);

  const [chartOption, setChartOption] = useState<string>(
    availableMetrics[0]?.key ?? ""
  );

  const getChartOptionLabel = (key: string) => {
    for (let i = 0; i < (studyData?.metrics?.length || 0); i++) {
      if (key === studyData?.metrics?.[i]?.key) {
        return studyData?.metrics?.[i]?.label;
      }
    }
    return "";
  };
  const getChartDateOption = (key: string) => {
    for (let i = 0; i < (dataPoints?.length || 0); i++) {
      if (key === dataPoints?.[i]?.id) {
        return dataPoints?.[i];
      }
    }
    return null;
    // return dataPoints?.find(
    //   (value) => value?.id === key
    // );
  };

  const coughCounts = getChartDateOption(chartOptionCreatedAt)?.data?.map(
    (point) => ({
      awakeCoughs: Math.floor(point?.awakeCoughs ?? 0),
      avgHrCoughs: Math.floor(point?.avgHrCoughs ?? 0),
      name: `${point?.day}`,
      coughs: Math.floor(point?.coughs ?? 0),
      medRespRate: point?.medRespRate ?? 0,
      // q1RespRate: point?.q1RespRate ?? 0,
      q3RespRate: point?.q3RespRate ?? 0,
      steps: point?.steps ?? 0,
    })
  );

  // const coughCounts = dataPoints?.data?.map((point) => ({
  //   awakeCoughs: Math.floor(point?.awakeCoughs ?? 0),
  //   avgHrCoughs: Math.floor(point?.avgHrCoughs ?? 0),
  //   name: `${point?.day}`,
  //   coughs: Math.floor(point?.coughs ?? 0),
  //   medRespRate: point?.medRespRate ?? 0,
  //   q1RespRate: point?.q1RespRate ?? 0,
  //   q3RespRate: point?.q3RespRate ?? 0,
  //   steps: point?.steps ?? 0,
  // }));

  const ChartOption = ({
    value,
    onChange,
    options,
    selectedOption,
    pushedToClient,
  }: ChartOptionProps) => (
    <OtherDataSelectInput
      selectedOption={selectedOption}
      pushedToClient={pushedToClient}
      key="other-data-select-input"
      onChange={(newOtherData: string) => onChange(newOtherData)}
      value={value}
      options={options}
      style={{
        width: 225,
        marginRight: 40,
      }}
    />
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 96,
            marginBottom: 0,
            marginTop: 16,
            gap: 16,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 24,
              height: "40px",
            }}
          >
            <PageSubtitle
              style={{
                margin: 0,
              }}
            >
              {getChartOptionLabel(chartOption)}
            </PageSubtitle>
          </div>
          {!clientView && (
            <div>
              <ShowDetailsModal
                visible={showDetailsModalOpen}
                onCancel={() => {
                  setShowDetailsModalOpen(false);
                }}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDetailsModalOpen(true);
                }}
              >
                <PageSubtitle
                  style={{
                    fontSize: "16px",
                    cursor: "pointer",
                    margin: 0,
                    textDecoration: "underline",
                  }}
                >
                  Generated at:{" "}
                  {moment(
                    getChartDateOption(chartOptionCreatedAt)?.createdAt
                  ).format("MM/DD/YY, hh:mm:ss a")}
                </PageSubtitle>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: 12,
          }}
        >
          <div
            style={{
              marginTop: 16,
            }}
          >
            <ChartOption
              value={getChartOptionLabel(chartOption) || ""}
              onChange={(newValue: OtherDataUnitEnum) =>
                setChartOption(newValue)
              }
              options={availableMetrics}
            />
          </div>
          {!clientView && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Button
                lightblue
                onClick={(e) => {
                  e.stopPropagation();
                  onPushToClient();
                }}
                style={{
                  display: "block",
                  height: 40,
                }}
                disabled={
                  isMutationRunning
                  // ||
                  // !!getChartDateOption(chartOptionCreatedAt)?.selected
                }
              >
                Push to Client
              </Button>
              <ChartOption
                selectedOption={chartOptionCreatedAt}
                pushedToClient={selectedReport?.getSelectedReport?.id}
                value={
                  dataPoints
                    ? moment(
                        getChartDateOption(chartOptionCreatedAt)?.createdAt
                      ).format("MM/DD/YY, hh:mm:ss a")
                    : ""
                }
                onChange={(newValue: OtherDataUnitEnum) =>
                  setChartOptionCreatedAt(newValue)
                }
                options={availableDates}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <div style={{ marginBottom: 80, height: 400, paddingLeft: 0 }}>
          <SessionDataChart2
            formattedData={coughCounts}
            chartOption={chartOption as OtherDataUnitEnum}
            isAwakeCoughs={chartOption === "awakeCoughs"}
          />
        </div>
      </div>
    </>
  );
}
